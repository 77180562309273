import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
//import { useTranslation } from "react-i18next";
import * as signalR from "@microsoft/signalr";

//import iconPixWhite from '../../assets/icons/pix-white.svg';
//import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

import IconPIX from '../../../../assets/img/pix-svgrepo-com.svg';

import './PayPix.scss';

export interface checkout {
    content?: any,
    action?: any,
    setPrinter?: any,
    setManagement?: any
};

const PayPix: React.FC<checkout> = React.memo(({ content, action, setPrinter, setManagement
}: checkout) => {
    //const [t] = useTranslation();

    const [scanResponse, setScanResponse] = useState<any>(null); // pixSuccess / pixFailed / pixExpired

    const [minutes, setMinutes] = useState<any>(10);
    const [seconds, setSeconds] = useState<any>(0);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_SERVER_URL_API_PIX + 'streaming/')
            .build();

        connection.on(content.data[0].data.channelPix, (data: any) => {
            if (data.log === 0) { //success
                action("aprovado");
                setScanResponse("pixSuccess");
                setPrinter(data.data.data);

                setTimeout(() => {
                    setManagement('imprimindo');
                }, 4000);
            } else if (data.log === 1) { //error
                setScanResponse("pixFailed");
                action("error");
            }
        });

        connection.start().then(function () {
            connection.invoke('JoinToGroup', content.data[0].data.channelPix);
        }).catch(function (err: any) {
            return console.error(err.toString());
        });

        let closeChannel = setTimeout(() => {
            if (scanResponse !== 'pixSuccess' || scanResponse !== 'pixFailed') {
                action("error");
            }
            connection.stop();
        }, 660000);

        return () => {
            clearTimeout(closeChannel);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content])

    return (
        <>
            <div className="d-flex justify-content-center flex-column text-center">
                {/* <div className="bg-header-custom mb-3">
                    <img src={process.env.REACT_APP_SERVER_LOGO_PRIMARY} alt="icon c2rio" width="180px" />
                </div> */}
                <div className='content-title'>
                    <h3 className="text-primary text-center my-inside d-flex gap-3 justify-content-center align-items-center">
                        <img src={IconPIX} alt="icon pix" width="60px" />
                        Pague agora com PIX
                    </h3>
                </div>
                <div>
                    <img
                        src={("data:image/png;base64," + content.data[0].data.urlQrCode).toString()}
                        className="qr-code"
                        alt="QR Code"
                        width={'360'}
                    />

                    <div className="instructions-pix mb-4">
                        <ol>
                            <li>1 - abra o app do seu banco ou instituição financeira</li>
                            <li>2 - entre no ambiente Pix</li>
                            <li>3 - escolha pagar com QR code e escaneie o código</li>
                            <li>4 - confirme as informações e finalize a compra</li>
                        </ol>
                    </div>

                    <p className='text-align-center'>Esse código expira em:<br />
                        {minutes === 0 && seconds === 0
                            ? null
                            : <h2 className="text-primary"> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h2>
                        }</p>
                </div>

                <div className="printing-buttons mt-4" style={{ marginTop: "7rem" }}>
                    <Button className="btn btn-printing-custom text-white py-3 mb-5 px-4" onClick={() => window.location.reload()}>
                        Cancelar
                    </Button>
                </div>
            </div>
        </>
    )
});

export default PayPix;