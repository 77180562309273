import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import store from './store/cart';
import { Provider } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
//import { fab } from '@fortawesome/free-brands-svg-icons';

import './assets/sass/global.scss'

import Routes from './routes';
import { parseCookies } from 'nookies';

function App() {
  library.add(fas, fal, fad, far);

  const cookies = parseCookies();

  useEffect(() => { // Verifica se tem token
    if(window.location.pathname !== "/") {
      if(cookies.authSupervisor === undefined) {
        window.location.href = "/";
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className="App">
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </I18nextProvider>
      </Provider>

    </div>
  );
}

export default App;