import React, { useEffect, useState } from 'react';

//COMPONENTS
import SearchTicketsFilter from './components/SearchTicketsFilter/SearchTicketsFilter';
import SearchTicketsTable from './components/SearchTicketsTable/SearchTicketsTable';

import './SearchTickets.scss';
import DesktopDefault from '../../templates/DesktopDefault';
import api from '../../services/api';
import { parseCookies } from 'nookies';
//import { parseCookies } from 'nookies';

function SearchTickets() {

    const [zpl, setZpl] = useState<any>("");

    const [tickets, setTickets] = useState<any>(null);

    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    const [loading, setLoading] = useState<any>(false);

    const [notFound, setNotFound] = useState<boolean>(false);

    const [cancel, setCancel] = useState<boolean>(true);

    async function buscar(tokenID: any, nsu: any, date: any, ticket: any, operador: any, bookingloc: any) {
        setLoading(true);

        if (operador === true) {
            setCancel(false);
        } else {
            setCancel(true);
        }

        const cookies = parseCookies();
        const token = cookies.authSupervisor;
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post(`/Bilheteria/ListRetireticketBilheteria`,
                {
                    "page": operador === true ? pageCount : 1,
                    "rowsPerPage": operador === true ? rowsPerPage : 10,
                    "codeReimpressao": tokenID || undefined,
                    "dataCompra": date.split("/").reverse().join("-") || undefined,
                    "nsuHost": nsu || undefined,
                    "PorOperador": operador || undefined,
                    "BookingLoc": bookingloc || undefined,
                    "Ticket": ticket || undefined

                }, config
            );
            if (res.status !== 400) {
                setZpl(res.data.data.zplTemplate);
                

                if (res.data.statusCode === 204) {
                    setTickets(undefined);

                } else {
                    setTickets(res.data.data.rows);
                    setTotalRows(res.data.data.rowsCount)
                    setLoading(false);
                }
            }
        } catch (error) {
            setTickets(null);
            setNotFound(true);
            setLoading(false);
        }
    };

    useEffect(() => { // Páginação
        async function buscar(tokenID: any, nsu: any, date: any, ticket: any, operador: any, bookingloc: any) {
            setLoading(true);
            setTickets(null);

            if (operador === true) {
                setCancel(false);
            } else {
                setCancel(true);
            }

            const cookies = parseCookies();
            const token = cookies.authSupervisor;
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try {
                const res = await api.post(`/Bilheteria/ListRetireticketBilheteria`,
                    {
                        "page": pageCount,
                        "rowsPerPage": Number(rowsPerPage),
                        "codeReimpressao": tokenID || undefined,
                        "dataCompra": date.split("/").reverse().join("-") || undefined,
                        "nsuHost": nsu || undefined,
                        "PorOperador": operador || undefined,
                        "BookingLoc": bookingloc || undefined,
                        "Ticket": ticket || undefined

                    }, config
                );
                if (res.status !== 400) {
                    setZpl(res.data.data.zplTemplate);
                    console.log(res.data.data);

                    if (res.data.statusCode === 204) {
                        setTickets(undefined);

                    } else {
                        setTickets(res.data.data.rows);
                        setTotalRows(res.data.data.rowsCount);
                        setLoading(false);
                    }
                }
            } catch (error) {
                setTickets(null);
                setNotFound(true);
                setLoading(false);
            }
        };

        if(cancel === false) {
            buscar("", "", "", "", true, "");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, pageCount]);

    return (
        <>
            <DesktopDefault pageTitle={'Buscar Ingressos'}>
                <SearchTicketsFilter
                    buscar={buscar}
                />

                <SearchTicketsTable
                    totalRows={totalRows}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    zpl={zpl}
                    tickets={tickets}
                    notFoundTable={notFound}
                    cancel={cancel}
                    loading={loading}
                />

            </DesktopDefault>
        </>

    );
}

export default SearchTickets;