import React from "react";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import configData from "../../config/config.json";

import { useTranslation } from "react-i18next";

import './Calendar.scss';

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

const today:string = new Date().toLocaleDateString();

const CustomMultipleInput = ({ openCalendar, value, setTime}: any) => {
  const [t] = useTranslation();
  
  if (value.length === 1) {
    setTime(value[0]);
  } else {
    setTime(value);
  }
  
  return (
    <input
      id="calendar"
      className="form-control bg-white form-control-lg frm-sm py-2 pl-5"
      onFocus={openCalendar}
      value={value}
      readOnly
      placeholder={today}
    />
  )
}


const SingleCalendarTime = ( props:any ) => {
  const transferC2: any = localStorage.getItem("transferC2");
  const transferItemJSON = JSON.parse(transferC2);

  return (
    <DatePicker
      id="date-picker"
      minDate={new Date()}
      weekDays={weekDays}
      months={months}
      value={window.location.href.split('/')[3] === 'transfers' ? transferItemJSON.date + " " + transferItemJSON.time : ""}
      format={`${configData.DATE.PT} HH:mm`}
      render={<CustomMultipleInput setTime={props.setTime} />}
      plugins={[
        <TimePicker
          hideSeconds
          style={{ minWidth: "100px" }}
        />
      ]}
    />
  )
}

export default SingleCalendarTime;



