import React from "react";

import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";

import iconMachine from '../../../assets/icons/credit_card_machine_dataphone.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './ModalCancelSitef.scss';

function ModalCancelSitef(props: any) {
    return (
        <div>
            <Modal
                {...props}
                className={"modal-custom modalAuth modal-validation"}
                show={props.modalCancel}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalBody className='modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column align-items-center modal-checkout'>
                    {
                        props?.management === "aprovado"
                            ?
                            <>
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    style={{ fontSize: "6rem" }}
                                    className="color-success"
                                />
                                <div>
                                    <span>
                                        {/* Cancelamento realizado com sucesso!
                                        <br /> */}
                                        <div className="text-success" style={{ fontWeight: 600, fontSize: "1rem", padding: '0 15px' }} dangerouslySetInnerHTML={{ __html: props?.infosMaquina }}></div>
                                        {
                                            props?.returnValue !== ""
                                                ?
                                                <p className="mb-0" style={{ fontWeight: 400 }}>Valor a ser devolvido: {props?.returnValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                                :
                                                ""
                                        }
                                    </span>
                                </div>
                                <div>
                                    <button className="btn btn-default btn-primary mx-2" onClick={() => window.location.reload()}>
                                        Sair
                                    </button>
                                </div>
                            </>
                            :
                            props.management === "error"
                                ?
                                <div className="d-flex justify-content-evenly align-items-center flex-column modal-checkout">
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="1x"
                                        style={{ fontSize: "6rem" }}
                                        className="color-error"
                                    />
                                    <div>
                                        <h4 className="text-error">Desculpe algo deu errado!</h4>
                                        <p className="mb-2">Por favor, entre em contato com nossa equipe de suporte.</p>
                                        <p className="mb-0">{props.errorCode}</p>
                                    </div>
                                    <div>
                                        <button className="btn btn-default btn-primary mx-2 text-white" onClick={() => { props.setModalCancel(false) }}>
                                            Sair
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="d-flex flex-column align-items-center">
                                    {
                                        props.infosMaquina === "Insira ou passe o cartao na leitora" || props.infosMaquina === "Insira ou passe ou aproxime o cartao na leitora"
                                            ?
                                            <img src={iconMachine} alt="icon machine" style={{ width: "6rem" }} className="mb-4" />
                                            :
                                            <div className="load-checkout mb-4"></div>
                                    }
                                    <p style={{ fontWeight: 600 }}>{props.infosMaquina}</p>
                                    <div className="printing-buttons" style={{ marginTop: "1rem" }}>
                                        {
                                            props.continueReady === true
                                                ?
                                                <button className="btn btn-default btn-primary" onClick={() => props.changeContinua(-1)}>
                                                    Cancelar
                                                </button>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                    }
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalCancelSitef;
