import {
  useEffect
} from 'react';

const LoadScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.type = "text/javascript"
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);

  return true;
};

export default LoadScript;