import React, { useCallback, useEffect, useState } from 'react';
import './Reports.scss'

//COMPONENTS
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { Chart } from "react-google-charts";
import DesktopDefault from '../../templates/DesktopDefault';
import api from '../../services/api';
import { parseCookies } from 'nookies'
import FilterReport from './FilterReport/FilterReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Reports() {

  // Resolve o warning do console referente ao Google Charts
  const originalWarn = console.warn;
  console.warn = function (...args) {
    const arg = args && args[0];
    if (arg && arg.includes('Attempting to load version \'51\' of Google Charts')) return;
    originalWarn(...args);
  };
  // END - Resolve o warning do console referente ao Google Charts

  const [key, setKey] = useState<string>('Relatório BDB');
  const [firstChart, setFirstChart] = useState<any>(["", "Total de Vendas (R$)"]);
  const [secondChart, setSecondChart] = useState<any>(["Dias", "Total de Vendas (R$)", "Cartão de Crédito", "Cartão de Débito", "Dinheiro", "Cancelamentos", "Devoluções"]);
  const [pdfBase64, setPdfBase64] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const firstOpt = {
    width: 800,
    colors: ["#00632D"],
  }

  const options = {
    axes: {
      y: {
        0: { label: "Vendas" }, // Top x-axis.
      },
    },
  };

  const getReportGraph = useCallback(async (date: any = null) => {
    setLoading(true);

    const cookies = parseCookies();
    const token = cookies.authSupervisor;
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    let today: any = new Date().toLocaleDateString();
    let dataInicio: any;
    let dataFim: any;

    if (date?.length === 2) {
      dataInicio = date[0];
      dataFim = date[1];
    } else if (date?.length === 1) {
      dataInicio = date[0];
      dataFim = date[0];
    } else {
      dataInicio = today;
      dataFim = today;
    }

    try {
      const { data } = await api.post(`/BilheteriaCaixa/BilheteriaRelatorDiario`,
        {
          "dataInicio": dataInicio.split('/').reverse().join('-'),
          "dataFim": dataFim.split('/').reverse().join('-'),
          "filtro": 3  // 1 = ano, 2 = ano e  mes, 3 =  ano mes e dia| só aceita valores 1, 2 ou 3
        }, config
      );
      if (data?.log === 0) {
        setPdfBase64(data.data.pdfRelatorio);
        let setUpFirstChart: any = [];

        /* SEPARANDO AS DATAS QUE RECEBO DA API */
        let dates = data.data.dadosPagamentoManagementReport.map((elem: any) => {
          return elem.dataLabel
        })

        let allDatesUnit: any = dates.filter((elem: any, index: number) => {
          return dates.indexOf(elem) === index;
        });
        /* [END] SEPARANDO AS DATAS QUE RECEBO DA API */

        /* CRIA O ARRAY QUE VAI MONTAR O PRIMEIRO CHART */
        let value: number = 0;

        allDatesUnit.forEach((elem: string) => {
          value = data.data.dadosPagamentoManagementReport.filter((e: any) => e.dataLabel === elem).map((e: any) => e.soma).reduce((acumulador: number, actual: number) => {
            return acumulador + actual;
          })
          setUpFirstChart.push([`${elem.split('-').reverse().join('/')}`, value])
        });
        /* [END] CRIA O ARRAY QUE VAI MONTAR O PRIMEIRO CHART */

        setFirstChart([
          ["", "Total de Vendas (R$)"],
          ...setUpFirstChart
        ])

        let setUpSecondChart: any = [];

        allDatesUnit.forEach((elem: string) => {
          let filtered: any = data.data.dadosPagamentoManagementReport.filter((e: any) => e.dataLabel === elem);
          let mapped: any = filtered.map((e: any) => e.soma);
          let reduced: any = mapped.reduce((acumulador: number, actual: number) => {
            return acumulador + actual;
          });
          let creditCard: any = filtered.filter((e: any) => e.tipo === 'CREDITCARD')[0]?.soma || 0;
          let debitCard: any = filtered.filter((e: any) => e.tipo === 'DEBITCARD')[0]?.soma || 0;
          let cash: any = filtered.filter((e: any) => e.tipo === 'CASH')[0]?.soma || 0;
          let refund: any = filtered.filter((e: any) => e.tipo === 'REFUND')[0]?.soma || 0;

          setUpSecondChart.push([`${elem.split('-').reverse().join('/')}`, reduced, creditCard, debitCard, cash, refund]);
        });

        setSecondChart([
          ["Dias", "Total de Vendas (R$)", "Cartão de Crédito", "Cartão de Débito", "Dinheiro", "Devoluções"],
          ...setUpSecondChart
        ])

        setLoading(false);
      } else {
        setModalMessage("Erro ao validar modelo");
        setModalShow(true);
      }
    } catch (e: any) {
      setModalMessage(e.response.data.errorMessage)
      setModalShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getReportGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportReport = () => {
    const linkSource = `data:application/pdf;base64,${pdfBase64}`;
    const downloadLink = document.createElement("a");
    const fileName = "Relatório_Vendas_Bilheteria.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
      <DesktopDefault pageTitle={'Realatório BDB'}>
        <main>
          <div>
            <FilterReport buscar={getReportGraph} />

            <Tabs
              id="controlled-tab-example"
              defaultActiveKey="Relatório BDB"
              activeKey={key}
              onSelect={(k: any) => {
                setKey(k);
              }}
              className="mt-4"
            >
              <Tab eventKey="Relatório BDB" title="Relatório BDB">
                {/* <h4>{tabTitle}</h4> */}
                {/* GRAFICO 1 */}
                {
                  loading === true
                    ?
                    <>
                      <div className="text-center text-primary my-2"><h4>Processando</h4></div>
                      <div className="animated-background row-loading"></div>
                    </>
                    :
                    <>
                      <div className="d-flex justify-content-between mt-5">
                        <div className='first-chart'>
                          <Chart
                            chartType="Bar"
                            width="80%"
                            height="200px"
                            data={firstChart}
                            options={firstOpt}
                          />
                        </div>
                        <Button
                          variant="outline-info"
                          onClick={exportReport}
                        >
                          Exportar Relatório
                        </Button>
                      </div>
                      {/* GRAFICO 2 */}
                      <div>
                        <Chart
                          chartType="Bar"
                          width="100%"
                          height="300px"
                          data={secondChart}
                          options={options}
                        />

                      </div>
                    </>
                }
              </Tab>
            </Tabs>
          </div>
          <Modal
            className="modalAuth modal-validation"
            show={modalShow}
            onHide={() => {
              setLoading(false);
              setModalMessage('');
              setModalShow(false);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <>
              <Modal.Body
                className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
              >
                <div>

                  <FontAwesomeIcon
                    icon={["fal", "times-circle"]}
                    size="5x"
                    //style={{ fontSize: "7.5em" }}
                    className='text-primary'
                  />
                </div>

                <div>{modalMessage}</div>
                <div className="d-flex justify-content-center pt-3">
                  <button
                    onClick={() => {
                      setModalMessage('');
                      setLoading(false);
                      setModalShow(false);
                    }}
                    className="btn btn-primary mx-2 w-25"
                    style={{ color: '#FFF' }}
                  >
                    Confirmar
                  </button>
                </div>
              </Modal.Body>
            </>
          </Modal>
        </main>
      </DesktopDefault>
    </>
  );
}

export default Reports;