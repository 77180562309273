import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/api';
import i18next from "i18next";
import ProductTicket from '../../Products/ProductTicket/ProductTicket';
import ProductTour from '../../Products/ProductTour/ProductTour';

import './ModalBuy.scss';

export interface propModal {
    setModalShow: any;
    modalShow: any;
    modalInfo: any;
    edit?: any;
    editIndex?: any;
};

const ModalBuy: React.FC<propModal> = ({
    setModalShow, modalShow, modalInfo, edit = false, editIndex
}: propModal) => {
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

    const [tourResponse, setTourResponse] = useState<any>("null");

    useEffect(() => {
        let idChannel: number = 1;

        async function getItems() {
            const apiRequest = modalInfo?.typeProduct === "TOUR" ?
                `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchSingleTourAsync` :
                `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchSingleTicketAsync`;

            try {
                const { data } = await api.post(apiRequest,
                    {
                        productCode: "",
                        lang: lng,
                        Slug: `${modalInfo.productSlug}`,
                        idCanal: idChannel
                    }
                );
                if (data.status !== 400) {
                    if (data.statusCode === 204) {

                    } else {
                        setTourResponse(data.data);
                    }
                }
            } catch (error) {
                setTourResponse("null");
            }
        }

        const config = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };

        async function getIdChannel() {
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/bilheteria`, config);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;

                    if(modalShow === true) {
                        getItems();
                    } else {
                        setTourResponse("null");
                    }
                }
            } catch (error) { }
        }

        getIdChannel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow]);

    return (
        <>
            <Modal
                className={"modal-custom modalAuth modal-buy " + tourResponse.slugBR}
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                    setTourResponse("null");
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className='mb-5' closeButton>
                    {
                        tourResponse !== "null"
                            ?
                            <div className="d-flex justify-content-between">
                                <h4 className="mb-0">
                                    {i18next.language === "pt-BR" ? tourResponse.productNameBR : i18next.language === "en" ? tourResponse.productNameEN : i18next.language === "es" ? tourResponse.productNameES : tourResponse.productNameBR}
                                </h4>
                            </div>
                            :
                            <div className="d-flex justify-content-between">
                                <h4 className="animated-background text-primary step-choose mb-0" style={{ width: "220px", height: "2.25rem" }}> </h4>
                            </div>
                    }
                </Modal.Header>
                <Modal.Body>
                    {tourResponse !== "null" ?
                        <div>
                            {tourResponse?.typeProduct === "TOUR"
                                ?
                                <ProductTour
                                    tourResponse={tourResponse}
                                    lng={lng}
                                    setModalShow={setModalShow}
                                    edit={edit}
                                    editInfo={modalInfo}
                                    editIndex={editIndex}
                                />
                                :
                                <ProductTicket
                                    tourResponse={tourResponse}
                                    lng={lng}
                                    setModalShow={setModalShow}
                                    edit={edit}
                                    editInfo={modalInfo}
                                    editIndex={editIndex}
                                />
                            }
                        </div>
                        :
                        <>
                            <div className=''>
                                <div>
                                    <h3 className="animated-background text-primary step-choose" style={{ width: "220px", height: "1.875rem" }}> </h3>
                                </div>

                                <div className='step'>
                                    <h3 className="animated-background text-primary step-choose" style={{ width: "100%", height: "1.875rem" }}> </h3>
                                </div>


                                <div className='step'>
                                    <h3 className="animated-background text-primary step-choose" style={{ width: "350px", height: "1.875rem" }}>

                                    </h3>
                                    <div className="prices-modal">
                                        <div className="qtyButtons">
                                            <div className="animated-background flex-ajuste" style={{ width: "160px", height: "2.25rem" }}>

                                            </div>
                                            <div className="animated-background d-flex" style={{ width: "200px", height: "2.25rem" }}>

                                            </div>
                                            <div className="animated-background price" style={{ width: "160px", height: "2.25rem" }}>

                                            </div>
                                        </div>


                                        <div className="qtyButtons">
                                            <div className="animated-background flex-ajuste" style={{ width: "160px", height: "2.25rem" }}>

                                            </div>
                                            <div className="animated-background d-flex" style={{ width: "200px", height: "2.25rem" }}>

                                            </div>
                                            <div className="animated-background price" style={{ width: "160px", height: "2.25rem" }}>

                                            </div>
                                        </div>

                                        <div className="qtyButtons">
                                            <div className="animated-background flex-ajuste" style={{ width: "160px", height: "2.25rem" }}>

                                            </div>
                                            <div className="animated-background d-flex" style={{ width: "200px", height: "2.25rem" }}>

                                            </div>
                                            <div className="animated-background price" style={{ width: "160px", height: "2.25rem" }}>

                                            </div>
                                        </div>

                                    </div>
                                    <hr />
                                    <div>
                                        <div className="qtyButtons">
                                            <div className="animated-background flex-ajuste" style={{ width: "200px", height: "2.25rem" }}>

                                            </div>

                                            <div className="animated-background price" style={{ width: "200px", height: "2.25rem" }}>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalBuy;