import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";


export interface checkout {
  setModalShow: any;
  errorCode: any;
  errorMessage: any;
};

//COMPONENTS

//ICONS


const InfoError: React.FC<checkout> = React.memo(({ setModalShow, errorCode, errorMessage
}: checkout) => {

  return (
    <>
      <div  className="d-flex justify-content-evenly align-items-center flex-column text-center modal-checkout gap-2">
        <div>
          <FontAwesomeIcon
            icon={["fal", "times-circle"]}
            size="1x"
            style={{ fontSize: "6rem" }}
            className="color-error"
          />
        </div>
        <div>
          <h4 className="mb-2 text-error">Desculpe algo deu errado!</h4>
          <p className="mb-2">Por favor, entre em contato com nossa equipe de suporte.</p>

          <div className="d-flex flex-column">
            <small style={{ color: "red", marginBottom: ".15rem" }}>{errorMessage}</small>
            <p className="mb-0">{errorCode}</p>
          </div>

        </div>
        <div>
          <Button className="btn-default text-white" onClick={() => setModalShow(false)}>
            Ok
          </Button>
        </div>
      </div>
    </>
  )

});

export default InfoError;
