import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useAppSelector } from "../../../../store/hooks";



//COMPONENTS

//ICONS
export interface checkout {
  setModalShow: any;
  getPayments: any;
  setReceiveCash: any;
};

const PaymentCash: React.FC<checkout> = React.memo(({ setModalShow, getPayments, setReceiveCash
}: checkout) => {
  const cartPDV: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const initialValue: any = 0;

  const [receiveValue, setReceiveValue] = useState<any>(0);
  const [backValue, setBackValue] = useState<any>(0);

  const [error, setError] = useState<boolean>();
  const [messagerError, setMessageError] = useState<string>('');

  function Receive(value: any) {
    console.log(value);
    setReceiveValue(Number(value));
    setBackValue((Number(value) - cartPDV.totalCart))
  }

  function handleCash() {
    if (receiveValue >= cartPDV.totalCart) {
      getPayments(2, 1);
    }
  }

  useEffect(() => {
    setReceiveCash(receiveValue);

    if (receiveValue >= cartPDV.totalCart) {
      setError(false);
    } else {
      setError(true);
      setMessageError('O valor não pode ser menor que o valor a receber!')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveValue])

  return (
    <>
      <div>

        <p style={{ marginBottom: "2.375rem" }}>Total a receber: <span style={{ fontWeight: 600 }}>{cartPDV.totalCart.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></p>

        <Form.Group>
          <Form.Label htmlFor="amountPaid">
            Valor entregue pelo cliente:
          </Form.Label>

          <Form.Control
            type="number"
            name="amountPaid"
            id="amountPaid"
            style={{ width: "210px", height: "48px" }}
            onChange={(e: any) => Receive(e.target.value)}
            className="mb-1"
          />

          {
            error === true
              ?
              <small className="text-danger">{messagerError}</small>
              :
              <></>
          }
        </Form.Group>


      </div>

      <div>
        <p>Descrição</p>

        <div className="d-flex justify-content-between mb-2">
          <h5 className="text-uppercase">Valor Recebido:</h5>
          <h5>{receiveValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h5>
        </div>

        <div className="d-flex justify-content-between">
          <h5 className="text-uppercase">Valor Total:</h5>
          <h5>{cartPDV.totalCart.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h5>
        </div>

        <hr />

        <div className="d-flex justify-content-between pb-3">
          <h5 className="text-uppercase">Troco:</h5>
          <h5>{backValue < 0 ? initialValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : backValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h5>
        </div>

        <div className="d-flex justify-content-end align-items-end mb-3 px-0 mt-5">
          <Button
            variant="outline-primary"
            className="btn-default mr-2"
            type="button"
            onClick={() => setModalShow(false)}
          >
            Cancelar
          </Button>
          <Button className="btn-default text-light" type="button" onClick={handleCash}>
            Finalizar Pagamento
          </Button>
        </div>
      </div>
    </>
  )
});

export default PaymentCash;
