
function SetTransfersItem(item: any) {


  const getTransfers = localStorage.getItem("transferPDV");
  let transfer = JSON.parse(getTransfers || '{}');
  transfer = item;
  localStorage.setItem("transferPDV", JSON.stringify(transfer));  

  return true;
}



export default SetTransfersItem;