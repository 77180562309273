//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "react-bootstrap";
//import ModalHeader from "react-bootstrap/esm/ModalHeader";

import InstructionsPrint from '../../../pages/Tickets/components/InstructionsPrint/InstructionsPrint';

export interface propPrint {
    setModalPrint: any;
    modalPrint: any;
    checkoutError: any;
    printerSecondWay: any;
    printerInfo: any;
}

const ModalPrinting: React.FC<propPrint> = ({
    setModalPrint, modalPrint, checkoutError, printerSecondWay, printerInfo
}) => {

    /* const [action, setAction] = useState<any>(0);
    const [printer, setPrinter] = useState<any>(); */

/*     const ChoiceTicket = () => {
        return (
            <>
                <Modal.Header closeButton></Modal.Header>
                <div className="text-center" style={{ marginBottom: "4rem" }}>
                    <h2>Reimprimir Pulseiras</h2>
                    <h5>{printerInfo.nameBR}</h5>
                </div>
                <div className="mb-3 text-center">
                    Deseja imprimir:
                </div>
                <div>
                    <div className="printer-ticket d-flex justify-content-center align-items-center" style={{ height: "74px" }} onClick={() => ActionPrinter(printerSecondWay)}>
                        Todas as pulseiras
                    </div>
                </div>
                <div className="my-3 text-center">
                    ou
                </div>
                <div className="d-flex flex-column gap-4 mb-5 quantity-tickets">
                    {
                        printerSecondWay.map((item: any, index: any) => {
                            return (
                                <div key={index}>
                                    <div className="printer-ticket" onClick={() => ActionPrinter([item])}>
                                        <Row className="align-items-center">
                                            <Col md="4">
                                                <FontAwesomeIcon
                                                    icon={["fal", "ticket-alt"]}
                                                    size="2x"
                                                    style={{ fontSize: "2.75rem", verticalAlign: "middle" }}
                                                />
                                            </Col>
                                            <Col md="6">
                                                {printerInfo.accessCodes[index].accessCode}
                                            </Col>
                                            <Col md="2" className="d-flex justify-content-end">
                                                {printerInfo.accessCodes[index].faixaLabel}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        )
    } */

    /* function ActionPrinter(item:any) {
        setPrinter(item);
        setAction(1);
    } */

    /* useEffect(() => {
        setTimeout(() => {
            setAction(0);          
        }, 500);
    }, [modalPrint]) */

    return (
        <>
            <Modal
                className={"modal-custom modalAuth modal-validation"}
                show={modalPrint}
                onHide={
                    () => setModalPrint(false)
                }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* {
                    action === 0
                        ?
                        <ChoiceTicket />
                        : */}
                        <InstructionsPrint printerSecondWay={printerSecondWay} setModalShow={setModalPrint} checkoutError={checkoutError} />
                {/* } */}
            </Modal>
        </>
    )
}

export default ModalPrinting;