import React from "react";
import { useForm } from "react-hook-form";
import { parseCookies } from 'nookies';
/* import { destroyCookie } from 'nookies' */

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
import "../../../../assets/sass/table.scss";
import "./CashRegisterTable.scss";
import { Button, Form } from "react-bootstrap";


export interface propInsert {
  cashTable: any;
  loading: any;
  handleCloseCashier: any;
}

const CashRegisterTable: React.FC<propInsert> = ({
  cashTable,
  loading,
  handleCloseCashier
}: propInsert) => {
  const validated = false;
  const cookies = parseCookies();

  const columns = [
    { dataField: "tipodemovimento", text: "Tipo de movimento" },
    { dataField: "registradonopdv", text: "Registrado no PDV" },
    //{ dataField: "totalconferidoacumulado", text: "Total conferido acumulado" },
    //{ dataField: "diferenca", text: "Diferença" },
  ];

  /* loading table */
  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    {
      dataField: "caixa",
      text: "Carregando registro de caixa",
      formatter: loadingFunc,
    },
  ];

  const loadingCash = [
    { caixa: "" },
    { caixa: "" },
    { caixa: "" },
    { caixa: "" },
    { caixa: "" },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [
    { dataField: "registro de caixa", text: "registro de caixa" },
  ];

  const notFoundCash = [{ vendas: "Nenhum registro de caixa encontrado" }];
  /* [END] In case of empty table*/

  const onSubmit = (data: any) => {

    //window.location.reload()
  };  

  const {
    handleSubmit,
    // formState: { errors },
  } = useForm();

  if (cashTable !== null) {
    return (
      <>
        <div className="table-default cash-register-table mx-0">
          <div className="table-container">
            <div>
              <div className="table-title">
                <h3>Total Movimentado</h3>
              </div>

              <BootstrapTable
                bootstrap4
                keyField={'tipodemovimento'}
                data={cashTable}
                hover={true}
                columns={columns}
                striped={true}
              //rowClasses={rowClasses}
              />
            </div>

            <div className="">
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="false"
              >
                {/* <Form.Group>
                  <Form.Label className="mb-4">
                    Insira informações em caso de diferença
                  </Form.Label>
                  <Controller
                    control={control}
                    name="infos"
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows={3}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                        onChange={handlerInfo}
                      />
                    )}
                  />
                </Form.Group> */}
                <div className="d-flex justify-content-end align-items-end mt-4">
                  {cookies.isCashierOpened !== undefined &&
                  <Button
                  className="btn-default text-light custom-button"
                  type="submit"
                  onClick={() => handleCloseCashier()}
                  >
                    Fechar Caixa
                  </Button>
                  }                 
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  } else if (cashTable === null && loading === true) {
    return (
      <>
        <div className="table-default cash-register-table loading">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={loadingCash}
                hover={true}
                columns={loadingColumns}
                striped={true}
                //rowClasses={rowClasses}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default cash-register-table loading not-found">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={notFoundCash}
                hover={true}
                columns={notFoundColumns}
                striped={true}
                //rowClasses={rowClasses}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default CashRegisterTable;
