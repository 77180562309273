import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PaymentCash from '../PaymentCash/PaymentCash';
import SelectPayment from '../SelectPayment/SelectPayment';

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { removeTotalCart } from "../../../../store/cart";
import api from "../../../../services/api";
import PaymentCreditCart from '../PaymentCreditCart/PaymentCreditCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoError from '../InfoError/InfoError';
import { parseCookies } from 'nookies';
import InstructionsSitef from '../InstructionsSitef/InstructionsSitef';
import InfoApproved from '../InfoApproved/InfoApproved';
import InstructionsPrint from '../InstructionsPrint/InstructionsPrint';
import Load from '../Load/Load';
import i18next from 'i18next';
import PayPix from '../PayPix/PayPix';

export interface propModal {
    setModalShow: any;
    modalShow: any;
};

let bookLocFinal = '';
let tokenCode = '';
let method = 0;
let continua = 0;
let price = '';

let payments: any;

const ModalBuy: React.FC<propModal> = ({
    setModalShow, modalShow,
}: propModal) => {
    let installmentSelect = 1;
    let sessionId = '';

    let local: any = localStorage.getItem("params");
    let storage: any = JSON.parse(local);

    const sitefIp = storage !== null && storage?.sitefIp !== null ? storage?.sitefIp : "127.0.0.1";
    const empresa = storage !== null && storage?.empresa !== null ? storage?.empresa : "00000000"; // 00004673
    const terminal = storage !== null && storage?.terminal !== null ? storage?.terminal : "REST0001"; // MU000901
    const cnpjEstabelecimento = storage !== null && storage?.cnpjEstabelecimento !== null ? storage?.cnpjEstabelecimento : "31406434895111";
    const cnpjLoja = storage !== null && storage?.cnpjLoja !== null ? storage?.cnpjLoja : "12523654185985";
    /* const caixa = storage !== null && storage?.caixa !== null ? storage?.caixa : "009"; */

    const [management, setManagement] = useState('metodo-pagamento');
    const [infosMaquina, setInfosMaquina] = useState('');
    const [continueReady, setContinueReady] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [installment, setInstallment] = useState('');

    const [printer, setPrinter] = useState<any>('');

    const [errorCode, setErrorCode] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [receiveCash, setReceiveCash] = useState<any>();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isFree, setIsFree] = useState<any>(null);

    const [pixContent, setPixContent] = useState('');

    const date: any = (new Date().toLocaleString()).split(" ")[0].split("/").reverse().join("");
    const time: any = (new Date().toLocaleString()).split(" ")[1].split(":").join("");
    const dateChanged: any = new Date().getTime();

    let tempObject = {
        nsu_sitef: '',
        nsu_host: '',
        codigo_autorizacao: '',
        terminal: terminal,
        estabelecimento: '',
        rede_autorizadora: '',
        bandeira: '',
        comprovante_estabelecimento: '',
        comprovante_cliente: ''
    }

    useEffect(() => {
        if (modalShow === false) {
            setManagement('metodo-pagamento');
        }
    }, [modalShow])

    //Controle do carrinho REDUXJS
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dispatch = useAppDispatch();
    const cartPDV: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    // Controle de Steps
    const handleNext = (newObject: any) => {
        setInfosMaquina('');

        switch (newObject) {
            case 'debit':
                method = 47;
                getPayments(47, 1);
                setManagement('instrucoes');
                break;
            case 'credit':
                method = 1;
                //getPayments(1);
                //setManagement('instrucoes');
                setManagement('parcelas');
                break;
            case 'pix':
                method = 96;
                setManagement('load');
                getPayments(96, 1);
                break;
            case 'cash':
                method = 2;
                setManagement('cash');
                //getPayments(2, 1);
                break;
            case 'free':
                method = 0;
                setManagement('free');
                getPayments(-1, 1);
                break;
            case 'reset':
                setManagement('metodo-pagamento');
                break;
            default:
                break;
        }
    };

    const handleInstallments = (newInstallment: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        installmentSelect = newInstallment;
        setManagement('instrucoes');
        getPayments(1, newInstallment);
    }

    const getPayments = async (methodPay: number | undefined, newInstallment: any) => {
        setErrorMessage("");

        if (methodPay === 2 || methodPay === -1) {
            setManagement('load');
        }

        if (methodPay === -1) {
            payments = [{}]
        } else {
            payments = [{
                installments: newInstallment,
                MerchantPayMethod: methodPay === 96 ? 2 : methodPay === 2 ? 0 : 6, // Passar 0 para faturado
                valor: "",
                payMethod: methodPay, //1 = cartao de credito | 47 = cartao de débito | 96 = pix | 2 - Dinheiro
                amountPaid: methodPay === 2 ? receiveCash : undefined,
                merchant: 0,
                refCode: '', //NSU
            }]
        }
        /* let payments = [{
        }]; */

        //"AmountPaid": 200 //CAMPO NOVO INFORMANDO QUANTO O CARO PAGOU EM CASH

        cartPDV.payments = payments;

        /* Tratamento do idioma das pulseiras */
        if (i18next.language === "es") {
            cartPDV.foreign = 1; // 1 - Estrangeiro
            cartPDV.language = 3; // 3 - Espanhol
        } else if (i18next.language === "en") {
            cartPDV.foreign = 1; // 1- Estrangeiro
            cartPDV.language = 2; // 2 - Inglês
        } else {
            cartPDV.foreign = 2; // 2 - Não estrangeiro
            cartPDV.language = 1; // 1 - Português
        }
        /* END - Tratamento do idioma das pulseiras */

        const cookies = parseCookies();
        const token = cookies.authSupervisor;
        const config = {
            headers: { 'Authorization': `Bearer ${token}`/* , 'Content-Type': 'application/json' */ },
        };

        try {
            const { data } = await api.post(`/Checkout/EnviaCheckoutBilheteria`,
                cartPDV, config);
            if (data?.statusCode !== 400) {
                if (data.data.log !== 1) {
                    if (methodPay === 1) {
                        createSession(1, data.data.data, newInstallment);
                        bookLocFinal = data.data.data;
                    } else if (methodPay === 47) {
                        createSession(47, data.data.data, newInstallment);
                        bookLocFinal = data.data.data;
                    } else if (methodPay === 2) {
                        setManagement('aprovado');
                        setPrinter(data.data.data.data);
                        bookLocFinal = data.data.data.data.bookingLoc;
                        tokenCode = data.data.data.data.codigoImpressao;

                        setTimeout(() => {
                            setManagement('imprimindo');
                        }, 4000);
                        dispatch(removeTotalCart(cartPDV.dados));
                    } else if (methodPay === -1) {
                        setManagement('aprovado');
                        setPrinter(data.data.data.data);
                        bookLocFinal = data.data.data.data.bookingLoc;
                        tokenCode = data.data.data.data.codigoImpressao;

                        setTimeout(() => {
                            setManagement('imprimindo');
                        }, 4000);
                        dispatch(removeTotalCart(cartPDV.dados));
                    } else {
                        setPixContent(data.data);
                        //bookLocFinal = data.data.data.data.bookingLoc;
                        //tokenCode = data.data.data.data.codigoImpressao;
                        //dispatch(removeTotalCart(cartPDV.dados));
                        setTimeout(() => {
                            setManagement('pix');
                        }, 600);
                    }
                } else {
                    //checkoutError('-920', 'API');
                    setErrorCode(`FFA00${dateChanged}-920`); // 920 - Erro log 1 na API de EnviaCheckoutTotem
                    setManagement('error');
                    setErrorMessage(data.data.texto);
                }
            }
        } catch (e) {
            //checkoutError('-921', 'API');
            setErrorCode(`FFA00${dateChanged}-921`); // 921 - Erro na API de EnviaCheckoutTotem
            setManagement('error');
            setErrorMessage("Error ao processar operação!");
        }
    }

    const checkoutError = async (code: any, typeMachine: any) => {
        const cookies = parseCookies();
        const token = cookies.authSupervisor;
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const { data } = await api.post(`/Checkout/FinalizeCheckoutWithPaymentErrorBilheteria`,
                {
                    "bookingLoc": bookLocFinal,
                    "merchant": Number(code),
                    "sourceBookingLoc": typeMachine
                }, config);
            if (data?.statusCode !== 400) { }
        } catch (e) { }
    }

    const createSession = useCallback(async (methodPay: number, info: any, newInstallment: any) => {
        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/session`, `sitefIp=${sitefIp}&storeId=${empresa}&terminalId=${terminal}&sessionParameters=[ParmsClient=1=${cnpjEstabelecimento};2=${cnpjLoja}]`);
            if (data?.statusCode !== 400) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                sessionId = data.sessionId;
                verifyPinpad(data.sessionId, methodPay, info, newInstallment);
            } else {
                setManagement('error');
                checkoutError('-910', 'Máquina');
                setErrorCode(`FFS00${dateChanged}-910`); // 910 - Erro criar sessão SITEF
            }
        } catch (e) {
            setManagement('error');
            checkoutError('-910', 'Máquina');
            setErrorCode(`FFS00${dateChanged}-910`); // 910 - Erro criar sessão SITEF
        }
    }, [sessionId]);

    const verifyPinpad = useCallback(async (dataSessionId: any, methodPay: number, info: any, newInstallment: any) => {
        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/pinpad/isPresent`, `sessionId=${dataSessionId}`);
            if (data?.statusCode !== 400) {
                if (methodPay === 1) {
                    startTransaction(1, 3, info, newInstallment, dataSessionId);
                } else if (methodPay === 47) {
                    startTransaction(1, 2, info, newInstallment, dataSessionId);
                }
            } else {
                setManagement('error');
                checkoutError('-911', 'Máquina');
                setErrorCode(`FFS00${dateChanged}-911`)// 911 - Erro Pinpad presente
            }
        } catch (e) {
            setManagement('error');
            checkoutError('-911', 'Máquina');
            setErrorCode(`FFS00${dateChanged}-911`)// 911 - Erro Pinpad presente
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const finishPayment = async (installMent: any) => {
        setErrorMessage("");

        try {
            const { data } = await api.post(`/Checkout/FinalizeCheckoutWithPaymentBilheteria`,

                {
                    "bookingLoc": bookLocFinal,
                    "payMethod": method,
                    "installments": installMent,
                    "brand": tempObject.bandeira,
                    "parameters": tempObject
                });

            if (data?.statusCode !== 400) {
                setPrinter(data.data.data.data);
                tokenCode = data.data.data.data.codigoImpressao;

                setTimeout(() => {
                    dispatch(removeTotalCart(cartPDV.dados));
                    setManagement("imprimindo");
                }, 4000);
            }
        } catch (e) {
            setManagement('error');
            checkoutError('-930', 'API');
            setErrorCode(`FFA00${dateChanged}-930`); // 930 - Erro na API de FinalizeCheckoutWithPaymentTotem
        }
    }

    price = (cartPDV.totalCart * 100).toFixed(0);

    const startTransaction = useCallback(async (tipo, funcao, booklock, newInstallment, sesssion) => {

        let param = '';

        if (tipo === 1) {
            param = `sessionId=${sesssion}&functionId=${funcao}&trnAmount=${price}&taxInvoiceNumber=${booklock}&taxInvoiceDate=${date}&taxInvoiceTime=${time}&cashierOperator=CAIXA&trnAdditionalParameters=&trnInitParameters=[ParmsClient=1=${cnpjEstabelecimento};2=${cnpjLoja}]`;
        }

        else if (tipo === 2) {
            param = `sessionId=${sesssion}&functionId=${funcao}&trnAmount=${price}&taxInvoiceNumber=${booklock}&taxInvoiceDate=${date}&taxInvoiceTime=${time}&cashierOperator=CAIXA&trnAdditionalParameters=&trnInitParameters=[ParmsClient=1=${cnpjEstabelecimento};2=${cnpjLoja}]`;
        }

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/startTransaction`, param);
            if (data?.statusCode !== 400) {
                if (data.serviceStatus !== 0) {

                }
                else if (data.clisitefStatus !== 10000) {

                }
                else {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    sessionId = data.sessionId;
                    continueTransaction('', Number(newInstallment), booklock);
                }

            } else {
                setManagement('metodo-pagamento');
            }
        } catch (e) {
            setManagement('error');
            checkoutError('-940', 'Máquina');
            setErrorCode(`FFA00${dateChanged}-940`); // 40 - Erro para startar transação
        }
    }, []);

    const continueTransaction = useCallback(async (dados, installMent, booklock) => {
        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/continueTransaction`, `sessionId=${sessionId}&data=${dados}&continue=${continua}`);

            if (data?.statusCode !== 400) {
                if (data.clisitefStatus !== 10000) {
                    if (data.clisitefStatus === 0) {
                        finishTransaction(1, booklock, installMent);
                    } else if (data.clisitefStatus === -2) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    } else if (data.clisitefStatus === -6) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    } else if (data.clisitefStatus === -40) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    } else {
                        setManagement('error');
                        checkoutError(`-0${Math.abs(data.clisitefStatus)}`, 'Máquina');
                        setErrorCode(`FFS00${dateChanged}-0${Math.abs(data.clisitefStatus)}`); // Retorno negativo SITEF 
                    }

                    return;
                }

                switch (data.commandId) {
                    case 0:
                        setContinueReady(false);

                        // acumula o resultado em um JSON, pode ser usado no final da transa��o para POST ao servidor da automa��o
                        // sessao.ret.push(item);
                        // console.info("TipoCampo [" + data.fieldId + "] Valor[" + data.data + "]");            

                        switch (data.fieldId) {
                            case 133:
                                tempObject.nsu_sitef = data.data;
                                break;
                            case 134:
                                tempObject.nsu_host = data.data;
                                break;
                            case 135:
                                tempObject.codigo_autorizacao = data.data;
                                break;
                            case 132:
                                tempObject.bandeira = data.data;
                                break;
                            case 157:
                                tempObject.estabelecimento = data.data;
                                break;
                            case 158:
                                tempObject.rede_autorizadora = data.data;
                                break;
                        }

                        if (data.fieldId === 121) {
                            tempObject.comprovante_estabelecimento = data.data;
                        }

                        // alert("Cupom Estabelecimento: \n" + data.data);

                        if (data.fieldId === 122) {
                            tempObject.comprovante_cliente = data.data;
                        }

                        continueTransaction('', installMent, booklock);
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 15:
                        setContinueReady(false);
                        setInfosMaquina(data.data);
                        continueTransaction('', installMent, booklock);
                        break;
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 16:
                        //Apaga display
                        //setContinueReady(false);
                        continueTransaction('', installMent, booklock);
                        break;
                    case 22:

                        if (data.fieldId === -1) {
                            setContinueReady(true);
                        } else {
                            setContinueReady(false);
                        }

                        setInfosMaquina(data.data);
                        setTimeout(() => {
                            continueTransaction('', installMent, booklock);
                        }, 2000);
                        break;
                    case 23:
                        // No comando 23, faz o reset da flag de continuidade, para sensibilizar tratamento de confirma��es de cancelamento da clisitef.
                        setContinueReady(true);
                        setTimeout(function () {
                            continueTransaction('', installMent, booklock);
                        }, 500);
                        break;
                    case 21:
                        if (installMent === 1) {
                            /* if (cont !== -1) { */
                            setContinueReady(false);
                            continueTransaction(1, installMent, booklock);
                            /* } */
                        } else {
                            /* if (cont !== -1) { */
                            setContinueReady(false);
                            continueTransaction(2, installMent, booklock);
                            /* } */
                        }
                        break;
                    case 20:
                        /* if (cont !== -1) { */
                        setContinueReady(false);
                        continueTransaction(0, installMent, booklock);
                        /* } */
                        break;
                    case 30:
                        /* if (cont !== -1) { */
                        setContinueReady(false);
                        continueTransaction(installMent, installMent, booklock);
                        /* } */
                        break;
                    case 31:
                    case 32:
                    case 33:
                    case 34:
                    // R$ 0,00 de taxa de serviço
                    /* continueTransaction(0, installMent, booklock);
                    break; */
                    // eslint-disable-next-line no-fallthrough
                    case 35:
                    case 38:
                        setContinueReady(false);
                        setTimeout(function () { continueTransaction(1, installMent, booklock); }, 100);
                        break;
                    default:
                        setContinueReady(false);
                        continueTransaction('', installMent, booklock);
                }
            }
        } catch (e) {
            setManagement('error');
            checkoutError('-950', 'Máquina');
            setErrorCode(`FFA00${dateChanged}-950`); // 950 - Erro para continuar transação
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const finishTransaction = useCallback(async (confirma, booklock, installMent) => {
        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/finishTransaction`, `sessionId=${sessionId}&taxInvoiceNumber=${booklock}&taxInvoiceDate=${date}&taxInvoiceTime=${time}&confirm=${confirma}`);
            if (data?.statusCode !== 400) {
                setManagement('aprovado');
                finishPayment(installMent);
            }
        } catch (e) {
            setManagement('error');
            checkoutError('-960', 'Máquina');
            setErrorCode(`FFA00${dateChanged}-960`); // 60 - Erro para finalizar transação
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function changeContinua(cont: any) {
        continua = cont;
    }

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === "CARACOL") {
            let isencaoAndCortesia: any = localStorage.getItem("IsencaoCortesia");
            let type: any = JSON.parse(isencaoAndCortesia);
            let aux = [];

            for (let i = 0; i < cartPDV.dados.length; i++) {
                aux.push(type?.includes(cartPDV.dados[i].productCode));
            }

            setIsFree(aux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartPDV.dados.length])


    const modalComparation = management === "metodo-pagamento" || management === 'cash' || management === 'parcelas' || management === 'pix';

    /*     if (process.env.REACT_APP_ENVIRONMENT === "CARACOL") {
            return (
                <>
                    <Modal
                        className={modalComparation && isFree?.includes(false) ? "modal-custom modalAuth modal-buy" : "modal-custom modalAuth modal-validation"}
                        show={modalShow}
                        onHide={() => {
                            if (modalComparation) {
                                setModalShow(false);
                            }
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header className={modalComparation && isFree?.includes(false) ? 'mb-5' : 'd-none'} closeButton={modalComparation && isFree?.includes(false) ? true : false}>
                            {
                                management === "parcelas"
                                    ?
                                    <div className='d-flex align-items-center gap-3'>
                                        <div>
                                            <FontAwesomeIcon
                                                icon={["far", "credit-card-front"]}
                                                size="1x"
                                                style={{ color: '#6CC9DD', fontSize: "2.75rem", height: "42px" }}
                                            />
                                        </div>
                                        <div>
                                            <h5 className='mb-0'>Cartão de Crédito</h5>
                                        </div>
                                    </div>
                                    :
                                    management === "cash"
                                        ?
                                        <div className='d-flex align-items-center gap-3'>
                                            <div>
                                                <FontAwesomeIcon
                                                    icon={["far", "money-bill-wave"]}
                                                    size="1x"
                                                    style={{ color: '#86C03F', fontSize: "2.75rem", height: "42px" }}
                                                />
                                            </div>
                                            <div>
                                                <h5 className='mb-0'>Dinheiro</h5>
                                            </div>
                                        </div>
                                        :
                                        ""
                            }
                        </Modal.Header>
                        <Modal.Body className={modalComparation && isFree?.includes(false) ? 'd-flex flex-column justify-content-between' : 'modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column modal-checkout'}>
                            {(() => {
                                switch (management) {
                                    case 'metodo-pagamento':
                                        return <SelectPayment action={handleNext} setModalShow={setModalShow} />
                                    case 'cash':
                                        return <PaymentCash setModalShow={setModalShow} getPayments={getPayments} setReceiveCash={setReceiveCash} />;
                                    case 'instrucoes':
                                        return <InstructionsSitef infos={infosMaquina} continueReady={continueReady} changeContinua={changeContinua} />;
                                    case 'parcelas':
                                        return <PaymentCreditCart action={handleInstallments} installMents={installment} setManagement={setManagement} setModalShow={setModalShow} />;
                                    case 'aprovado':
                                        return <InfoApproved />;
                                    case 'imprimindo':
                                        return <InstructionsPrint printer={printer} setModalShow={setModalShow} checkoutError={checkoutError} bookLocFinal={bookLocFinal} tokenCode={tokenCode} />;
                                    case 'nao-aprovado':
                                        return <>nao-aprovado</>;
                                    case 'error':
                                        return <InfoError setModalShow={setModalShow} errorCode={errorCode} errorMessage={errorMessage} />;
                                    case 'load':
                                        return <Load />;
                                    case 'pix':
                                        return <>pix</>;
                                    default:
                                        return null;
                                }
                            })()}
    
                        </Modal.Body>
    
                    </Modal>
                </>
            )
        } else { */
    return (
        <Modal
            className={management === "pix" ? `modal-custom modalAuth modal-buy bg-modal-pix` : modalComparation ? `modal-custom modalAuth modal-buy` : "modal-custom modalAuth modal-validation"}
            show={modalShow}
            onHide={() => {
                if (modalComparation) {
                    setModalShow(false);
                }
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className={management === "pix" ? "mb-4" : modalComparation ? 'mb-5' : 'd-none'} closeButton={modalComparation ? true : false}>
                {
                    management === "parcelas"
                        ?
                        <div className='d-flex align-items-center gap-3'>
                            <div>
                                <FontAwesomeIcon
                                    icon={["far", "credit-card-front"]}
                                    size="1x"
                                    style={{ color: '#6CC9DD', fontSize: "2.75rem", height: "42px" }}
                                />
                            </div>
                            <div>
                                <h5 className='mb-0'>Cartão de Crédito</h5>
                            </div>
                        </div>
                        :
                        management === "cash"
                            ?
                            <div className='d-flex align-items-center gap-3'>
                                <div>
                                    <FontAwesomeIcon
                                        icon={["far", "money-bill-wave"]}
                                        size="1x"
                                        style={{ color: '#86C03F', fontSize: "2.75rem", height: "42px" }}
                                    />
                                </div>
                                <div>
                                    <h5 className='mb-0'>Dinheiro</h5>
                                </div>
                            </div>
                            :
                            ""
                }
            </Modal.Header>
            <Modal.Body className={modalComparation ? 'd-flex flex-column justify-content-between' : 'modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column modal-checkout'}>
                {(() => {
                    switch (management) {
                        case 'metodo-pagamento':
                            return <SelectPayment action={handleNext} setModalShow={setModalShow} />
                        case 'cash':
                            return <PaymentCash setModalShow={setModalShow} getPayments={getPayments} setReceiveCash={setReceiveCash} />;
                        case 'instrucoes':
                            return <InstructionsSitef infos={infosMaquina} continueReady={continueReady} changeContinua={changeContinua} />;
                        case 'parcelas':
                            return <PaymentCreditCart action={handleInstallments} installMents={installment} setManagement={setManagement} setModalShow={setModalShow} />;
                        case 'aprovado':
                            return <InfoApproved />;
                        case 'imprimindo':
                            return <InstructionsPrint printer={printer} setModalShow={setModalShow} checkoutError={checkoutError} bookLocFinal={bookLocFinal} tokenCode={tokenCode} />;
                        case 'nao-aprovado':
                            return <>nao-aprovado</>;
                        case 'error':
                            return <InfoError setModalShow={setModalShow} errorCode={errorCode} errorMessage={errorMessage} />;
                        case 'load':
                            return <Load />;
                        case 'pix':
                            return <PayPix content={pixContent} action={setManagement} setPrinter={setPrinter} setManagement={setManagement} />;
                        default:
                            return null;
                    }
                })()}

            </Modal.Body>

        </Modal>
    )
}
/* } */

export default ModalBuy;