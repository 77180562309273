import React from "react";
import { Row, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./CashRegisterCards.scss";

export interface propInsert {
  cardsInfo: any
}

const CashRegisterCards: React.FC<propInsert> = ({
  cardsInfo,
}) => {


  if (cardsInfo !== null) {
    return (
      <>
        <div className="card-report">
          <Row className="cards">
            <div className="col-md-2 card-unit">
              <Card>
                <div className="col-md-10">
                  <Row>
                    <span className="card-title">Total de Vendas</span>
                  </Row>
                  <Row>
                    <span className="cart-content">{cardsInfo !== null ? cardsInfo[5]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}</span>
                  </Row>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    size="lg"
                    icon={["fad", "coins"]}
                    className="text-primary"
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-2 card-unit">
              <Card>
                <div className="col-md-10">
                  <Row>
                    <span className="card-title">Valor em Caixa</span>
                  </Row>
                  <Row>
                    <span className="cart-content">{cardsInfo !== null ? cardsInfo[6]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}</span>
                  </Row>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    size="lg"
                    icon={["fad", "coins"]}
                    className="text-primary"
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-2 card-unit">
              <Card>
                <div className="col-md-10">
                  <Row>
                    <span className="card-title">Dinheiro</span>
                  </Row>
                  <Row>
                    <span className="cart-content">{cardsInfo !== null ? cardsInfo[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}</span>
                  </Row>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    size="lg"
                    icon={["fad", "money-bill-wave"]}
                    className="text-primary"
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-2 card-unit">
              <Card>
                <div className="col-md-10">
                  <Row>
                    <span className="card-title">Cartão de Crédito</span>
                  </Row>
                  <Row>
                    <span className="cart-content">{cardsInfo !== null ? cardsInfo[1]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}</span>
                  </Row>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    size="lg"
                    icon={["fad", "credit-card-front"]}
                    className="text-primary"
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-2 card-unit">
              <Card>
                <div className="col-md-10">
                  <Row>
                    <span className="card-title">Cartão de Débito</span>
                  </Row>
                  <Row>
                    <span className="cart-content">{cardsInfo !== null ? cardsInfo[2]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}</span>
                  </Row>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    size="lg"
                    icon={["fad", "credit-card"]}
                    className="text-primary"
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-2 card-unit">
              <Card>
                <div className="col-md-10">
                  <Row>
                    <span className="card-title">Devolução</span>
                  </Row>
                  <Row>
                    <span className="cart-content">{cardsInfo !== null ? cardsInfo[4]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}</span>
                  </Row>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    size="lg"
                    icon={["fad", "dollar-sign"]}
                    className="text-primary"
                  />
                </div>
              </Card>
            </div>
            {
              process.env.REACT_APP_ENVIRONMENT === "CARACOL"
                ?
                <div className="col-md-2 card-unit">
                  <Card>
                    <div className="col-md-10">
                      <Row>
                        <span className="card-title">Isenção</span>
                      </Row>
                      <Row>
                        <span className="cart-content">{cardsInfo !== null ? cardsInfo[7]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}</span>
                      </Row>
                    </div>
                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon
                        size="lg"
                        icon={["fad", "dollar-sign"]}
                        className="text-primary"
                      />
                    </div>
                  </Card>
                </div>
                :
                ""
            }
            {
              process.env.REACT_APP_ENVIRONMENT === "CARACOL"
                ?
                <div className="col-md-2 card-unit">
                  <Card>
                    <div className="col-md-10">
                      <Row>
                        <span className="card-title">Cortesia</span>
                      </Row>
                      <Row>
                        <span className="cart-content">{cardsInfo !== null ? cardsInfo[8]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}</span>
                      </Row>
                    </div>
                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon
                        size="lg"
                        icon={["fad", "dollar-sign"]}
                        className="text-primary"
                      />
                    </div>
                  </Card>
                </div>
                :
                ""
            }
          </Row>
        </div>
      </>
    );
  } else {
    return (
      <></>
    )
  }
};

export default CashRegisterCards;
