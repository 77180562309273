import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
import { Dropdown, Modal } from 'react-bootstrap';

import '../../SearchTickets.scss';
import api from '../../../../services/api';
import { parseCookies } from 'nookies';
import ModalPrinting from '../../../../components/Modal/ModalPrinting/ModalPrinting';
import ModalDetails from '../../../../components/Modal/ModalDetails/ModalDetails';
import ModalCancelSitef from '../../../../components/Modal/ModalCancelSitef/ModalCancelSitef';
import Pagination from '../../../../components/Pagination/Pagination';
// import ModalNewLayout from '../../../../components/Modal/ModalNewLayout/ModalNewLayout';

export interface propItems {
    tickets?: any;
    loading?: any;
    totalRows?: any;
    pageCount?: any;
    setPageCount?: any;
    rowsPerPage?: any;
    setRowsPerPage?: any;
    zpl: any;
    notFoundTable: any;
    cancel: any;
}

let continua = 0;
let sessionId = '';

let bookingLoc = "";

let typeCancel: any;

const SearchTicketsFilter: React.FC<propItems> = ({
    tickets, loading, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, zpl, notFoundTable, cancel
}) => {

    const [modalShow, setModalShow] = useState<any>(null);
    const [reprintMessage, setReprintMessage] = useState<string>('Processando...');
    const [reprintLog, setReprintLog] = useState<number>(2);

    /* Cancelamento SITEF */
    const [, setLog] = useState<any>();
    const [management, setManagement] = useState('inicio');
    const [infosMaquina, setInfosMaquina] = useState('');
    const [, setModalContent] = useState<any>();
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [continueReady, setContinueReady] = useState<boolean>(false);

    const [modalPrint, setModalPrint] = useState<boolean>(false);
    const [printerSecondWay, setPrinterSecondWay] = useState<any>();
    const [printerInfo, setPrinterInfo] = useState<any>();

    const [errorCode, setErrorCode] = useState<string>("");

    const [modalDetails, setModalDetails] = useState<boolean>(false);
    const [details, setDetails] = useState<any>();

    const [returnValue, setReturnValue] = useState<any>("");

    const [isCancelTotal, setIsCancelTotal] = useState<boolean>(true);

    const date: any = (new Date().toLocaleString()).split(" ")[0].split("/").reverse().join("");
    const time: any = (new Date().toLocaleString()).split(" ")[1].split(":").join("");
    const dateChanged: any = new Date().getTime();

    useEffect(() => {
        if (tickets !== null) {
            bookingLoc = tickets[0]?.bookingLoc;
        }
    }, [tickets])

    const checkoutError = async (code: any, typeMachine: any) => {
        const cookies = parseCookies();
        const token = cookies.authSupervisor;
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const { data } = await api.post(`/Checkout/FinalizeCheckoutWithPaymentErrorBilheteria`,
                {
                    "bookingLoc": bookingLoc,
                    "merchant": Number(code),
                    "sourceBookingLoc": typeMachine,
                }, config);
            if (data?.statusCode !== 400) {

            }
        } catch (e) { }
    }

    function DetailsInfo(info: any) {
        setDetails(info);
        setModalDetails(true);
    }

    const addActionButton = (cell: any, row: any) => {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="1"
                                disabled={false}
                                onClick={() => DetailsInfo(row)}
                                /* href={"/vehicles/edit-vehicle?id=" + cell} */>
                                <FontAwesomeIcon style={{ marginRight: '5px' }} icon={['fal', 'print']} />
                                Detalhes
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                                disabled={false}
                                onClick={() => boxSecondWay(row.bookingLoc)}>
                                <FontAwesomeIcon style={{ marginRight: '5px' }} icon={['fal', 'print']} />
                                Reimprimir Recibos
                            </Dropdown.Item>
                            {
                                row.status !== 2
                                    ?
                                    <Dropdown.Item
                                        eventKey="3"
                                        disabled={false}
                                        onClick={() =>
                                            Print2ndWay(row)
                                        }
                                    >
                                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={['fal', 'print']} />
                                        Reimprimir Tickets
                                    </Dropdown.Item>
                                    :
                                    ""
                            }
                            {
                                row.status !== 2 /* && (row.metodoPagamento !== '1' && row.metodoPagamento !== '47') */ && cancel === true && row.metodoPagamento !== "96"
                                    ?
                                    <Dropdown.Item
                                        eventKey="4"
                                        disabled={false}
                                        className="text-danger remove"
                                        onClick={() =>
                                            cancelReservation(row, 1)
                                        }
                                    >
                                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={['fal', 'trash-alt']} />
                                        Cancelar Produto
                                    </Dropdown.Item>
                                    :
                                    ""
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    const convertDate = (cell: any) => {
        return (
            <>
                {cell.split("-").reverse().join("/")}
            </>
        )
    }

    const convertMoney = (cell: any, row: any) => {
        if (process.env.REACT_APP_ENVIRONMENT !== "CARACOL") {
            return (
                <>
                    {cell.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </>
            )
        } else {
            if (row.possuiPagamento === true) {
                return (
                    <>
                        {cell.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </>
                )
            } else {
                return (
                    <>
                        R$ 0,00
                    </>
                )
            }
        }
    }

    const convertStatus = (cell: any) => {
        return (
            <>
                {
                    cell === 1
                        ?
                        <div className="bg-ativo mx-auto">Ativo</div>
                        :
                        <div className="bg-inativo mx-auto">Cancelado</div>
                }
            </>
        )
    }

    const convertPayment = (cell: any) => {
        return (
            <>
                {
                    cell === "1"
                        ?
                        <div className="mx-auto">CARTÃO DE CRÉDITO</div>
                        :
                        cell === "47"
                            ?
                            <div className="mx-auto">CARTÃO DE DÉBITO</div>
                            :
                            cell === "96"
                                ?
                                <div className="mx-auto">PIX</div>
                                :
                                cell === "2"
                                    ?
                                    <div className="mx-auto">DINHEIRO</div>
                                    :
                                    cell === "85"
                                        ?
                                        <div className="mx-auto">FATURADO</div>
                                        :
                                        <div className="mx-auto">NÃO IDENTIFICADO</div>
                }
            </>
        )
    }

    const getNumberPax = (row: any, cell: any) => {
        return ((cell.adult || 0) + (cell.child || 0) + (cell.infant || 0) + (cell.elders || 0) + (cell.student || 0) + (cell.private || 0));
    }

    const columns = [
        { dataField: "bookingLoc", text: "Localizador" },
        { dataField: "nameBR", text: "Nome do Produto" },
        { dataField: "metodoPagamento", text: "Pagamento", formatter: convertPayment },
        { dataField: "amount", text: "Valor", formatter: convertMoney },
        { dataField: "dataCompra", text: "Data/Hora", formatter: convertDate },
        { dataField: "nsuHost", text: "NSU Host" },
        { dataField: "status", text: "Status", formatter: convertStatus },
        { dataField: "adult", text: "Pax", formatter: getNumberPax },
        { dataField: "idItem", text: "Ações", formatter: addActionButton },
    ];

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando Ingressos", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "ingressos", text: "Ingressos" }];

    const notFoundProducts = [{ ingressos: "Nenhum ingresso encontrado" }];
    /* [END] In case of empty table*/

    function cancelReservation(item: any, edit: any) {
        setModalCancel(true);
        setInfosMaquina("Processando...");
        setModalContent('loading');
        setManagement("loading");

        typeCancel = edit;

        const cancelamento = async () => {
            const cookies = parseCookies();
            const token = cookies.authSupervisor;
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try {
                const res = await api.post(`/Reservations/TryCancelItemBilheteria`,
                    {
                        "idItem": edit !== 2 ? item?.idItem : item[0]?.idItem,
                        "bookingLoc": edit !== 2 ? item?.bookingLoc : item[0]?.bookingLoc,
                        "cancelamentoTotal": edit === 2 ? true : false
                    }, config);

                if (res.status !== 400) {
                    if (res.data.data.log === 0 || res.data.data.log === '0') {

                        if ((item?.metodoPagamento === '96' || item?.metodoPagamento === '2' || item?.metodoPagamento === '88') || (item[0]?.metodoPagamento === '96' || item[0]?.metodoPagamento === '2' || item[0]?.metodoPagamento === '88')) {
                            setInfosMaquina(res.data.data.texto);
                            setLog(res.data.data.log);
                            setModalContent('sucesso');
                            setManagement("aprovado");

                            if (process.env.REACT_APP_ENVIRONMENT !== "CARACOL") {
                                if (edit === 2) {
                                    let total: any = 0;

                                    item.forEach((sum: any) => {
                                        total += sum.amount;
                                    });

                                    setReturnValue(total);
                                } else {
                                    setReturnValue(item.amount);
                                }
                            } else {
                                if (edit === 2) {
                                    let total: any = 0;

                                    item.forEach((sum: any) => {
                                        total += sum.possuiPagamento === true ? sum.amount : 0;
                                    });

                                    setReturnValue(total);
                                } else {
                                    setReturnValue(item.possuiPagamento === true ? item.amount : 0);
                                }
                            }
                        } else {
                            setInfosMaquina(res.data.data.texto);
                            setLog(res.data.data.log);
                            setModalContent('sucesso');
                            setManagement("aprovado");

                            setTimeout(() => {
                                if (edit === 2) {
                                    createSession(item[0], res.data.data.data.guid, res.data.data.data.valorReembolso);
                                } else {
                                    createSession(item, res.data.data.data.guid, res.data.data.data.valorReembolso);
                                }
                                setManagement("");
                            }, 5000);

                        }
                        //setTimeout(() => window.location.reload(), 2000);
                    } else {
                        setErrorCode(`FFA00${dateChanged}-922`); // 922 - Erro log 1 na API de TryCancelItemBilheteria
                        setManagement('error');
                        //setInfosMaquina(res.data.texto);
                        //checkoutError('-922', 'API');

                        /* if ((item.metodoPagamento === '96' || item.metodoPagamento === '2') || (item[0].metodoPagamento === '96' || item[0].metodoPagamento === '2')) {
                            setInfosMaquina(res.data.texto);
                            setLog(res.data.log);
                            setModalContent('error');
                            setManagement("error");
                        } else {
                            setInfosMaquina(res.data.data.texto);
                            setLog(res.data.data.log);
                            setModalContent('error');
                            setManagement("error");
                        } */
                    }
                    //setCancelTextResponse(res.data.data.texto);
                }
            } catch (error: any) {
                setErrorCode(`FFA00${dateChanged}-923`); // 923 - Erro na API de TryCancelItemBilheteria
                setManagement('error');
                setLog(1);
                //setConfirm("Erro ao processar operação <br /> Entre em contato com nosso suporte");
                //checkoutError('-923', 'API');

                /* if ((item.metodoPagamento === '96' || item.metodoPagamento === '2') || (item[0].metodoPagamento === '96' || item[0].metodoPagamento === '2')) {
                    setModalContent('error');
                    //setLog(1);
                    //setConfirm("Erro ao processar operação <br /> Entre em contato com nosso suporte");
                } else {
                    setInfosMaquina("Erro ao processar operação <br /> Entre em contato com nosso suporte");
                    setLog(1);
                    setModalContent('error');
                    setManagement("error");

                    setTimeout(() => {
                        //createSession(item, "ADIAUDAUD8AFOAJF0UAF0AUFOPJ6", 130);
                        //setManagement("");
                    }, 5000);
                } */
            }
        };

        cancelamento();
    }

    function changeContinua(cont: any) {
        continua = cont;
    }

    // Reimpressão de Cupom Não-Fiscal
    const boxSecondWay = useCallback(async (bookingLoc) => {
        setModalShow(true);

        const config = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };

        try {
            const { data } = await api.get(`/BilheteriaCaixa/SegundaViaComprovanteCaixa?BookingLoc=${bookingLoc}`, config);
            if (data?.statusCode !== 400) {
                setTimeout(() => {
                    coupom(data.data.comprovanteBase64, data.data.comprovateSitefBase64, data.data.comprovateEstabelicimentoBase64);
                    setReprintMessage("Imprimindo...");
                }, 3000);
            } else {
                setReprintMessage(data.data.texto);
                setReprintLog(1);
            }
        } catch (e) {
            setReprintMessage("Erro!!");
            setReprintLog(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const coupom = useCallback(async (receive, print, printEst) => {
        try {
            const { data } = await api.post(`http://localhost:5000/api/impressao/bilheteria/recibo`, {
                "ConteudoRecibo": receive || null,
                "ConteudoReciboSITEFCli": print || null,
                "ConteudoReciboSITEFEst": printEst || null
            });
            if (data?.statusCode !== 400) {
                setTimeout(() => {
                    setReprintMessage("Reimpressão realizada com sucesso!!");
                    setReprintLog(0);
                }, 5000);
            } else {
                setReprintMessage(data.errorMessage);
                setReprintLog(1);
            }
        } catch (e) {
            setReprintMessage("Erro!!");
            setReprintLog(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (modalShow === null) {
            setReprintMessage('Processando...');
            setReprintLog(2);
        }
    }, [modalShow]);
    // END - Reimpressão de Cupom Não-Fiscal

    function Print2ndWay(elem: any) {
        setPrinterSecondWay(elem);
        setModalPrint(true);
        setPrinterInfo(elem)
    }

    //
    //
    //
    /* Cancelamento SITEF */
    let local: any = localStorage.getItem("params");
    let storage: any = JSON.parse(local);

    const sitefIp = storage !== null && storage?.sitefIp !== null ? storage?.sitefIp : "127.0.0.1";
    const empresa = storage !== null && storage?.empresa !== null ? storage?.empresa : "00000000"; // 00004673
    const terminal = storage !== null && storage?.terminal !== null ? storage?.terminal : "REST0001"; // MU000901
    const cnpjEstabelecimento = storage !== null && storage?.cnpjEstabelecimento !== null ? storage?.cnpjEstabelecimento : "31406434895111";
    const cnpjLoja = storage !== null && storage?.cnpjLoja !== null ? storage?.cnpjLoja : "12523654185985";
    /* const caixa = storage !== null && storage?.caixa !== null ? storage?.caixa : "009"; */

    let tempObject = {
        nsu_sitef: '',
        nsu_host: '',
        codigo_autorizacao: '',
        terminal: terminal,
        estabelecimento: '',
        rede_autorizadora: '',
        bandeira: ''
    }

    let comp_cancel = {
        comprovante_estabelecimento_cancel: '',
        comprovante_cliente_cancel: ''
    }

    const createSession = useCallback(async (newObject, hash, valueTotal) => {
        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/session`, `sitefIp=${sitefIp}&storeId=${empresa}&terminalId=${terminal}&sessionParameters=[ParmsClient=1=${cnpjEstabelecimento};2=${cnpjLoja}]`);
            if (data?.statusCode !== 400) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                sessionId = data.sessionId;
                verifyPinpad(data.sessionId, newObject, hash, valueTotal);
            } else {
                setManagement('error');
                checkoutError('-910', 'Máquina');
                setErrorCode(`FFS00${dateChanged}-910`); // 910 - Erro criar sessão SITEF
            }
        } catch (e) {
            setInfosMaquina("Erro ao realizar cancelamento<br /> Por favor, entre em contato com nosso suporte");
            setLog(1);
            setModalContent('error');
            setManagement('error');
            checkoutError('-910', 'Máquina');
            setErrorCode(`FFS00${dateChanged}-910`); // 910 - Erro criar sessão SITEF
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const verifyPinpad = useCallback(async (dataSessionId, newObject, hash, valueTotal) => {
        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/pinpad/isPresent`, `sessionId=${dataSessionId}`);
            if (data?.statusCode !== 400) {
                getCancel(newObject.metodoPagamento, newObject, hash, valueTotal);
            } else {
                setManagement('error');
                checkoutError('-911', 'Máquina');
                setErrorCode(`FFS00${dateChanged}-911`)// 911 - Erro Pinpad presente
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        } catch (e) {
            setInfosMaquina("Erro ao realizar cancelamento<br /> Por favor, entre em contato com nosso suporte");
            setLog(1);
            setModalContent('error');
            setManagement("error");
            checkoutError('-911', 'Máquina');
            setErrorCode(`FFS00${dateChanged}-911`)// 911 - Erro Pinpad presente
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCancel = async (methodPay: string | undefined, newObject: any, hash: any, valueTotal: any) => {
        if (methodPay === '1') {
            startTransaction(1, 210, "1234", newObject, hash, valueTotal);
        } else if (methodPay === '47') {
            startTransaction(1, 211, "1234", newObject, hash, valueTotal);
        }
    }

    const startTransaction = useCallback(async (tipo, funcao, booklock, newObject, hash, valueTotal) => {
        let param = '';

        if (tipo === 1) {
            param = `sessionId=${sessionId}&functionId=${funcao}&trnAmount=${valueTotal * 100}&taxInvoiceNumber=${newObject.bookingLoc}&taxInvoiceDate=${date}&taxInvoiceTime=${time}&cashierOperator=CAIXA&trnAdditionalParameters=&trnInitParameters=[ParmsClient=1=${cnpjEstabelecimento};2=${cnpjLoja}]`;
        }

        else if (tipo === 2) {
            param = `sessionId=${sessionId}&functionId=${funcao}&trnAmount=${valueTotal * 100}&taxInvoiceNumber=${newObject.bookingLoc}&taxInvoiceDate=${date}&taxInvoiceTime=${time}&cashierOperator=CAIXA&trnAdditionalParameters=&trnInitParameters=[ParmsClient=1=${cnpjEstabelecimento};2=${cnpjLoja}]`;
        }

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/startTransaction`, param);
            if (data?.statusCode !== 400) {
                if (data.serviceStatus !== 0) {
                    console.log("Agente ocupado: " + data.serviceStatus + " " + data.serviceMessage);
                } else if (data.clisitefStatus !== 10000) {
                    console.log("Retorno " + data.clisitefStatus + " da CliSiTef");
                } else {
                    continueTransaction('', funcao, newObject, hash, valueTotal);
                }
            } else {
                setManagement('metodo-pagamento');
            }
        } catch (e) {
            setManagement('error');
            checkoutError('-940', 'Máquina');
            setErrorCode(`FFA00${dateChanged}-940`); // 40 - Erro para startar transação
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const continueTransaction = useCallback(async (dados, installMent, newObject, hash, valueTotal) => {

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/continueTransaction`, `sessionId=${sessionId}&data=${dados}&continue=${continua}`);

            if (data?.statusCode !== 400) {

                if (data.clisitefStatus !== 10000) {

                    if (data.clisitefStatus === 0) {
                        finishTransaction(1, newObject, hash, valueTotal);
                    } else if (data.clisitefStatus === -2) {
                        window.location.reload();
                    } else if (data.clisitefStatus === -6) {
                        window.location.reload();
                    } else if (data.clisitefStatus === -40) {
                        window.location.reload();
                    } else {
                        setManagement('error');
                        checkoutError(`-0${Math.abs(data.clisitefStatus)}`, 'Máquina');
                        setErrorCode(`FFS00${dateChanged}-0${Math.abs(data.clisitefStatus)}`); // Retorno negativo SITEF 
                    }

                    // alert("Fim - Retorno: " + data.clisitefStatus + "<br>" + s);
                    return;
                }

                switch (data.commandId) {
                    case 0:
                        setContinueReady(false);
                        // acumula o resultado em um JSON, pode ser usado no final da transa��o para POST ao servidor da automa��o
                        // sessao.ret.push(item);
                        // console.info("TipoCampo [" + data.fieldId + "] Valor[" + data.data + "]");            

                        switch (data.fieldId) {
                            case 133:
                                tempObject.nsu_sitef = data.data;
                                break;
                            case 134:
                                tempObject.nsu_host = data.data;
                                break;
                            case 135:
                                tempObject.codigo_autorizacao = data.data;
                                break;
                            case 132:
                                tempObject.bandeira = data.data;
                                break;
                            case 157:
                                tempObject.estabelecimento = data.data;
                                break;
                            case 158:
                                tempObject.rede_autorizadora = data.data;
                                break;
                        }

                        if (data.fieldId === 121) {
                            console.log(data.data);
                            comp_cancel.comprovante_estabelecimento_cancel = data.data;
                        }

                        if (data.fieldId === 122) {
                            console.log(data.data);
                            comp_cancel.comprovante_cliente_cancel = data.data;
                            //setManagement('aprovado');
                        }

                        continueTransaction('', installMent, newObject, hash, valueTotal);
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 15:
                        setContinueReady(false);
                        setInfosMaquina(data.data);
                        continueTransaction('', installMent, newObject, hash, valueTotal);
                        break;
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 16:
                        //Apaga display
                        setContinueReady(false);
                        continueTransaction('', installMent, newObject, hash, valueTotal);
                        break;
                    case 20:
                        setContinueReady(false);

                        setTimeout(() => {
                            continueTransaction(0, installMent, newObject, hash, valueTotal);
                        }, 1000);
                        break;
                    case 22:
                        setContinueReady(false);
                        setInfosMaquina(data.data);
                        continueTransaction('', installMent, newObject, hash, valueTotal);
                        break;
                    case 23:
                        // No comando 23, faz o reset da flag de continuidade, para sensibilizar tratamento de confirma��es de cancelamento da clisitef.
                        setContinueReady(true);
                        setTimeout(function () { continueTransaction('', installMent, newObject, hash, valueTotal); }, 500);
                        break;
                    case 21:
                        if (installMent === 2) {
                            setContinueReady(false);
                            setTimeout(function () { continueTransaction(1, installMent, newObject, hash, valueTotal); }, 500);
                        } else if (installMent === 3) {
                            //Função de Parcelas
                            setContinueReady(false);
                            continueTransaction(2, installMent, newObject, hash, valueTotal);
                        } else {

                            // Qualquer coisa retirar - Cancelamento CIELO
                            setContinueReady(false);
                            continueTransaction(1, installMent, newObject, hash, valueTotal);
                        }
                        break;
                    case 30:
                        if (data.fieldId === 515) {
                            let date: any = newObject.dataCompra.split("-").reverse().join("");
                            setContinueReady(false);
                            continueTransaction(date, installMent, newObject, hash, valueTotal);
                        } else if (data.fieldId === 516) {
                            setContinueReady(false);
                            continueTransaction(newObject.nsuHost, installMent, newObject, hash, valueTotal);
                        } else if (data.fieldId === -1) {
                            // transformar em NSU Sitef - Cancelamento CIELO
                            continueTransaction(newObject.nsuHost.split("").slice(3, 9).join(""), installMent, newObject, hash, valueTotal);
                        } else {
                            /* setContinueReady(false);
                            continueTransaction(1, installMent, newObject, hash, valueTotal); */
                        }

                        break;
                    case 31:
                    case 32:
                    case 33:
                    case 34:
                        if (data.fieldId === 147) {
                            if (typeCancel === 2) {
                                setContinueReady(false);
                                continueTransaction((valueTotal * 100), installMent, newObject, hash, valueTotal);
                            } else {
                                setContinueReady(false);
                                continueTransaction((newObject?.amount * 100), installMent, newObject, hash, valueTotal);
                            }

                        } else {
                            setContinueReady(false);
                            continueTransaction((valueTotal * 100), installMent, newObject, hash, valueTotal);
                        }
                        break;
                    case 35:
                    case 38:
                        setContinueReady(false);
                        setTimeout(function () { continueTransaction(1, installMent, newObject, hash, valueTotal); }, 100);
                        break;
                    default:
                        continueTransaction('', installMent, '', hash, valueTotal);
                }
            }
        } catch (e) {
            setManagement('error');
            checkoutError('-950', 'Máquina');
            setErrorCode(`FFA00${dateChanged}-950`); // 950 - Erro para continuar transação
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const finishTransaction = useCallback(async (confirma, newObject, hash, valueTotal) => {
        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/finishTransaction`, `sessionId=${sessionId}&taxInvoiceNumber=${bookingLoc}&taxInvoiceDate=${date}&taxInvoiceTime=${time}&confirm=${confirma}`);
            if (data?.statusCode !== 400) {
                finalizeReservation(hash, valueTotal);
            }
        } catch (e) {
            setManagement('error');
            checkoutError('-960', 'Máquina');
            setErrorCode(`FFA00${dateChanged}-960`); // 60 - Erro para finalizar transação
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function finalizeReservation(hash: any, valueTotal: any) {
        setManagement("");
        setInfosMaquina("Processando...");

        setModalContent('loading');

        const finalize = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };
            try {
                const res = await api.post(`/Checkout/ApproveSitefCancelAllItem`,
                    {
                        "guid": hash,
                        "parameters": comp_cancel
                    }, config);

                if (res.status !== 400) {
                    if (res.data.data.log === 0 || res.data.data.log === '0') {
                        setInfosMaquina(res.data.data.texto);
                        setManagement("aprovado");
                        coupom(res.data.data.data.comprovanteCancelamentoRecibo, res.data.data.data.comprovanteCancelamentoSitef, res.data.data.data.comprovanteCancelamentoSitefEstab);
                    } else {
                        setInfosMaquina(res.data.data.texto);
                        setManagement("error");
                        setErrorCode(`FFA00${dateChanged}-931`); // 931 - Log 1 na API de ApproveSitefCancelAllItem
                    }
                }
            } catch (error: any) {
                setManagement('error');
                setErrorCode(`FFA00${dateChanged}-932`); // 932 - Erro na API de ApproveSitefCancelAllItem
            }
        };
        finalize();
    }

    useEffect(() => {
        setIsCancelTotal(true);

        if (tickets !== null) {
            tickets.forEach((item: any) => {
                if (item.status === 2) {
                    setIsCancelTotal(false);
                }
            });
        }
    }, [tickets])

    if (tickets !== null) {
        return (
            <>
                <div className="table-default tickets-table">
                    <div className="table-container">

                        {
                            isCancelTotal === true && cancel === true && tickets[0].metodoPagamento !== '96'
                                ?
                                <div className='d-flex justify-content-end'>
                                    <button className='btn btn-primary text-white' onClick={() => cancelReservation(tickets, 2)}>Cancelar Todos</button>
                                </div>
                                :
                                ""
                        }

                        <BootstrapTable
                            bootstrap4
                            keyField={"id"}
                            data={tickets}
                            columns={columns}
                            striped={true}
                        />

                        {
                            cancel === false
                                ?
                                <Pagination
                                    totalRows={totalRows}
                                    pageCount={pageCount}
                                    setPageCount={setPageCount}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    selectNumberRows="no"
                                />
                                :
                                ""
                        }
                    </div>
                </div>

                <ModalCancelSitef
                    modalCancel={modalCancel}
                    setModalCancel={setModalCancel}
                    classModal="modal-confirm"
                    infosMaquina={infosMaquina}
                    management={management}
                    changeContinua={changeContinua}
                    continueReady={continueReady}
                    returnValue={returnValue}
                    errorCode={errorCode}
                />

                <ModalPrinting
                    setModalPrint={setModalPrint}
                    modalPrint={modalPrint}
                    checkoutError={checkoutError}
                    printerSecondWay={printerSecondWay}
                    printerInfo={printerInfo}
                />

                <ModalDetails
                    modalDetails={modalDetails}
                    setModalDetails={setModalDetails}
                    details={details}
                    Print2ndWay={Print2ndWay}
                    setInfosMaquina={setInfosMaquina}
                    setLog={setLog}
                    setModalCancel={setModalCancel}
                    setModalContent={setModalContent}
                    setManagement={setManagement}
                    setReturnValue={setReturnValue}
                /* cancelReservation={cancelReservation} */
                />

                <Modal
                    className="modalAuth modal-validation"
                    show={modalShow !== null}
                    onHide={() => { setModalShow(null) }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
                        >
                            <div>
                                {
                                    reprintLog !== 2
                                        ?
                                        <FontAwesomeIcon
                                            icon={["fal", reprintLog === 0 ? "check-circle" : reprintLog === 1 ? "times-circle" : "question-circle"]}
                                            size="5x"
                                            className='text-primary'
                                        />
                                        :
                                        <div className='load'></div>
                                }
                            </div>

                            <small>{reprintMessage}</small>
                            {
                                reprintLog !== 2
                                    ?
                                    <div className="d-flex justify-content-center pt-3">
                                        <button
                                            onClick={() => {
                                                setModalShow(null);
                                            }}
                                            className="btn btn-primary mx-2 w-25"
                                            style={{ color: '#FFF' }}
                                        >
                                            Sair
                                        </button>
                                    </div>
                                    :
                                    ""
                            }
                        </Modal.Body>
                    </>
                </Modal>
            </>
        );
    } else if (tickets === null && loading === true) {
        return (
            <>
                <div className="table-default partners-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        )
    } else if (tickets === null && notFoundTable === true) {
        return (
            <>
                <div className="table-default partners-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <></>
        );
    }
}

export default SearchTicketsFilter;