import React, { useEffect, useState } from 'react';
import { Accordion, Container, Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller, useForm } from "react-hook-form";

import '../../SearchTickets.scss';
import SingleCalendar from '../../../../components/Calendar/SingleCalendar';

export interface propItems {
    buscar: any;
}

const SearchTicketsFilter: React.FC<propItems> = ({
    buscar
}) => {
    const today = new Date();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const todayStr = `${today.getDate()}/${month}/${today.getFullYear()}`;

    const [type, setType] = useState<any>('0');
    const [token, setToken] = useState<any>('');
    const [nsuHost, setNsuHost] = useState<any>('');
    const [date, setDate] = useState<any>([todayStr]);
    const [ticket, setTicket] = useState<any>('');
    const [bookloc, setBookloc] = useState<any>('');

    const {
        control,
        handleSubmit,
    } = useForm();

    const onSubmit = (data: any) => {

        if (type === '1') {
            if (date.length === 1) {
                buscar(token, "", "", "", "");
            } else {
                buscar(token, "", "", "", "");
            }
        } else if (type === '2') {
            if (date.length === 1) {
                buscar("", nsuHost, date[0], "", "");
            } else {
                buscar("", nsuHost, date, "", "");
            }
        } else if (type === '3') {
            if (date.length === 1) {
                buscar("", "", "", ticket, "", "");
            } else {
                buscar("", "", "", ticket, "", "");
            }
        } else if (type === '4') {
            if (date.length === 1) {
                buscar("", "", "", "", true, "");
            } else {
                buscar("", "", "", "", true, "");
            }
        } else if (type === '5') {
            if (date.length === 1) {
                buscar("", "", "", "", "", bookloc);
            } else {
                buscar("", "", "", "", "", bookloc);
            }
        }

        delete data.typeSearch;
    }

    useEffect(() => {
        setToken("");
        setNsuHost("");
        setTicket("");
        setBookloc("")
        setDate([todayStr])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])


    return (
        <>
            <div className="default-filter  d-flex justify-content-center bg-white" style={{ textAlign: "left" }}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2 text-primary" /><span className="h5 mb-0 text-primary">Buscar Ingressos</span></Accordion.Header>
                        <Accordion.Body className="pt-0">
                            <Container className="p-0" fluid>
                                <Row>
                                    <div>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="my-3 justify-content-between">
                                                <Col md={9}>
                                                    <Row>
                                                        <Form.Group as={Col} md="3">
                                                            <Form.Label>Pesquisar por:</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="typeSearch"
                                                                render={({ field }: any) => (
                                                                    <Form.Select
                                                                        {...field}
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        autoComplete="off"
                                                                        onChange={(e: any) => setType(e.target.value)}
                                                                        value={type}
                                                                        required
                                                                    >
                                                                        <option value="0" disabled>Selecione</option>
                                                                        <option value="1">Token</option>
                                                                        <option value="2">NSU Host</option>
                                                                        <option value="3">Ticket</option>
                                                                        <option value="4">Operador</option>
                                                                        <option value="5">BookingLoc</option>
                                                                    </Form.Select>
                                                                )}
                                                            />
                                                        </Form.Group>

                                                        {
                                                            type === '2' //NSU
                                                                ?
                                                                <>
                                                                    <Form.Group as={Col} md="3">
                                                                        <Form.Label>NSU Host:</Form.Label>
                                                                        <Controller
                                                                            control={control}
                                                                            name="nsu"
                                                                            render={({ field }: any) => (
                                                                                <Form.Control
                                                                                    {...field}
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                    onChange={(e: any) => setNsuHost(e.target.value)}
                                                                                    value={nsuHost}
                                                                                    required
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="3">
                                                                        <Form.Label>Data de emissão:</Form.Label>
                                                                        <Controller
                                                                            control={control}
                                                                            name="date"
                                                                            render={({ field }: any) => (
                                                                                <SingleCalendar date={date} setDate={setDate} />
                                                                            )}
                                                                        />
                                                                    </Form.Group>
                                                                </>
                                                                :
                                                                type === '1' //Token
                                                                    ?
                                                                    <>
                                                                        <Form.Group as={Col} md="3">
                                                                            <Form.Label>Token:</Form.Label>
                                                                            <Controller
                                                                                control={control}
                                                                                name="token"
                                                                                render={({ field }: any) => (
                                                                                    <Form.Control
                                                                                        {...field}
                                                                                        variant="standard"
                                                                                        margin="normal"
                                                                                        autoComplete="off"
                                                                                        onChange={(e: any) => setToken(e.target.value)}
                                                                                        value={token}
                                                                                        required
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Form.Group>
                                                                        {/* <Form.Group as={Col} md="3">
                                                                        <Form.Label>Data de emissão:</Form.Label>
                                                                        <Controller
                                                                            control={control}
                                                                            name="date"
                                                                            render={({ field }: any) => (
                                                                                <SingleCalendar date={date} setDate={setDate} />
                                                                            )}
                                                                        />
                                                                    </Form.Group> */}
                                                                    </>
                                                                    :
                                                                    type === '3'
                                                                        ?
                                                                        <>
                                                                            <Form.Group as={Col} md="3">
                                                                                <Form.Label>Ticket:</Form.Label>
                                                                                <Controller
                                                                                    control={control}
                                                                                    name="ticket"
                                                                                    render={({ field }: any) => (
                                                                                        <Form.Control
                                                                                            {...field}
                                                                                            variant="standard"
                                                                                            margin="normal"
                                                                                            autoComplete="off"
                                                                                            onChange={(e: any) => setTicket(e.target.value)}
                                                                                            value={ticket}
                                                                                            maxLength={6}
                                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                            required
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </Form.Group>
                                                                        </>
                                                                        :
                                                                        type === '5'
                                                                            ?
                                                                            <>
                                                                                <Form.Group as={Col} md="3">
                                                                                    <Form.Label>BookingLoc:</Form.Label>
                                                                                    <Controller
                                                                                        control={control}
                                                                                        name="bookingloc"
                                                                                        render={({ field }: any) => (
                                                                                            <Form.Control
                                                                                                {...field}
                                                                                                variant="standard"
                                                                                                margin="normal"
                                                                                                autoComplete="off"
                                                                                                onChange={(e: any) => setBookloc(e.target.value)}
                                                                                                value={bookloc}
                                                                                                maxLength={7}
                                                                                                /* onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} */
                                                                                                required
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Form.Group>
                                                                            </>
                                                                            :
                                                                            <></>
                                                        }
                                                    </Row>
                                                </Col>
                                                {
                                                    type === '1' || type === '2' || type === '3' || type === '4' || type === '5'
                                                        ?
                                                        <Col md="3" className="d-flex justify-content-md-end align-items-end">
                                                            <Button type='submit' className="btn-default-filter" style={{ height: "38px", maxHeight: "38px" }}>Pesquisar Ingresso</Button>
                                                        </Col>
                                                        :
                                                        ""
                                                }
                                            </Row>
                                        </Form>
                                    </div>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

export default SearchTicketsFilter;