import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";

import RangeCalendar from '../../../components/Calendar/RangeCalendar';

export interface propFilter {
    buscar: any;
}

const FilterReport: React.FC<propFilter> = ({
    buscar
}) => {
    let today: any = new Date();

    const [filterDate, setFilterDate] = useState<any>([`${(today.toLocaleDateString())}`]);

    const {
        handleSubmit,
    } = useForm();

    /* const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "0";
            }
        }

        var today: any = new Date();


        //setFilterDate([][`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear() - 1}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`]);

    }; */

    const onSubmit = (data: any) => {
        buscar(filterDate);
    }

    return (
        <>
            <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2 text-primary" /><span className="h5 mb-0 text-primary">Buscar Relatórios</span></Accordion.Header>
                        <Accordion.Body className="pt-0">
                            <Container className="p-0" fluid>
                                <Row>
                                    <div>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3 d-flex justify-content-between">
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>Data</Form.Label>
                                                    <InputGroup hasValidation className="ranger-calendar">
                                                        <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                    </InputGroup>
                                                </Form.Group>
                                                <Col md="3" className="d-flex justify-content-md-end align-items-end">
                                                    <Button type='submit' className="btn-default-filter text-white w-50">Buscar</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

export default FilterReport;