import React, { useEffect, useState } from "react";
import api from '../../../../services/api';
import { setCookie, parseCookies } from 'nookies'

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

function AuthLogin() {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [showPass, setShowPass] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    // Parametrização
    let local: any = localStorage.getItem("params");
    let storage: any = JSON.parse(local);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    const sitefIp = query.get("ipsitef");
    const empresa = query.get("empresa");
    const terminal = query.get("terminal");
    const cnpjEstabelecimento = query.get("cnpjEstabelecimento");
    const cnpjLoja = query.get("cnpjLoja");
    const caixa = query.get("caixa");

    useEffect(() => {
        if (storage === null) {
            let parametros = {
                "sitefIp": sitefIp,
                "empresa": empresa,
                "terminal": terminal,
                "cnpjEstabelecimento": cnpjEstabelecimento,
                "cnpjLoja": cnpjLoja,
                "caixa": caixa
            }
            localStorage.setItem("params", JSON.stringify(parametros));
        } else {
            if (sitefIp !== null) {
                storage.sitefIp = sitefIp;
            }

            if (empresa !== null) {
                storage.empresa = empresa;
            }

            if (terminal !== null) {
                storage.terminal = terminal;
            }

            if (cnpjEstabelecimento !== null) {
                storage.cnpjEstabelecimento = cnpjEstabelecimento;
            }

            if (cnpjLoja !== null) {
                storage.cnpjLoja = cnpjLoja;
            }

            if (caixa !== null) {
                storage.caixa = caixa;
            }

            localStorage.setItem("params", JSON.stringify(storage));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // END - Parametrização

    // const url = window.location.href;
    // const codeUrl = url.split("/");
    // const fullSlugUrl = codeUrl[codeUrl.length - 1];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cookies = parseCookies();

    const onSubmit = (e: any) => {
        e.preventDefault();
        //destroyCookie(null, 'isCashierOpened');
        const getAuthenticate = async () => {
            try {
                const { data } = await api.post('/LoginBilheteria/Authenticate', {
                    "email": e.target.email.value,
                    "password": e.target.cust_pass.value,
                    "idCaixa": (storage.caixa === null || storage.caixa === undefined) ? "caixa999" : `caixa${storage.caixa}` // ID CAIXA GERADO PELO FRONT
                });
                if (data.statusCode !== 400) {
                    if (data.log === 0) {
                        setCookie(null, 'authSupervisor', data.data.perfils.filter((elem: any) => elem.nivel === 27)[0].token.token, {
                            maxAge: (60 * 60 * 8), //expira em 8 horas (60 * 60 * 8)
                            path: '/',
                        });

                        setCookie(null, 'Operator', `${data.data.nome} ${data.data.lastName}`, { //guardar nome e sobrenome do operador
                            maxAge: (60 * 60 * 8), //expira em 8 horas (60 * 60 * 8)
                            path: '/',
                        });

                        //setAuthState(true);
                        if (data.data.obrigaFechamento === true && cookies.isCashierOpened === undefined) {
                            setCookie(null, 'authFechamento', data.data.perfils.filter((elem: any) => elem.nivel === 27)[0].token.token, {
                                maxAge: (60 * 60 * 8), //expira em 8 horas (60 * 60 * 8)
                                path: '/',
                            });
                            window.location.href = window.location.origin + '/fechamento';
                        } else {
                            window.location.href = window.location.origin + '/cash-register';
                        }
                    } else if (data.log === 1) {
                        setLoading(false);
                        setErrorMessage(data.texto);
                    } else if (data.log === 2) {
                        setCookie(null, 'authFechamento', data.data.perfils.filter((elem: any) => elem.nivel === 27)[0].token.token, {
                            maxAge: (60 * 60 * 8), //expira em 8 horas (60 * 60 * 8)
                            path: '/',
                        });
                        window.location.href = window.location.origin + '/fechamento';
                    }

                } else {
                    setLoading(false);
                    setErrorMessage(data.errorMessage);
                }

            } catch (error: any) {
                setLoading(false);

                if (error?.response?.status === 400) {
                    setErrorMessage(error.response.data.errorMessage)
                }
            }
        }

        getAuthenticate();
    };

    const GetError = () => {
        if (errorMessage === null) {
            return (
                <div className="form-group"></div>
            );
        } else {
            return (
                <div className="invalid form-group">
                    <div className="invalid-feedback d-flex justify-content-start">{errorMessage}</div>
                </div>
            );
        }
    }

    return (
        <>
            <form className="user" name="login_form" onSubmit={(e) => onSubmit(e)}>
                <div className="form-group">
                    <label className='d-flex justify-content-start' htmlFor="exampleInputEmail">{t("login.email")}</label>
                    <input type="email" name="email" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp" />
                </div>
                <div className="form-group">
                    <label className='d-flex justify-content-start' htmlFor="exampleInputPassword">{t("login.password")}</label>
                    <input type={showPass === true ? "text" : "password"} name="cust_pass" className="form-control" id="exampleInputPassword" />
                    <div className="iconsPassword">
                        {showPass === false ?
                            <FontAwesomeIcon
                                icon={["fal", "eye-slash"]}
                                size="1x"
                                onClick={() => { setShowPass(!showPass) }}
                            />
                            :
                            <FontAwesomeIcon
                                icon={["fal", "eye"]}
                                size="1x"
                                onClick={() => { setShowPass(!showPass) }}
                            />
                        }
                    </div>
                </div>
                <GetError />

                {/* <div className="form-group">
                    <div className="custom-control custom-checkbox small">
                        <input type="checkbox" name="remember" className="custom-control-input" value="1" id="customCheck" />
                        <label className="custom-control-label" htmlFor="customCheck">{t("login.remember")}</label>
                    </div>
                </div> */}
                <button type="submit" className="form-button btn btn-primary btn-block btn-login" onClick={() => { setLoading(true) }}>{loading === true ? <div className="load"></div> : t("login.send")}</button>
            </form>
        </>
    )
}

export default AuthLogin;

