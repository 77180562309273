import React from "react";

import './Load.scss';

function Load() {
    return (
        <>
            <div style={{ margin: "35px auto" }}>
                <div className="load-checkout"></div>
            </div>
        </>
    )
}

export default Load;