import React, { useEffect, useState } from "react";
import api from "../../services/api";
import configData from "../../config/config.json";

import DatePicker from "react-multi-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Calendar.scss";
import i18next from "i18next";

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];

const numberMonths = window.innerWidth < 480 ? 1 : 2;

export interface productInfo {
    modalityID: any;
    product: any;
    productCode: any;
    changePriceDateTour?: any;
    index: any;
    dateTour?: any;
    startTimeLocal?: any;
    setPrice: any;
    isTour: boolean;
    setShowPickup?: any;
    setShowDropdownHours?: any;
    edit?: any;
    editInfo?: any;
}

const CustomMultipleInput = ({ openCalendar, value, modalityID, valueCalendar, startTimeLocal, setNewGetDate, edit, editInfo }: any) => {
    if(startTimeLocal){
        if(valueCalendar.data === undefined){
            var teste: any = document.getElementById(`date-${modalityID}`);
            if(teste !== null){
                value = startTimeLocal.props.children;
            }
        }
    }

    function getArrowLimit() {
        var today: any = new Date();
        var monthControl = (today.getMonth() + 1);
        //var actual: any = monthControl;

        let timer1 = setTimeout(() => {

            var teste: any = document.getElementsByClassName('rmdp-arrow-container');


            if (teste.length >= 1) {

                teste[0].addEventListener("click", (e: any) => {
                    monthControl = monthControl - 1;
                }, false)

                teste[1].addEventListener("click", (e: any) => {
                    monthControl = monthControl + 1;
                    setNewGetDate(monthControl);

                }, false)
            }

        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }

    return (
        <div className="d-flex">
            <FontAwesomeIcon
                icon={["fal", "calendar-alt"]}
                size="1x"
                style={{ color: "#000", position: "absolute", transform: "translate(4px, 10px)", fontSize: "22px" }}
            />
            <input
                className={`form-control rounded-0 py-2 rounded calendar-input date-${modalityID}`}
                onFocus={() => { openCalendar(); getArrowLimit(); }}
                value={value}
                data-object={JSON.stringify(valueCalendar)}
                id={`date-${modalityID}`}
                placeholder={edit !== true ? "Quando?" : editInfo?.dateTour?.data.split("-").reverse().join("/")}
                readOnly
            />
        </div>
    );
};

const DoubleCalendar: React.FC<productInfo> = ({
    modalityID,
    product,
    productCode,
    changePriceDateTour,
    dateTour,
    index,
    startTimeLocal = undefined,
    setPrice,
    isTour = true,
    setShowPickup,
    setShowDropdownHours,
    edit,
    editInfo
}: productInfo) => {
    const [dayProduct, setDayProduct] = React.useState<any>({});
    const [valueCalendar, setValueCalendar] = useState<any>({});
    const [newGetDate, setNewGetDate] = React.useState<any>(null);

    const [auxTarif, setAuxTarif] = useState<any>(null);

    function handleDateTour(objCalendar: any) {
        let auxTar: any = [];


        for (let i = 0; i < auxTarif.length; i++) {
            if (auxTarif[i].selectedDate.split("T")[0] === objCalendar.data) {
                auxTar.push(auxTarif[i])
            }
        }

        if(product.embarkingType === "0") {
            setShowPickup(true);
            setShowDropdownHours(true);
        } else {
            setShowPickup(true);
        }
        
        var aux: any = dateTour;
        aux[index].data = objCalendar.data === undefined ? editInfo.dateTour.data : objCalendar.data;
        aux[index].idSellingType = objCalendar.idSellingType;
        aux[index].daysUniqueTarCode = objCalendar.daysUniqueTarCode;
        aux[index].prodModUniqueCode = objCalendar.prodModUniqueCode;
        aux[index].tarUniqueCode = objCalendar.tarUniqueCode;
        aux[index].idTarif = objCalendar.idTarifario;
        aux[index].auxTarif = auxTar;

        changePriceDateTour(aux);
    }

    useEffect(() => {
        let aux: any = [];
        let idChannel: number = 1;

        async function getDays(info: any) {
            var today: any;
            var todayDay: any;
            var todayMonth: any;
            var todayYear: any;
            var endMonth: any;
            var endYear: any;

            if (newGetDate === null) {
                today = new Date();
                todayDay = today.getDate();
                todayMonth = (today.getMonth() + 1);
                todayYear = today.getFullYear();
                endMonth = (today.getMonth() + 3);
                endYear = today.getFullYear();

                if (endMonth > 11) {
                    endMonth = endMonth - 11;
                    endYear = parseInt(endYear) + 1;
                }
            } else {
                today = new Date();
                todayDay = today.getDate();
                todayMonth = newGetDate - 1;
                todayYear = today.getFullYear();
                endMonth = newGetDate + 3;
                endYear = today.getFullYear();

                if (todayMonth > 12) {
                    todayMonth = todayMonth - 12;
                    todayYear = parseInt(todayYear) + 1;
                }
                if (endMonth > 12) {
                    endMonth = endMonth - 12;
                    endYear = parseInt(endYear) + 1;
                }
            }

            todayDay = todayDay >= 28 ? 28 : todayDay;

            try {
                const { data } = await api.post(
                    isTour === true ?
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/GetCalendarTourAsync` :
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/GetCalendarTicketAsync`,
                    {
                        dateStart: todayYear + '-' + todayMonth + '-' + todayDay,
                        dateEnd: endYear + '-' + endMonth + '-' + todayDay,
                        productCode: `${productCode}`,
                        tarCode: `${info.tarCode}`,
                        ProdModCode: `${product.prodModCode}`,
                        lang: i18next.language === "pt-BR" ? 'BR' : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR",
                        idCanal: idChannel
                    }
                );

                const objControl = {
                    idSellingType: data.data.dates[0].idSellingType,
                    adult: data.data.dates[0].priceAdultFinal,
                    child: data.data.dates[0].priceChildFinal,
                    infant: data.data.dates[0].priceInfantFinal,
                    student: null,
                    elders: null,
                    global: data.data.dates[0].priceGlobalFinal,
                    idTarif: data.data.dates[0].idTarifario
                };

                setPrice(objControl);

                if (data.status !== 400) {
                    aux.push(...data.data.dates);

                    data.data.dates.forEach((dayProduct: any) => {
                        let year = dayProduct.selectedDate.split("-")[0];
                        let month = dayProduct.selectedDate.split("-")[1];
                        let day = dayProduct.selectedDate.split("-")[2];

                        let controlObject: { [x: number]: { price: any; data: any, idTarif: any, priceAdultFinal: any, priceChildFinal: any, priceGlobalFinal: any, priceInfantFinal: any, idSellingType: any, daysUniqueTarCode: any, prodModUniqueCode: any, tarUniqueCode: any, idTarifario: any} };
                        let sControl: any = year + "-" + month + "-" + day.split("T")[0];
                        controlObject = {
                            [sControl]: {
                                price:
                                    dayProduct.priceAdultFinal === 0.0
                                        ? dayProduct.priceGlobalFinal
                                        : dayProduct.priceAdultFinal,
                                idTarif: dayProduct.idTarifario,
                                priceAdultFinal: dayProduct.priceAdultFinal,
                                priceChildFinal: dayProduct.priceChildFinal,
                                priceInfantFinal: dayProduct.priceInfantFinal,
                                priceGlobalFinal: dayProduct.priceGlobalFinal,
                                idSellingType: dayProduct.idSellingType,
                                daysUniqueTarCode: dayProduct.daysUniqueTarCode,
                                prodModUniqueCode: dayProduct.prodModUniqueCode,
                                tarUniqueCode: dayProduct.tarUniqueCode,
                                idTarifario: dayProduct.idTarifario,
                                data: year + "-" + month + "-" + day.split("T")[0],
                            },
                        };
                        setDayProduct((curObjDeal: any) => ({
                            ...curObjDeal,
                            ...controlObject,
                        }));

                        setAuxTarif(aux);
                    });
                }
            } catch (error: any) {
                
                console.log(error)
            }
        }

        const config = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };

        async function getIdChannel() {
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/bilheteria`, config);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;
                    for (let i = 0; i < product.allTarif.length; i++) {
                        getDays(product.allTarif[i]);
                    }
                }
            } catch (error) { }
        }

        getIdChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, productCode, newGetDate]);

    if (dayProduct !== null) {
        return (
            <DatePicker
                weekDays={weekDays}
                numberOfMonths={numberMonths}
                calendarPosition={`bottom-bottom`}
                fixMainPosition={true}
                disableMonthPicker
                disableYearPicker
                months={months}
                format={configData.DATE.PT}
                render={<CustomMultipleInput modalityID={modalityID} valueCalendar={valueCalendar} startTimeLocal={startTimeLocal} setNewGetDate={setNewGetDate} edit={edit} editInfo={editInfo} />}
                className="multi-locale-days"
                mapDays={({ date }): any => {
                    let controlNumber =
                        JSON.stringify(date.day).length === 1 ? "0" + date.day : date.day;
                    let controlMonth =
                        JSON.stringify(date.month.number).length === 1
                            ? "0" + date.month.number
                            : date.month.number;
                    let controlYear = date.year;

                    const objCalendar =
                        dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber];
                    if (objCalendar !== undefined && objCalendar.price !== "null") {
                        /* console.log(dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber]) */
                        if (
                            controlYear + "-" + controlMonth + "-" + controlNumber ===
                            objCalendar.data
                        ) {
                            return {
                                children: (
                                    <div
                                    className="renan"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: "18px",
                                            height: "100%",
                                            justifyContent: "center"
                                        }}
                                        onClick={() => { setValueCalendar(objCalendar); handleDateTour(objCalendar) }}
                                    >
                                        <div style={{ textAlign: "center" }}>
                                            {date.format("D")}
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "13px",
                                                minHeight: "14px",
                                            }}
                                        >
                                            {" "}
                                            {objCalendar !== undefined
                                                ? objCalendar.price !== "null"
                                                    ? `R$ ${objCalendar.price
                                                        .toFixed(2)
                                                        .replace(".", ",")}`
                                                    : ""
                                                : ""}
                                        </div>
                                    </div>
                                ),
                            };
                        } else {
                            return {
                                disabled: true,
                            };
                        }
                    } else {
                        return {
                            disabled: true,
                        };
                    }
                }}
            ></DatePicker>
        );
    } else {
        return <></>;
    }
};

export default DoubleCalendar;
