import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

// import Exemple from './components/Example/Exemple';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Tickets from './pages/Tickets/Tickets';
import SearchTickets from './pages/SearchTickets/SearchTickets';
import PrivateRoute from './infra/PrivateRoute';
import { parseCookies } from 'nookies';
import CashRegister from './pages/CashRegister/CashRegister';
import Reports from './pages/Reports/Reports';
import CloseCashier from './pages/CloseCashier/CloseCashier';

function Routes() {

    //isAuthenticated
    //0 - login do caixa e supervisor (sucesso)
    //1 - login do caixa (sucesso) - esperando login do supervisor
    //2 - login do caixa deu log 2 - favor fechar caixa

    const [authentication, setAuthState] = useState<boolean>(false);

    const cookies = parseCookies();

    useEffect(() => {
        if (cookies.authSupervisor !== undefined) {
            setAuthState(true);
        } else {
            setAuthState(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/fechamento" exact component={CloseCashier} />
                {/* <Route path="/dasboard" exact component={Dashboard} /> */}

                <PrivateRoute path="/caixa" exact component={Dashboard} isAuthenticated={authentication} setAuthState={setAuthState} />
                <PrivateRoute path="/cash-register" exact component={CashRegister} isAuthenticated={authentication} setAuthState={setAuthState} />
                <PrivateRoute path="/buscar-ingressos" exact component={SearchTickets} isAuthenticated={authentication} setAuthState={setAuthState} />
                <PrivateRoute path="/tickets" exact component={Tickets} isAuthenticated={authentication} setAuthState={setAuthState} />
                <PrivateRoute path="/reports" exact component={Reports} isAuthenticated={authentication} setAuthState={setAuthState} />

            </Switch>


        </>
    );
}

export default Routes;