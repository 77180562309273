import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Route } from 'react-router-dom';
//import Header from '../components/Header/Header';
import LoginSupervisor from '../components/LoginSupervisor/LoginSupervisor';
import Menu from '../components/Menu/Menu';
import api from '../services/api';

let continua = 0;

let cupomFiscal = '';
//let numberID = '';
let dataFiscal = '';
let horaFiscal = '';
let pendente = '';
let sessionId = '';

let pendLoc = '';
let pendDate = '';
let pendTime = '';
let pendStatus = '';

export interface propItems {
    component: React.FC;
    path: string;
    exact: boolean;
    isAuthenticated: boolean;
    setAuthState: any;
}

const PrivateRoute: React.FC<propItems> = ({
    component, path, exact, isAuthenticated, setAuthState
}) => {
    const [modalShow, setModalShow] = useState<any>(null);
    const [reprintMessage, setReprintMessage] = useState<string>('Processando...');
    const [reprintLog, setReprintLog] = useState<number>(2);

    let local: any = localStorage.getItem("params");
    let storage: any = JSON.parse(local);

    const sitefIpNew = storage !== null && storage?.sitefIp !== null ? storage?.sitefIp : "127.0.0.1";
    const empresaNew = storage !== null && storage?.empresa !== null ? storage?.empresa : "00000000"; // 00004673
    const terminalNew = storage !== null && storage?.terminal !== null ? storage?.terminal : "REST0001"; // MU000901
    const cnpjEstabelecimentoNew = storage !== null && storage?.cnpjEstabelecimento !== null ? storage?.cnpjEstabelecimento : "31406434895111";
    const cnpjLojaNew = storage !== null && storage?.cnpjLoja !== null ? storage?.cnpjLoja : "12523654185985";
    //const caixa = storage !== null && storage?.caixa !== null ? storage?.caixa : "009";

    const date: any = (new Date().toLocaleString()).split(" ")[0].split("/").reverse().join("");
    const time: any = (new Date().toLocaleString()).split(" ")[1].split(":").join("");

    // Verificação do SITEF
    const verifySession = useCallback(async (order: any) => {

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/session`, `sitefIp=${sitefIpNew}&storeId=${empresaNew}&terminalId=${terminalNew}&sessionParameters=[ParmsClient=1=${cnpjEstabelecimentoNew};2=${cnpjLojaNew}]`);
            if (data?.statusCode !== 400) {

                if (data.sessionId !== undefined) {
                    order = 0;
                }

                verifyPinpad(data.sessionId, order);
                sessionId = data.sessionId;
            }

        } catch (e) {
            //setReadySITEF('error'); // Máquininha com problema.
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const verifyPinpad = useCallback(async (sessionId: string, order: any) => {

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/pinpad/isPresent`, `sessionId=${sessionId}`);
            if (data?.statusCode !== 400) {
                startTransaction(1, 130, order);
                // setReadySITEF('success');
            }
        } catch (e) {
            // setReadySITEF('error'); // Máquininha com problema.
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startTransaction = useCallback(async (tipo, funcao, order) => {
        let param = '';

        if (tipo === 1) {
            param = `sessionId=${sessionId}&functionId=${funcao}&trnAmount=&taxInvoiceNumber=&taxInvoiceDate=${date}&taxInvoiceTime=${time}&cashierOperator=CAIXA&trnAdditionalParameters=&trnInitParameters=[ParmsClient=1=${cnpjEstabelecimentoNew};2=${cnpjLojaNew}]`;
        }

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/startTransaction`, param);

            if (data?.statusCode !== 400) {
                if (data.serviceStatus !== 0) {
                    /*                     setReadySITEF('pendente');
                                        setTextError('Impressora não disponível'); */
                    setTimeout(() => {
                        verifySession(1);
                    }, 3000);

                } else {
                    continueTransaction('', '', order);
                }

            } else {
                //setReadySITEF('error');
            }
        } catch (e) {
            //setReadySITEF('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const continueTransaction = useCallback(async (dados, installMent, order) => {

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/continueTransaction`, `sessionId=${sessionId}&data=${dados}&continue=${continua}`);

            if (data?.statusCode !== 400) {

                if (data.clisitefStatus !== 10000) {

                    if (data.clisitefStatus === 0) {

                        if (Number(pendente) >= 1) {
                            verifyPendente(cupomFiscal, order);
                            //Printer();
                        }
                    } else {
                        //setReadySITEF('error');
                    }

                    return;
                }

                switch (data.commandId) {
                    case 0:
                        switch (data.fieldId) {
                            case 160:
                                cupomFiscal = data.data;
                                break;
                            case 161:
                                break;
                            case 163:
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                dataFiscal = data.data;
                                break;
                            case 164:
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                horaFiscal = data.data;
                                break;
                            case 210:
                                if (order === 0) {
                                    setTimeout(() => {
                                        //setReadySITEF('success');
                                    }, 500);
                                }

                                if (Number(data.data) > 0) {
                                    pendente = data.data
                                } else {
                                    pendente = data.data
                                    //Printer();
                                }
                                
                                break;
                            case 1319:
                                break;
                        }

                        continueTransaction('', installMent, order);
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 15:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 16:
                    case 22:
                        continueTransaction('', installMent, order);
                        break;
                    case 23:
                    case 21:
                    case 20:
                    case 30:
                    case 31:
                    case 32:
                    case 33:
                    case 34:
                    case 35:
                    case 38:
                        break;

                    default:
                        continueTransaction('', installMent, order);
                }
            }
        } catch (e) {
            //setReadySITEF('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const finishTransaction = useCallback(async (order) => {
        setModalShow(true);

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/finishTransaction`, `sessionId=${sessionId}&taxInvoiceNumber=${pendLoc}&taxInvoiceDate=${pendDate}&taxInvoiceTime=${pendTime}&confirm=${pendStatus}`);
            if (data?.statusCode !== 400) {
                //console.log(pendStatus);
                if (pendStatus === '0') {
                    //console.log('Cancelado');
                    setReprintMessage('Última transação TEF não foi efetuada. ');
                    setReprintLog(1);
                } else {
                    //console.log('Efetuado');
                    setReprintMessage('Última transação TEF foi efetuada. ');
                    setReprintLog(0);
                }
                if (order === 1) {
                    setTimeout(() => {
                        //setReadySITEF('success');
                    }, 1500);
                }
            }
        } catch (e) {
            //setReadySITEF('error');
        }
    }, []);

    const verifyPendente = useCallback(async (cupomFiscal, order) => {
        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/bookings/getstatussitef/${cupomFiscal}`);

            if (data?.statusCode !== 400) {
                //console.log(data.data)
                pendLoc = data.data.bookingloc;
                pendDate = data.data.date;
                pendTime = data.data.time;
                pendStatus = data.data.status;

                finishTransaction(order);
            }
        } catch (e) {
            //setReadySITEF('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* useEffect(() => {
        verifySession('');
    }, [verifySession]); */
    // END - Verificação do SITEF

    /* useEffect(() => {
        if (isAuthenticated !== false) {
            console.log("Aqqqquuuuuiiii!");
            verifySession('');
        }
    }, [isAuthenticated]) */
    //isAuthenticated
    //0 - login do caixa e supervisor (sucesso)
    //1 - login do caixa (sucesso) - esperando login do supervisor
    //2 - login do caixa deu log 2 - favor fechar caixa

    return (
        <>
            <div>
                {/* <Route path={path} exact={exact} component={component} />

                {isAuthenticated === false && <><LoginSupervisor isAuthenticated={isAuthenticated} setAuthState={setAuthState} /></>} */}

                {isAuthenticated === true
                    ?
                    <>
                        <Route path={path} exact={exact} component={component} />

                        <Modal
                            className="modalAuth modal-validation"
                            show={modalShow !== null}
                            onHide={() => { setModalShow(null) }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <>
                                <Modal.Body
                                    className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
                                >
                                    <div>
                                        {
                                            reprintLog !== 2
                                                ?
                                                <FontAwesomeIcon
                                                    icon={["fal", reprintLog === 0 ? "check-circle" : reprintLog === 1 ? "times-circle" : "question-circle"]}
                                                    size="5x"
                                                    className='text-primary'
                                                />
                                                :
                                                <div className='load'></div>
                                        }
                                    </div>

                                    <small>{reprintMessage}</small>
                                    {
                                        reprintLog !== 2
                                            ?
                                            <div className="d-flex justify-content-center pt-3">
                                                <button
                                                    onClick={() => {
                                                        setModalShow(null);
                                                    }}
                                                    className="btn btn-primary mx-2 w-25"
                                                    style={{ color: '#FFF' }}
                                                >
                                                    Sair
                                                </button>
                                            </div>
                                            :
                                            ""
                                    }
                                </Modal.Body>
                            </>
                        </Modal>
                    </>
                    :
                    <>
                        <div className="d-flex">
                            <Menu />

                            <div style={{
                                width: "100%"
                            }}>
                                <div className='contentPDV'>
                                    {/* <Header pageTitle={"Login supervisor"} setInfoTotal={() => { }} allowStatus={false} /> */}
                                    <div >
                                        <LoginSupervisor isAuthenticated={isAuthenticated} setAuthState={setAuthState} verifySession={verifySession} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default PrivateRoute;