import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

import "./DropdownHours.scss";
import { Col, Row } from "react-bootstrap";
import api from "../../services/api";

export interface propsR {
    changePriceDateTour?: any,
    dateTour?: any,
    index?: any,
    setPeopleDisponible?: any,
    edit?: any,
    item?: any
    editInfo?: any;
    isIntegration?: any;
    integrationHours?: any;
}

const DropdownHours: React.FC<propsR> = ({
    changePriceDateTour, dateTour, index = 0, setPeopleDisponible, edit = false, item, editInfo, isIntegration = false, integrationHours
}: propsR) => {
    const indexChange: any = index;

    const [isOpen, setIsOpen] = useState<any>(false);
    const [hours, setHours] = useState<any>(null);
    const [select, setSelect] = useState<any>(null);
    const [loading, setLoading] = useState<any>(true);

    /* Detecta clique fora da div#wrapper para fechar*/
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleClickHours = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    function handleSelectHours(hours: any, price: any) {
        setSelect(hours);

        var aux: any = dateTour;

        if(isIntegration === false) {
            if (dateTour?.data === undefined && edit === true) {
                aux[index].data = editInfo?.dateTour?.data;
                aux[index].daysUniqueTarCode = editInfo?.dateTour?.daysUniqueTarCode;
                aux[index].prodModUniqueCode = editInfo?.dateTour?.prodModUniqueCode;
                aux[index].tarUniqueCode = editInfo?.dateTour?.tarUniqueCode;
                aux[index].idSellingType = editInfo?.dateTour?.idSellingType;
                aux[index].auxTarif = editInfo?.dateTour?.auxTarif;
            }
            //aux[index].data = dateTour.data;
            //aux[index].idSellingType = objCalendar.idSellingType;
            aux[index].time = hours;
            aux[index].adult = price.sellingTarifAdultFinal;
            aux[index].child = price.sellingTarifChildFinal;
            aux[index].infant = price.sellingTarifInfantFinal;
            aux[index].student = price.sellingTarifStudentFinal;
            aux[index].elders = price.sellingTarifEldersFinal;
            aux[index].global = price.sellingTarifGlobalFinal;
            aux[index].maxStockNumber = price.balanceAvailableByChannel;
        } else {
            aux[index].time = hours;
            aux[index].maxStockNumber = price.capacity;
        }

        setIsOpen(false);
        if (edit !== true) {
            setPeopleDisponible(true);
        }
        changePriceDateTour(aux, hours);
    }

    useEffect(() => {
        let aux: any = [];
        let idChannel: number = 1;

        if (edit !== true) {
            setSelect(null);
            setPeopleDisponible(false);
        }

        if(isIntegration === true) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } else {
            setLoading(true);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listHours(info:any) {
            try {
                const { data } = await api.post(`/Products/GetTicketAndTourHoursStockAsync`,
                    {
                        "page": 1,
                        "rowsPerPage": 10,
                        "dateStart": edit !== true ? info.selectedDate.split("T")[0] : editInfo.dateTour.data,
                        "tarUniqueCode": edit !== true ? info.tarUniqueCode : editInfo.dateTour.tarUniqueCode,
                        "prodModUniqueCode": edit !== true ? info.prodModUniqueCode : editInfo.dateTour.prodModUniqueCode,
                        "daysUniqueTarCode": edit !== true ? info.daysUniqueTarCode : editInfo.dateTour.daysUniqueTarCode,
                        "idCanal": idChannel
                    }, config);
                if (data.status !== 400) {
                    aux.push(...data.data);
                    setLoading(false);
                    setHours(aux);
                } else {

                }
            } catch (error: any) {
                /* if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                } */
            }
        }
        
        const configC = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };

        async function getIdChannel() {
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/bilheteria`, configC);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;

                    if (isIntegration === false) {
                        if(edit !== true) {
                            for (let i = 0; i < dateTour[index]?.auxTarif?.length; i++) {
                                listHours(dateTour[index]?.auxTarif[i]);
                            }
                        } else {
                            for (let i = 0; i < editInfo?.dateTour?.auxTarif?.length; i++) {
                                listHours(editInfo?.dateTour?.auxTarif[i]);
                            }
                        }
                    }
                }
            } catch (error) { }
        }

        getIdChannel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTour[index]?.data, dateTour[index]?.auxTarif, editInfo?.dateTour?.auxTarif]);

    useEffect(() => {
        if (edit === true) {
            const editPrice: any = {
                sellingTarifAdultFinal: editInfo.dateTour.adult,
                sellingTarifChildFinal: editInfo.dateTour.child,
                sellingTarifInfantFinal: editInfo.dateTour.infant,
                sellingTarifStudentFinal: editInfo.dateTour.student,
                sellingTarifEldersFinal: editInfo.dateTour.elders,
                sellingTarifGlobalFinal: editInfo.dateTour.global,
                balanceAvailableByChannel: editInfo.dateTour.maxStockNumber,
            }

            handleSelectHours(editInfo?.dateTour?.time, editPrice)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isOpen === true) {
        return (
            <>
                <div id="wrapper" ref={wrapperRef}>
                    <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                        <FontAwesomeIcon
                            icon={["fal", "clock"]}
                            className="size-icon-hours mr-2"
                        />
                        <span style={{ marginLeft: "32px", fontSize: "18px" }}>
                            {select === null ? "Escolha o horário" : select.split(":")[0] + ":" + select.split(":")[1]}
                        </span>
                    </div>

                    {
                        isIntegration === false
                            ?
                            <div className="panel-dropdown-content panel-hours right active">
                                <Row className="mx-0">
                                    {hours?.map((x: any, index: any) => {
                                        if (x.balanceAvailableByChannel <= 5) {
                                            return (
                                                <>
                                                    {
                                                        index === 0
                                                            ?
                                                            <h6 className="hours-title">
                                                                Últimas Vagas
                                                            </h6>
                                                            :
                                                            ""
                                                    }
                                                    <Col
                                                        md={3}
                                                        key={index}
                                                        /* value={x.startTime} */
                                                        data-capacity={x.balanceAvailableByChannel}
                                                        className="p-1 mb-2"
                                                    >
                                                        <div className="hours-options" onClick={() => handleSelectHours(x.hora, x)}>
                                                            <p>{`${x.hora.split(":")[0]}:${x.hora.split(":")[1]}`}</p>
                                                            {
                                                                dateTour[indexChange]?.idSellingType === 1
                                                                    ?
                                                                    <small>R$ {x?.sellingTarifGlobalFinal.toFixed(2).replace(".", ",")}</small>
                                                                    :
                                                                    <small>R$ {x?.sellingTarifAdultFinal.toFixed(2).replace(".", ",")}</small>
                                                            }
                                                            <span>{`${x.balanceAvailableByChannel} vagas`}</span>
                                                        </div>
                                                    </Col>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <></>
                                            )
                                        }
                                    })}
                                </Row>
                                <h6 className="hours-title">
                                    {hours?.length > 0 ? "Horários Disponíveis" : "Não há horários disponíveis"}
                                </h6>
                                <Row className="mx-0">
                                    {hours?.map((x: any, index: any) => {
                                        if (x.balanceAvailableByChannel > 5) {
                                            return (
                                                <>
                                                    <Col
                                                        md={3}
                                                        key={index}
                                                        /* value={x.startTime} */
                                                        data-capacity={x.balanceAvailableByChannel}
                                                        className="p-1"
                                                    >
                                                        <div className="hours-options" onClick={() => handleSelectHours(x.hora, x)}>
                                                            <p>{`${x.hora.split(":")[0]}:${x.hora.split(":")[1]}`}</p>
                                                            {
                                                                dateTour[indexChange]?.idSellingType === 1
                                                                    ?
                                                                    <small>R$ {x?.sellingTarifGlobalFinal.toFixed(2).replace(".", ",")}</small>
                                                                    :
                                                                    <small>R$ {x?.sellingTarifAdultFinal.toFixed(2).replace(".", ",")}</small>
                                                            }
                                                        </div>
                                                    </Col>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <></>
                                            )
                                        }
                                    })}
                                </Row>
                                {/* <p id="alertNumber"></p> */}
                            </div>
                            :
                            <div className="panel-dropdown-content panel-hours right active">
                                <Row className="mx-0">
                                    {integrationHours?.map((x: any, index: any) => {
                                        if (x.capacity <= 5) {
                                            return (
                                                <>
                                                    {
                                                        index === 0
                                                            ?
                                                            <h6 className="hours-title">
                                                                Últimas Vagas
                                                            </h6>
                                                            :
                                                            ""
                                                    }
                                                    <Col
                                                        md={3}
                                                        key={index}
                                                        data-capacity={x.capacity}
                                                        className="p-1 mb-2"
                                                    >
                                                        <div className="hours-options" onClick={() => handleSelectHours(x.startTime, x)}>
                                                            <p>{`${x.startTime.split(":")[0]}:${x.startTime.split(":")[1]}`}</p>
                                                            <span>{`${x.capacity} vagas`}</span>
                                                        </div>
                                                    </Col>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <></>
                                            )
                                        }
                                    })}
                                </Row>
                                <h6 className="hours-title">
                                    {integrationHours?.length > 0 ? "Horários Disponíveis" : "Não há horários disponíveis"}
                                </h6>
                                <Row className="mx-0">
                                    {integrationHours?.map((x: any, index: any) => {
                                        if (x.capacity > 5) {
                                            return (
                                                <>
                                                    <Col
                                                        md={3}
                                                        key={index}
                                                        data-capacity={x.capacity}
                                                        className="p-1"
                                                    >
                                                        <div className="hours-options" onClick={() => handleSelectHours(x.startTime, x)}>
                                                            <p>{`${x.startTime.split(":")[0]}:${x.startTime.split(":")[1]}`}</p>
                                                            <small>{x?.capacity} vagas</small>
                                                        </div>
                                                    </Col>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <></>
                                            )
                                        }
                                    })}
                                </Row>
                            </div>
                    }
                </div>
            </>
        )
    } else {
        if (loading !== true) {
            return (
                <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                    <FontAwesomeIcon
                        icon={["fal", "clock"]}
                        className="size-icon-hours mr-2"
                    />
                    <span style={{ marginLeft: "32px", fontSize: "18px" }}>{select === null ? "Escolha o horário" : select.split(":")[0] + ":" + select.split(":")[1]}</span>
                    <span
                        className="qtyTotal"
                        data-min="2"
                        data-max="19"
                    >
                        {/* {dateTour[actual]?.idSellingType === 2
                            ? numberTotal
                            : dateTour[actual]?.idSellingType === 1
                                ? numberTotalPeople
                                : 0} */}
                    </span>
                </div>
            );
        } else {
            return (
                <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer", color: "#666", position: "relative" }} onClick={() => handleClickHours()}>
                    <div className="load" style={{ position: "absolute" }}></div>
                </div>
            );
        }
    }
}

export default DropdownHours;