import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Badge, Col, Dropdown, ModalBody, Row } from "react-bootstrap";

/* import iconMachine from '../../../../../../assets/icons/credit_card_machine_dataphone.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */

import './ModalDetails.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../services/api";
import { parseCookies } from "nookies";

export interface propDetails {
    modalDetails: any;
    setModalDetails: any;
    details: any;
    Print2ndWay: any;
    setInfosMaquina: any;
    setLog: any;
    setModalCancel: any;
    setModalContent: any;
    setManagement: any;
    setReturnValue: any;
    tickets?: any;
    /* cancelReservation: any; */
}

const ModalDetails: React.FC<propDetails> = ({
    modalDetails, setModalDetails, details, Print2ndWay, setInfosMaquina, setLog, setModalCancel, setModalContent, setManagement, setReturnValue/* , cancelReservation */
}) => {
    const [itemCancel, setItemCancel] = useState<any>();

    const getDetails = async () => {
        const cookies = parseCookies();
        const token = cookies.authSupervisor;
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
        };

        try {
            const res = await api.get(`/NewReservations/GetNewReservationsByIdBilheteriaAsync/${details?.idItem}`, config);
            if (res.status !== 400) {
                setItemCancel(res.data.data);
            }
        } catch (error: any) {
            console.log(error.response)
        }
    };

    useEffect(() => {
        if (modalDetails === true) {
            getDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalDetails]);

    const cancelAccessCode = async (access: any, value: any) => {
        setModalDetails(false);
        setModalCancel(true);
        setInfosMaquina("Processando...");
        setModalContent('loading');

        //cancelReservation(details, 1)

        const cookies = parseCookies();
        const token = cookies.authSupervisor;
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post(`/Checkout/EditEnviaCheckoutBilheteria`,
                {
                    "firstName": itemCancel?.nome,
                    "lastName": itemCancel?.sobrenome,
                    "phone": itemCancel?.telefone,
                    "email": itemCancel?.email,
                    "idTarif": itemCancel?.idTarifario,
                    "internalNotes": itemCancel?.internalNotesItem,
                    "IdNewItem": itemCancel?.idItem,
                    "date": details?.dataVisita,
                    "idBooking": itemCancel?.idBookings,
                    "BookingLoc": itemCancel?.bookingLoc,
                    "Foreign": 2,
                    "Language": 1,
                    "accessCodeListToRemove": [
                        access
                    ]
                }, config);
            if (res.status !== 400) {
                if (res.data.data.log === 0 || res.data.data.log === '0') {
                    setInfosMaquina(res.data.data.texto);
                    setLog(res.data.data.log);
                    setReturnValue(value);
                    setModalContent('sucesso');
                    setManagement("aprovado");
                } else {
                    setInfosMaquina(res.data.data.texto);
                    setLog(res.data.data.log);
                    setModalContent('error');
                    setManagement("error");
                }
            }
        } catch (error: any) {
            console.log(error.response)
        }
    };

    /*     useEffect(() => {
            //setAccessBtnLoad(true);
            const getAccessCode = async () => {
                try {
                    const { data } = await api.get(`/NewReservations/UpdateStatusSkidataReservationsByIdAsync/${item.idItem}`);
    
                    if (data.status !== 400) {
                        //setInfo(data.data);
                        //setAccessBtnLoad(false);
                        //setAccessBtnError(false);
                    }
                } catch (error: any) {
                    //setAccessBtnLoad(false);
                    //setAllowAccessCodeComponent(false);
                    //setAccessBtnError(true);
                }
            }
            getAccessCode();
    
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []) */

    //console.log(tickets)

    function CreateObj(arr: any) {
        Print2ndWay(details);
        setModalDetails(false);
    }

    return (
        <div>
            <Modal
                className={"modal-custom modalAuth modal-details"}
                show={modalDetails}
                onHide={
                    () => setModalDetails(false)
                }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="details-img p-4" style={{ backgroundImage: details?.productType === 4 ? `url(${process.env.REACT_APP_SERVER_URL_IMG}/img_tickets/products/${details?.productImage})` : `url(${process.env.REACT_APP_SERVER_URL_IMG}/images-products/${details?.productImage})` }} closeButton>
                    <div className="d-flex justify-content-between flex-column h-100" style={{ zIndex: 9 }}>
                        <div className={details?.status === 1 ? "bg-ativo" : "bg-inativo"}>
                            {details?.status === 1 ? `Ativo` : `Cancelado`}
                        </div>
                        <div>
                            <h5 className="mb-0" style={{ color: '#FFF' }}>{details?.nameBR}</h5>
                            <h6 className="mb-0" style={{ color: '#FFF' }}>{details?.modalityBR}</h6>
                        </div>
                    </div>
                </Modal.Header>
                <ModalBody>
                    <div className="details-content">
                        <h5 className="mb-4">Informações da Compra</h5>
                        <Row className="mb-3">
                            <Col md={6}>
                                <h6>Código da Reserva</h6>
                                <span>{details?.bookingLoc}</span>
                            </Col>
                            <Col md={6}>
                                {
                                    details?.metodoPagamento === '1' || details?.metodoPagamento === '47'
                                        ?
                                        <>
                                            <h6>NSU</h6>
                                            <span>{details?.nsuHost}</span>
                                        </>
                                        :
                                        ""
                                }
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <h6>Data da Compra</h6>
                                <span>{details?.dataCompra.split("-").reverse().join("/")}</span>
                            </Col>
                            <Col md={6}>
                                <h6>Data da Visita</h6>
                                <span>{details?.dataVisita.split("-").reverse().join("/")}</span>
                            </Col>

                            {
                                details?.dataCancelamento !== '0001-01-01T00:00:00'
                                    ?
                                    <Col md={6} className="mt-3">
                                        <h6 className="text-danger">Cancelamento</h6>
                                        <span>{details?.dataCancelamento.split("T")[0].split("-").reverse().join("/")} - {details?.dataCancelamento.split("T")[1]}</span>
                                    </Col>
                                    :
                                    <></>
                            }

                        </Row>
                        <Row className="mb-3">
                            <Col md={6}>
                                <h6>Ingressos</h6>
                                <p className="details-people mb-0">
                                    {/* {details?.adult + details?.child + details?.elders + details?.infant + details?.student} */}
                                    {details?.adult !== 0 ? <><span>{details?.adult} Adultos</span><span> - </span></> : ""}
                                    {details?.child !== 0 ? <><span>{details?.child} Crianças</span><span> - </span></> : ""}
                                    {details?.elders !== 0 ? <><span>{details?.elders} Idosos</span><span> - </span></> : ""}
                                    {details?.infant !== 0 ? <><span>{details?.infant} Promo Brasil</span><span> - </span></> : ""}
                                    {details?.student !== 0 ? <><span>{details?.student} Outras Meias</span><span> - </span></> : ""}
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="details-content">
                        <h5 className="mb-4">Informações do Pagamento</h5>
                        <Row className="mb-3">
                            <Col md={6}>
                                <h6>Valor</h6>
                                {
                                    process.env.REACT_APP_ENVIRONMENT === "CARACOL"
                                        ?
                                        details?.possuiPagamento === true
                                            ?
                                            <span>{details?.amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                            :
                                            <span>R$ 0,00</span>
                                        :
                                        <span>{details?.amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                }
                            </Col>
                            {
                                process.env.REACT_APP_ENVIRONMENT === "CARACOL" && details?.metodoPagamento === '88'
                                    ?
                                    ""
                                    :
                                    <Col md={6}>
                                        <h6>Forma de Pagamento</h6>
                                        <span>
                                            {
                                                details?.metodoPagamento === '1'
                                                    ?
                                                    `Cartão de Crédito`
                                                    :
                                                    details?.metodoPagamento === '47'
                                                        ?
                                                        `Cartão de Débito`
                                                        :
                                                        details?.metodoPagamento === '2'
                                                            ?
                                                            `Dinheiro`
                                                            :
                                                            `PIX`
                                            }
                                        </span>
                                    </Col>
                            }
                        </Row>
                    </div>
                    <div className="details-content">
                        <h5 className="mb-4">Códigos de Acesso</h5>
                        <Row className="mb-3">
                            {
                                itemCancel?.qrCodeAccessReservationDTO.map((item: any, index: any) => {
                                    //console.log(item);

                                    return (
                                        <Col md="12" key={index}>
                                            <div className="details-access">
                                                <Row className="justify-content-between">
                                                    <Col md={1} style={{ margin: "auto 0" }}>
                                                        <FontAwesomeIcon
                                                            icon={["fal", "ticket-alt"]}
                                                            size="2x"
                                                            style={{ fontSize: "2rem", verticalAlign: "middle" }}
                                                        />
                                                    </Col>
                                                    <Col md={9}>
                                                        <div>
                                                            {/* <p className="mb-0">Código</p> */}
                                                            <span>
                                                                <h6 className='w-auto'>
                                                                    <span style={{ fontSize: "1rem" }}>Código</span>
                                                                    <small style={{ marginLeft: "0.5rem" }} >
                                                                        <Badge className=" ml-2" bg={item.activationTimestamp === null && item.status === 1 ? 'info' : item.activationTimestamp === null && item.status === 2 ? 'danger' : 'success'}>{item.activationTimestamp === null && item.status === 1 ? 'Em aberto' : item.activationTimestamp === null && item.status === 2 ? 'Cancelado' : 'Usado'}</Badge>
                                                                    </small>
                                                                    {item.activationTimestamp !== null
                                                                        ?
                                                                        <>
                                                                            <small style={{ marginLeft: "0.5rem" }} >
                                                                                <Badge className=" ml-2" bg={item.activationTimestamp === null ? 'info' : item.partial === false ? 'blue' : 'warning'}>Skidata</Badge>
                                                                            </small>
                                                                            {item.partial === false
                                                                                ?
                                                                                <>
                                                                                    <small style={{ marginLeft: "0.5rem" }} >
                                                                                        <Badge className=" ml-2" bg={'blue'}>SIG</Badge>
                                                                                    </small>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                    }
                                                                </h6>
                                                                <small className="response-modal" style={{ fontWeight: 400 }}>{item.accessCode}</small>
                                                                {
                                                                    item.activationTimestamp === null
                                                                        ?
                                                                        <>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <small className="ml-3 response-modal">Data da visita:</small> <small className="response-modal" style={{ fontWeight: 400 }}>{/* {convertDate(unit.activationTimestamp)} - {convertDate(item.activationTimestamp, true)} */}</small>
                                                                        </>
                                                                }
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col md={1} style={{ margin: "auto 0" }}>
                                                        <div className="d-flex justify-content-center">
                                                            {
                                                                item?.status !== 2
                                                                    ?
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="light ">
                                                                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} style={{ fontSize: "1.5rem" }} />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item
                                                                                eventKey="3"
                                                                                disabled={false}
                                                                                onClick={() =>
                                                                                    CreateObj(item)
                                                                                }
                                                                            >
                                                                                <FontAwesomeIcon style={{ marginRight: '5px' }} icon={['fal', 'print']} />
                                                                                Reimprimir Ticket
                                                                            </Dropdown.Item>
                                                                            {
                                                                                (details?.metodoPagamento !== "1" && details?.metodoPagamento !== "47") && details?.status !== 2 && details?.qrCodeAccessReservationDTO?.length > 1 && details?.metodoPagamento !== "96"
                                                                                    ?
                                                                                    <Dropdown.Item
                                                                                        eventKey="4"
                                                                                        disabled={false}
                                                                                        className="text-danger remove"
                                                                                        onClick={() =>
                                                                                            cancelAccessCode(item.accessCode, details?.accessCodes[index]?.valorTicket)
                                                                                        }
                                                                                    >
                                                                                        <FontAwesomeIcon style={{ marginRight: '5px' }} icon={['fal', 'trash-alt']} />
                                                                                        Cancelar Item
                                                                                    </Dropdown.Item>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalDetails;
