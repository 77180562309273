import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import IconPIX from '../../../../assets/img/pix-svgrepo-com.svg';

import './SelectPayment.scss';

export interface checkout {
    action: any,
    setModalShow: any
};

//COMPONENTS

//ICONS

const SelectPayment: React.FC<checkout> = React.memo(({ action, setModalShow
}: checkout) => {
    const [typePayment, setTypePayment] = useState<string>('');

    const [isFree, setIsFree] = useState<any>(null);

    const cartPDV = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === "CARACOL") {
            let isencaoAndCortesia: any = localStorage.getItem("IsencaoCortesia");
            let type: any = JSON.parse(isencaoAndCortesia);
            let aux = [];

            for (let i = 0; i < cartPDV.dados.length; i++) {
                aux.push(type?.includes(cartPDV?.dados[i].productCode));
            }

            setIsFree(aux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartPDV.dados.length])

    function Transaction() {
        if (process.env.REACT_APP_ENVIRONMENT === "CARACOL") {
            if (isFree?.includes(false)) {
                action(typePayment);
            } else {
                action('free');
            }
        } else {
            action(typePayment);
        }
    }

    if (process.env.REACT_APP_ENVIRONMENT === "CARACOL") {
        if (isFree?.includes(false)) {
            return (
                <>
                    <div className="content-payment">
                        <Row>
                            <Col xs={6} className="mb-3">
                                <button className={typePayment === 'cash' ? "card-methods active" : "card-methods"} onClick={() => setTypePayment('cash')}>
                                    <div>
                                        <FontAwesomeIcon
                                            icon={["far", "money-bill-wave"]}
                                            size="4x"
                                            style={{ color: '#86C03F' }}
                                        />
                                    </div>
                                    <div>
                                        <h5>Dinheiro</h5>
                                    </div>
                                </button>
                            </Col>
                            <Col xs={6} className="mb-3">
                                <button className={typePayment === 'credit' ? "card-methods active" : "card-methods"} onClick={() => setTypePayment('credit')}>
                                    <div>
                                        <FontAwesomeIcon
                                            icon={["far", "credit-card-front"]}
                                            size="4x"
                                            style={{ color: '#6CC9DD' }}
                                        />
                                    </div>
                                    <div>
                                        <h5>Cartão de Crédito</h5>
                                    </div>
                                </button>
                            </Col>
                            <Col xs={6} className="mb-3">
                                <button className={typePayment === 'debit' ? "card-methods active" : "card-methods"} onClick={() => setTypePayment('debit')}>
                                    <div>
                                        <FontAwesomeIcon
                                            icon={["far", "credit-card"]}
                                            size="4x"
                                            style={{ color: '#FF6600' }}
                                        />
                                    </div>
                                    <div>
                                        <h5>Cartão de Débito</h5>
                                    </div>
                                </button>
                            </Col>
                            <Col xs={6} className="mb-3">
                                <button className={typePayment === 'pix' ? "card-methods active" : "card-methods"} onClick={() => setTypePayment('pix')}>
                                    <div>
                                        <FontAwesomeIcon
                                            icon={["far", "credit-card"]}
                                            size="4x"
                                            style={{ color: 'red' }}
                                        />
                                    </div>
                                    <div>
                                        <h5>PIX</h5>
                                    </div>
                                </button>
                            </Col>
                        </Row>
                    </div>
                    <div className="d-flex justify-content-end align-items-end mb-3 px-0">
                        <Button
                            variant="outline-primary"
                            className="btn-default mr-2"
                            type="button"
                            onClick={() => setModalShow(false)}
                        >
                            Cancelar
                        </Button>
                        <Button className="btn-default text-light" type="button" onClick={Transaction}>
                            Continuar Pagamento
                        </Button>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div>

                        <FontAwesomeIcon
                            icon={["fal", "question-circle"]}
                            size="5x"
                            className='text-primary'
                        />
                    </div>

                    <small>{`Tem certeza que deseja finalizar a compra?`}</small>
                    <div className="d-flex justify-content-center pt-3">
                        <button
                            onClick={() => setModalShow(false)}
                            className="btn btn-outline-primary mx-2 w-25"
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn btn-primary mx-2 w-25"
                            style={{ color: '#FFF' }}
                            onClick={Transaction}
                        >
                            Confirmar
                        </button>
                    </div>
                </>
            )
        }
    } else {
        return (
            <>
                <div className="content-payment">
                    <Row>
                        <Col xs={6} className="mb-3">
                            <button className={typePayment === 'cash' ? "card-methods active" : "card-methods"} onClick={() => setTypePayment('cash')}>
                                <div>
                                    <FontAwesomeIcon
                                        icon={["far", "money-bill-wave"]}
                                        size="4x"
                                        style={{ color: '#86C03F' }}
                                    />
                                </div>
                                <div>
                                    <h5>Dinheiro</h5>
                                </div>
                            </button>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <button className={typePayment === 'credit' ? "card-methods active" : "card-methods"} onClick={() => setTypePayment('credit')}>
                                <div>
                                    <FontAwesomeIcon
                                        icon={["far", "credit-card-front"]}
                                        size="4x"
                                        style={{ color: '#6CC9DD' }}
                                    />
                                </div>
                                <div>
                                    <h5>Cartão de Crédito</h5>
                                </div>
                            </button>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <button className={typePayment === 'debit' ? "card-methods active" : "card-methods"} onClick={() => setTypePayment('debit')}>
                                <div>
                                    <FontAwesomeIcon
                                        icon={["far", "credit-card"]}
                                        size="4x"
                                        style={{ color: '#FF6600' }}
                                    />
                                </div>
                                <div>
                                    <h5>Cartão de Débito</h5>
                                </div>
                            </button>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <button className={typePayment === 'pix' ? "card-methods active" : "card-methods"} onClick={() => setTypePayment('pix')}>
                                <div>
                                    <img src={IconPIX} alt="" style={{ width: "64px" }}/>
                                </div>
                                <div>
                                    <h5>PIX</h5>
                                </div>
                            </button>
                        </Col>
                    </Row>
                </div>

                <div className="d-flex justify-content-end align-items-end mb-3 px-0">
                    <Button
                        variant="outline-primary"
                        className="btn-default mr-2"
                        type="button"
                        onClick={() => setModalShow(false)}
                    >
                        Cancelar
                    </Button>
                    <Button className="btn-default text-light" type="button" onClick={Transaction}>
                        Continuar Pagamento
                    </Button>
                </div>
            </>
        )
    }
});

export default SelectPayment;
