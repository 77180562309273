import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";



//COMPONENTS

//ICONS


const InfoApproved = () => {

  return (
    <>
      <div className="d-flex justify-content-evenly align-items-center flex-column text-center modal-checkout gap-2">
        <div>
          <FontAwesomeIcon
            icon={["fal", "check-circle"]}
            size="1x"
            style={{ fontSize: "6rem" }}
            className="color-success"
          />
          <h4 className="my-4 text-success">Compra realizada com sucesso!</h4>
          <p className="m-auto" style={{ fontWeight: 600, width: "80%", textAlign: "center" }}>Por favor, aguarde uns segundos que o ingresso será impresso.</p>
        </div>
      </div>
    </>
  )

}

export default InfoApproved;
