import React, { useState, Key, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { version } from "../../../package.json";

import './Menu.scss';
import objectMenu from './locales/menu.json';

// import logo from `../../assets/img/logo-c2tours.svg`;

import MenuPrimary from './MenuPrimary/MenuPrimary';

import { Modal } from 'react-bootstrap';
import { parseCookies } from 'nookies';

export interface propMenu {
};

const Menu: React.FC<propMenu> = () => {

    const [open, setOpen] = useState(false);
    const [menuName, setMenuName] = useState('');

    const [show, setShow] = useState<any>(null);


    /* Detecta clique fora da div#wrapper para fechar o menu */
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setMenuName('.');
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* //Detecta clique fora da div#wrapper para fechar o menu\\ */

    function handleClosed() {
        const cookies = parseCookies();
        const isOpen = cookies.isCashierOpened;

        console.log(isOpen)

        setShow(null);

        if(isOpen === 'true') {
            window.location.href="/fechamento";
        } else {
            window.location.href="/";
        }
    }

    return (
        <div id="wrapper"/*  ref={wrapperRef} */>
            {/* <ul className="navbar-nav bg-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar" style={{ zIndex: 11 }}> */}
            <div className="navbar-nav sidebar sidebar-dark toggled" id="accordionSidebar" style={{ zIndex: 11 }}>
                <ul className='ul-menu'>

                    <li className='mb-3'>
                        <Link className="sidebar-brand d-flex align-items-center justify-content-center py-6 border-bottom-primary" to="/tickets">
                            <div className="sidebar-brand-icon">
                                <img src={process.env.REACT_APP_SERVER_LOGO_SHORT} alt="Logo" />
                            </div>
                            <div className="sidebar-brand-text d-none">C2tours<span className="font-weight-light">SIG</span></div>
                        </Link>
                    </li>

                    {objectMenu.menu.default.length > 0 ? objectMenu.menu.default.map((menu: any, index: Key) => (
                        <div key={index}>
                            <li className="nav-item mt-0" title={menu.name}>
                                
                                {menu.href === "#"
                                    ?
                                    <div style={{ cursor: "pointer" }} className="nav-link select" id="collapseDashboard" onClick={() => { setOpen(!open); setMenuName(menu.name) }} aria-controls={menu.id} aria-expanded={open}>
                                        <FontAwesomeIcon icon={['fal', menu.icon]} />
                                        <span className="menu-name">{menu.name}</span>
                                    </div>
                                    :
                                    <Link className={menu.href === window.location.pathname ? "nav-link select active" : "nav-link select"} to={menu.href} id="collapseDashboard" onClick={() => { setOpen(!open); setMenuName(menu.name) }} aria-controls={menu.id} aria-expanded={open}>
                                        <FontAwesomeIcon icon={['fal', menu.icon]} />
                                        <span className="menu-name">{menu.name}</span>
                                    </Link>
                                }

                                {menu.submenu.length > 0 ?

                                    <MenuPrimary menu={menu} menuName={menuName} setMenuName={setMenuName} />

                                    : ''}

                            </li>
                        </div>
                    )) : ''}

                    <li className="nav-item logout-item">
                        <div style={{ cursor: "pointer" }} className="nav-link" id="collapseDashboard" onClick={() => setShow(true)}>
                            <FontAwesomeIcon icon={['fal', 'sign-out-alt']} />
                            <span className="menu-name">Sair</span>
                        </div>
                        <div className='bg-logout-footer'>
                            <img src={process.env.REACT_APP_SERVER_LOGO}
                                alt="Destitech"
                                style={{ width: "70px" }}
                            />
                            <br />
                            <small style={{ fontSize: ".55rem" }}>Version: <strong>{version}</strong></small>
                        </div>
                    </li>
                </ul>
            </div>

            <Modal ///Confirmação de saída da aplicação
                className="modalAuth modal-validation"
                show={show !== null}
                onHide={() => { setShow(null) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <>
                    <Modal.Body
                        className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
                    >
                        <div>

                            <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className='text-primary'
                            />
                        </div>

                        <small>{`Tem certeza que deseja sair?`}</small>
                        <div className="d-flex justify-content-center pt-3">
                            <button
                                onClick={() => {
                                    setShow(null);
                                }}
                                className="btn btn-outline-primary mx-2 w-25"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={() => {
                                    handleClosed()
                                }}
                                className="btn btn-primary mx-2 w-25"
                                style={{ color: '#FFF' }}
                            >
                                Confirmar
                            </button>
                        </div>
                    </Modal.Body>
                </>
            </Modal>
        </div>

    );
}

export default Menu;