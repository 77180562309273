import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import GetInstallmentsForSource from "../../../../components/Installments/GetInstallmentsForSource";
import { useAppSelector } from "../../../../store/hooks";

export interface checkout {
  action: any,
  installMents: any,
  setManagement: any,
  setModalShow: any
};

//COMPONENTS

//ICONS

const PaymentCreditCart: React.FC<checkout> = React.memo(({ action, installMents, setManagement, setModalShow
}: checkout) => {
  const [select, setSelect] = useState<any>(1);
  const [installments, setInstallments] = useState<any>(1);

  const cartPDV: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  useEffect(() => {
    let aux: any = [];

    const installments = 0;

    if (cartPDV.totalCart > 200) {
      for (let i = 0; i <= installments; i++) {
        aux.push(
          {
            "installments": i + 1,
            "installmentAmount": cartPDV.totalCart / (i + 1)
          }
        )
      }
    } else {
      aux = [{
        "installments": 1,
        "installmentAmount": cartPDV.totalCart / 1
      }]
    }

    setInstallments(aux)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="mt-4">
        <p className="mb-4">Escolha uma opção de parcelamento:</p>

        <div>
          {
            process.env.REACT_APP_ENVIRONMENT === "CARACOL"
              ?
              <GetInstallmentsForSource
                setSelect={setSelect}
                select={select}
              />
              :
              <>
                {
                  installments?.length > 0 ? installments?.map((dataInstall: any, index: any) => {
                    return (
                      <div key={index}>
                        <Col xs={12}>
                          <div className="card-methods-installments mb-4" /* onClick={() => action(dataInstall.installments)} */>
                            <div className="d-flex align-items-center gap-2">
                              <Form.Check type="radio" id={'parsePayments' + (index + 1)} name="customPayment" value={(index + 1)} onChange={(e: any) => setSelect(e.target.value)} defaultChecked={true} />
                              <h5>{dataInstall.installments}x de R$ {(dataInstall.installmentAmount).toFixed(2).replace(".", ",")} <small> sem juros</small></h5>
                            </div>
                            <div>
                              Total: R$ {(dataInstall.installments * dataInstall.installmentAmount.toFixed(2)).toFixed(2).replace(".", ",")}
                            </div>
                          </div>
                        </Col>
                      </div>
                    )
                  }) : ""
                }
              </>
          }
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-end mb-3 px-0">
        <Button
          variant="outline-primary"
          className="btn-default mr-2"
          type="button"
          onClick={() => setModalShow(false)}
        >
          Cancelar
        </Button>
        <Button className="btn-default text-light" type="button" onClick={() => action(Number(select))}>
          Continuar Pagamento
        </Button>
      </div>
    </>
  )
});

export default PaymentCreditCart;
