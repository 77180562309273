import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { destroyCookie, parseCookies } from 'nookies'
import AuthLogin from "./components/AuthLogin/AuthLogin";
//import bg01 from "../../assets/img/backgrounds/Bg-Login.png";
import './Login.scss';

function Login() {
    const { t } = useTranslation();
    const cookies = parseCookies();

    useEffect(() => {
        destroyCookie(null, 'authSupervisor');
        destroyCookie(null, 'authFechamento');
        destroyCookie(null, 'Operator');

        if (cookies.isCashierOpened === undefined) {
            destroyCookie(null, 'authCookie');
            destroyCookie(null, 'isCashierOpened');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* destroyCookie(null, 'authCookie');
    destroyCookie(null, 'authSupervisor');
    destroyCookie(null, 'authFechamento');
    destroyCookie(null, 'isCashierOpened'); */

    return (
        <Container fluid className="container-login p-0">
            <Row className="h-100 w-100 m-0 ">
                <Col
                    sm={12}
                    className="bg-login-image p-0"
                    id="random"
                    style={{ backgroundImage: `url(${process.env.REACT_APP_LOGIN_BANNER})` }}
                >
                    <Row className="h-100 w-100 m-0 content-split justify-content-center">
                        <Col sm={12} md={12} className="bg-white valign left p-0 my-auto rounded">
                            <div className="login-card inner d-flex flex-column justify-content-between h-100">
                                <div className=" d-flex flex-column justify-content-center align-items-center">
                                    <img src={process.env.REACT_APP_SERVER_LOGO_PRIMARY} alt="PDV Bondinho" className='w-50' />
                                </div>
                                <div>
                                    <div className="login-title mb-4">
                                        <h1 className='d-flex justify-content-start'>Login</h1>
                                    </div>
                                    <AuthLogin />
                                </div>
                                <div className="text-center" style={{ fontSize: "10px" }}>
                                    <small>{t("login.development")}</small>
                                    <div className="login-brand d-flex align-items-center justify-content-center mt-1">
                                        <img
                                            src={process.env.REACT_APP_SERVER_LOGO}
                                            alt="Destitech"
                                            className="float-left"
                                            style={{ width: "100px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;