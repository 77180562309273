import React from 'react';
/* import React, { useState, Key } from 'react'; */
/* import { Link } from 'react-router-dom';
 */import './Dashboard.scss'

//COMPONENTS
import Menu from '../../components/Menu/Menu';
import Header from '../../components/Header/Header';
//import CashierOpening from '../../components/CashierOpening/CashierOpening';
//import LoginSupervisor from '../../components/LoginSupervisor/LoginSupervisor';


//ICONS
/* import iconGlobe from '../../assets/icons/fa-globe-americas.svg'; */

function Dashboard() {
    //const cashierOpened: any = localStorage.getItem('cashier');
    
    //const [allowLoginSupervisor, setAllowLoginSupervisor] = useState<boolean>(cashierOpened === null ? false : true);

    return (
        <>
            <div className="d-flex">
                <Menu />

                <div style={{
                    width: "100%"
                }}>
                    <Header pageTitle={'Bilhetes ' + process.env.REACT_APP_CLIENT_NAME} />

                </div>
            </div>

            {/* {cashierOpened === null && <CashierOpening setAllowLoginSupervisor={setAllowLoginSupervisor} />} */}
        </>

    );
}

export default Dashboard;