/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api";

import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";

/* import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'; */

import i18next from "i18next";

import "./TabSearchs.scss";

/* import IconActivities from "../../assets/icons/ic_outline-tour.svg";
import IconTransfer from "../../assets/icons/icon-park-outline_transfer.svg"; */
// import IconCombo from "../../assets/icons/bx_map-alt.svg";

// import iconTour from '../../assets/icons/bx_map.svg';
// import iconCalendar from '../../assets/icons/ci_calendar.svg'

import iconArrow from '../../assets/icons/arrow_1.svg';

//import SingleCalendarTime from "../../components/Calendar/SingleCalendarTime";

import SetTransfersItem from "../../infra/Transfers/SetTransfersItem";

import { useTranslation } from "react-i18next";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AutoComplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import SingleCalendarTime from "../Calendar/SingleCalendarTime";

function TabSearchs(prosp: any) {
  let idChannel: number = 1;
  
  const [tourFilterPriceRange, setTourFilterPriceRange] = useState<any>({ min: 0, max: 0 });
  const [tourFilterType, setTourFilterType] = useState<any>([]);
  const [tourFilterCategorie, setTourFilterCategorie] = useState<any>([]);
  const [tourFilterStars, setTourFilterStars] = useState<any>([]);

  const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

  const [t] = useTranslation();

  const transferC2: any = localStorage.getItem("transferC2");
  const transferItemJSON = JSON.parse(transferC2);

  const isTransfer = window.location.pathname;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tours, setTours] = useState<any>("");
  const [locations, setLocations] = useState<any>("");
  const [isOpen, setIsOpen] = useState<any>(false);
  const [searchValue, setSearchValue] = useState<any>('');
  const [valueSelect, setValueSelect] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);

  const [num, setNum] = useState<any>(1);
  const [origin, setOrigin] = useState<any>(isTransfer !== "/transfers" ? "" : transferItemJSON?.origin);
  const [destiny, setDestiny] = useState<any>(isTransfer !== "/transfers" ? "" : transferItemJSON?.destine);
  const [latOrigin, setLatOrigin] = useState<any>(isTransfer !== "/transfers" ? "" : transferItemJSON?.latOrigem);
  const [longOrigin, setLongOrigin] = useState<any>(isTransfer !== "/transfers" ? "" : transferItemJSON?.lngOrigem);
  const [latDestiny, setLatDestiny] = useState<any>(isTransfer !== "/transfers" ? "" : transferItemJSON?.latDestino);
  const [longDestiny, setLongDestiny] = useState<any>(isTransfer !== "/transfers" ? "" : transferItemJSON?.lngDestino);

  const [time, setTime] = useState<any>(isTransfer !== "/transfers" ? "" : `${transferItemJSON?.date} ${transferItemJSON?.time}`);
  const [width, setWidth] = useState<any>(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', function () {
      var newWidth = window.innerWidth;
      setWidth(newWidth)
    });
  }, [])

  /* Detecta clique fora da div#wrapper para fechar*/
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /* Search Tour */
  function getLocations(data: any) {
    var aux: any = [];

    data.forEach((element: any) => {
      if (!aux.includes(element.eventLocation)) {
        aux.push(element.eventLocation);
      }
    });

    setLocations(aux);
  }

  const searchTours = async () => {

    const response = await api.post(
      `${process.env.REACT_APP_SERVER_URL_API}/Products/SearchByChannel`,
      {
        term: searchTerm,
        channel: idChannel,
        lang: lng,
        ProductType: 0,
      }
    );

    if (response.data.data !== null) {
      setTours(response.data.data);
      getLocations(response.data.data);
      setIsOpen(true);
      // isOpen === false ? setIsOpen(true) : setIsOpen(false);
    }
  };

  const convertToSlug = (text: any) => {
    const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
    const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    const p = new RegExp(a.split('').join('|'), 'g')
    return text.toString().toLowerCase().trim()
      .replace(p, (c: any) => b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
  }

  const handleClickCity = (event: React.MouseEvent<HTMLElement>) => {
    var element = (event.target as HTMLButtonElement).dataset.city;
    var typeE = (event.target as HTMLButtonElement).dataset.busca;
    setSearchValue(element);
    const tourSelect = {
      slug: convertToSlug(element?.split(",")[0].split("-")[0]),
      type: typeE
    };

    setValueSelect(tourSelect);
    setIsOpen(false);
  };

  const handleClickTour = (event: React.MouseEvent<HTMLElement>) => {
    var element = (event.target as HTMLButtonElement).dataset.tour;
    var slug = (event.target as HTMLButtonElement).dataset.slug;
    var type = (event.target as HTMLButtonElement).dataset.type;
    var typeE = (event.target as HTMLButtonElement).dataset.busca;
    var codeE =
      (event.target as HTMLButtonElement).dataset.busca === "tour"
        ? (event.target as HTMLButtonElement).dataset.code
        : null;
    setSearchValue(element);
    const tourSelect = {
      slug: slug,
      type: typeE,
      code: codeE,
      typeSearch: type
    };
    setValueSelect(tourSelect);
    setIsOpen(false);
  };

  async function handleClickSearch(city: any) {
    prosp.setChooseTab(1);
    try {
      const res = await api.post(
        `/Products/FetchByChannel`,
        {
          "page": 0,
          "rowsPerPage": 0,
          "term": searchValue || city,
          "channel": idChannel,
          "lang": "BR",
          "ProductType": tourFilterType.length > 1 || tourFilterType.length === 0 ? 0 : tourFilterType[0],
          "categories": tourFilterCategorie,
          "stars": tourFilterStars !== null ? tourFilterStars : [],
          "priceStart": tourFilterPriceRange.min !== 0 ? tourFilterPriceRange.min : 1,
          "priceEnd": tourFilterPriceRange.max
        }
      );
      if (res.status !== 400) {
        if (res?.data?.statusCode === 204) {  ///Tratando o caso de "Nenhum resultado encontrado" na pesquisa
          //setNoContent(true);
          //setTourList(null);
        } else {
          if (res.data.data !== null) {
            prosp.setResProduct(res.data.data.rows);
            prosp.setTotalRows(res.data.data.rowsCount);
            //setTourListSize(res.data.data.rows.length);
            //setPagination(1);


            //setValue({ min: Math.floor(res.data.data.minPrice), max: Math.ceil(res.data.data.maxPrice) });
            //setNoContent(false);
          } else {
            //setTourList(null);
            //setNoContent(false);
          }
        }
      }
    } catch (error) { }
  }

  const config = {
    headers: { "ngrok-skip-browser-warning": "69420" },
};

  async function getIdChannel(info: any) {
    try {
      const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/bilheteria`, config);
      if (data.statusCode === 200) {
        idChannel = data.data.data;
        handleClickSearch(info);
      }
    } catch (error) { }
  }

  async function getIdChannel2() {
    try {
      const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/bilheteria`, config);
      if (data.statusCode === 200) {
        idChannel = data.data.data;
        searchTours();
      }
    } catch (error) { }
  }

  useEffect(() => {
    getIdChannel("Rio de Janeiro");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchValue.length >= 2 && valueSelect === null) {
      getIdChannel2();
    } else if (searchValue.length < 2) {
      setIsOpen(false)
      setValueSelect(null)
    } else {
      ///momento do click
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleFocus = () => {
    if (searchValue.length >= 2) {
      setIsOpen(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    setSearchTerm(newValue);
  };

  /* END - Search Tour */

  /* Search Transfers */
  if (localStorage.getItem("transferPDV") === null) {
    localStorage.setItem("transferPDV", '[]');
  }

  const addToTransfers = (itemOption: any) => {
    if (destiny === "" || origin === "") {
      setError(true)

    } else {
      const item = {
        date: time?.split(' ')[0],
        destine: destiny,
        latDestino: latDestiny,
        latOrigem: latOrigin,
        lngDestino: longDestiny,
        lngOrigem: longOrigin,
        numPeople: num,
        origin: origin,
        time: time?.split(' ')[1]
      }

      prosp.setSearch(item);
      prosp.setChooseTab(2);
      SetTransfersItem(item)
    }
  }

  function less() {
    if (num > 1) {
      setNum(num - 1)
    }
  }

  function more() {
    setNum(num + 1)
  }
  /* END - Search Transfers */

  if (tours !== null) {
    return (
      <>
        <Container fluid>
          <Container className="container-content px-0 mb-3">
            <div
              // className="bg-tab-options"
              className={process.env.REACT_APP_CLIENT_NAME === 'Destinow' ? 'bg-tab-options' : 'bg-tab-options outlet'}
            >
              <Row className="tab-content-options">
                <Tabs
                  defaultActiveKey="Atividades"
                >
                  <Tab
                    eventKey="Atividades"
                    title={
                      <span>
                        {width > 767 ? (
                          <img
                            src={/* IconActivities */""}
                            alt=""
                            className="mr-2"
                          />
                        ) : (
                          ""
                        )}
                        Atividades
                      </span>
                    }
                  >
                    <div className="tab-space">
                      <Row className="h-100 align-items-center px-3">
                        <form action="" className="d-md-flex align-items-center px-0">
                          <Col md={10}>
                            {/* Search Tour */}
                            <div
                              ref={wrapperRef}
                              className="bg-header search-tour px-1 px-md-3 py-1"
                              id="nav-1"
                              role="tabpanel"
                              aria-labelledby="nav-1-tab"
                            >
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text py-0 py-md-2"
                                    id="basic-addon1"
                                  >
                                    {/* <img
                                    src={iconTour}
                                    alt="pesquisar"
                                    className="py-1"
                                  /> */}
                                    {/* {IconMap} */}
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  value={searchValue}
                                  className="form-control text-left py-1 py-md-2"
                                  placeholder="Escolha a sua atividade..."
                                  data-input="search"
                                  aria-label="Destino"
                                  aria-describedby="tourDestino"
                                  autoComplete="off"
                                  id="tourDestino"
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                />
                                <div className="input-group-append">
                                  <span
                                    className="input-group-text py-0 py-md-2"
                                    id="basic-addon1"
                                  >
                                    <img
                                      src={iconArrow}
                                      alt="pesquisar"
                                      className="py-1"
                                    />
                                  </span>
                                </div>
                                <div
                                  id="search-menu"
                                  style={{
                                    position: "absolute",
                                    top: "100%",
                                    left: "0px",
                                    zIndex: 10,
                                    backgroundColor: "#fff",
                                    padding: "10px",
                                    width: "100%",
                                    color: "#000",
                                  }}
                                  className={
                                    isOpen === true ? "d-block" : "d-none"
                                  }
                                >
                                  <div id="search-dataset-allCitys">
                                    <h5
                                      className="league-name"
                                      style={{ fontSize: "16px" }}
                                    >
                                      Cidades
                                    </h5>
                                    {locations.length > 0
                                      ? locations.map((city: any, index: any) => (
                                        <p
                                          key={index}
                                          onClick={handleClickCity}
                                          style={{ cursor: "pointer" }}
                                          className="chooice tt-suggestion tt-selectable"
                                          data-busca="city"
                                          data-input="search"
                                          //data-value="{city}"
                                          data-city={city}
                                        >
                                          <span
                                            className="result-info city"
                                            style={{ fontSize: "18px" }}
                                          >
                                            {city}
                                          </span>
                                        </p>
                                      ))
                                      : ""}
                                  </div>
                                  <div id="search-dataset-allTourNames">
                                    <h5
                                      className="league-name"
                                      style={{ fontSize: "16px" }}
                                    >
                                      Passeios
                                    </h5>
                                    {tours.length > 0
                                      ? tours.map((tours: any, index: any) => (
                                        <p
                                          key={index}
                                          onClick={handleClickTour}
                                          style={{ cursor: "pointer" }}
                                          className="chooice tt-suggestion tt-selectable"
                                          data-busca="tour"
                                          data-input="search"
                                          data-code={tours.productCode}
                                          data-tour={tours.description}
                                          data-slug={tours.slug}
                                          data-type={tours.productType}
                                        >
                                          <span className="content-text">
                                            <span
                                              className="result-info"
                                              style={{ fontSize: "18px" }}
                                            >
                                              <strong>
                                                {tours.description}
                                              </strong>
                                            </span>
                                            <div className="price-search">
                                              {" "}
                                              <small>a partir de</small>:{" "}
                                              <span
                                                style={{ fontSize: "18px" }}
                                              >
                                                R${" "}
                                                {tours?.price?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                              </span>
                                            </div>
                                          </span>
                                        </p>
                                      ))
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* END - Search Tour */}
                          </Col>
                          {/* <Col md={5}>
                          <div className="bg-header search-tour px-1 px-md-3 py-1">
                            <SearchCalendar />
                          </div>
                        </Col> */}
                          <Col md={2}>
                            <div className="px-1 px-md-3 py-1">
                              <button
                                type="button"
                                data-btn="search"
                                id="button-search"
                                className="btn btn-primary btn-default"
                                onClick={(e: any) => {
                                  getIdChannel(e);
                                }}
                              >
                                Encontrar atividade
                              </button>
                            </div>
                          </Col>
                        </form>
                      </Row>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Transfers"
                    title={
                      <span>
                        {width > 767 ? (
                          <img
                            src={/* IconTransfer */ ""}
                            alt=""
                            className="mr-2"
                          />
                        ) : (
                          ""
                        )}
                        Transfers
                      </span>
                    }
                  >
                    <div className="tab-space">
                      <Row className="h-100 align-items-center px-3">
                        <form action="" className="d-md-flex align-items-center px-1">
                          <Col md={6}>
                            <Row className="mx-0 px-0">
                              <Col md={6} className="px-0 px-md-2">
                                <div className="bg-header search-tour px-1 py-1">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text py-0 py-md-2"
                                        id="basic-addon1"
                                      >
                                      </span>
                                    </div>
                                    <AutoComplete
                                      className="h-100"
                                      options={{
                                        types: ["establishment"],
                                        fields: ["name"],
                                      }}
                                      defaultValue={origin}
                                      placeholder="Selecione a origem"
                                      apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                                      onPlaceSelected={(place) => {
                                        setOrigin(place.name);
                                        setError(false);
                                        // eslint-disable-next-line no-lone-blocks
                                        {
                                          geocodeByAddress(`${place.name}`)
                                            .then((results) =>
                                              getLatLng(results[0])
                                            )
                                            .then(({ lat, lng }) => {
                                              setLatOrigin(lat);
                                              setLongOrigin(lng);
                                            });
                                        }
                                      }}
                                    />

                                    {error === true &&
                                      (origin === "" || origin === null) ? (
                                      <div className="d-flex ml-3">
                                        <small
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {t("home.tabSearch.errorOrigin")}
                                        </small>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    <div className="input-group-append">
                                      <span
                                        className="input-group-text py-0 py-md-2"
                                        id="basic-addon1"
                                      >
                                        <img
                                          src={iconArrow}
                                          alt="pesquisar"
                                          className="py-1"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={6} className="px-0 px-md-2">
                                <div className="bg-header search-tour px-1 py-1">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text py-0 py-md-2"
                                        id="basic-addon1"
                                      >

                                      </span>
                                    </div>
                                    <AutoComplete
                                      className="h-100"
                                      options={{
                                        types: ["establishment"],
                                        fields: ["name"],
                                      }}
                                      defaultValue={destiny}
                                      placeholder="Selecione o destino"
                                      apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                                      onPlaceSelected={(place) => {
                                        setDestiny(place.name);
                                        setError(false);
                                        // eslint-disable-next-line no-lone-blocks
                                        {
                                          geocodeByAddress(`${place.name}`)
                                            .then((results) =>
                                              getLatLng(results[0])
                                            )
                                            .then(({ lat, lng }) => {
                                              setLatDestiny(lat);
                                              setLongDestiny(lng);
                                            });
                                        }
                                      }}
                                    />

                                    {error === true &&
                                      (destiny === "" || destiny === null) ? (
                                      <div className="d-flex ml-3">
                                        <small
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {t("home.tabSearch.errorDestination")}
                                        </small>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <div className="input-group-append">
                                      <span
                                        className="input-group-text py-0 py-md-2"
                                        id="basic-addon1"
                                      >
                                        <img
                                          src={iconArrow}
                                          alt="pesquisar"
                                          className="py-1"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <Row className="mx-0 px-0">
                              <Col md={8} className="px-0 px-md-2">
                                <div className="bg-header search-tour px-1 py-1">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text py-0 py-md-2"
                                        id="basic-addon1"
                                      >

                                      </span>
                                    </div>
                                    <SingleCalendarTime setTime={setTime} />
                                    <div className="input-group-append">
                                      <span
                                        className="input-group-text py-1 py-md-2"
                                        id="basic-addon1"
                                      >
                                        <img
                                          src={iconArrow}
                                          alt="pesquisar"
                                          className="py-1"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={4} className="px-0 px-md-2">
                                <div className="bg-header search-tour search-transfers-people px-1 py-1">
                                  <div className="input-group transfer-icon">
                                    <div className="input-group-prepend">
                                      <button
                                        className="btn btn-less"
                                        type="button"
                                        onClick={less}
                                      >
                                        -
                                      </button>
                                    </div>

                                    <div className="icon-male">
                                      {/* <FontAwesomeIcon
                                        icon={["fal", "male"]}
                                        size="1x"
                                      /> */}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control py-1 py-md-2 px-4 text-center"
                                      placeholder=""
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                      value={num}
                                      disabled
                                    />

                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-more"
                                        type="button"
                                        onClick={more}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={2}>
                            <div className="px-1 px-md-3 py-1">
                              <button
                                type="button"
                                className="btn btn-primary btn-default"
                                data-btn="search"
                                id="button-search"
                                onClick={addToTransfers}
                              >
                                Encontrar Transfers
                              </button>
                            </div>
                          </Col>
                        </form>
                      </Row>
                    </div>
                  </Tab>
                </Tabs>
              </Row>
            </div>
          </Container>
        </Container>
      </>
    );
  } else {
    return (
      <></>
    )
  }
}

export default TabSearchs;