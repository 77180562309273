import React, { useEffect, useState }/* , { useEffect, useState } */ from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { removeTotalCart, updateItemCart, addPassengersToCart, updateCart, removeCupomCart, removePassengersToCart } from "../../../../store/cart";

import "./CartPDV.scss";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import ModalNewLayout from "../../../../components/Modal/ModalNewLayout/ModalNewLayout";
import api from "../../../../services/api";

//COMPONENTS

//ICONS

export interface propItems {
    setModalPaymentShow: any;
    setModalShow: any;
    setEdit: any;
    setModalInfo: any;
    setEditIndex: any;
}

const CartPDV: React.FC<propItems> = ({
    setModalPaymentShow, setModalShow, setEdit, setModalInfo, setEditIndex
}) => {
    const { t } = useTranslation();

    const [isBrazilian, setIsBrazilian] = useState<boolean>(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [chosenProduct, setChosenProduct] = useState<any>(null);
    const [numberAdults, setNumberAdults] = useState<number>(0);
    const [numberChilds, setNumberChilds] = useState<number>(0);
    const [numberInfants, setNumberInfants] = useState<number>(0);
    const [numberStudent, setNumberStudent] = useState<number>(0);
    const [numberElders, setNumberElders] = useState<number>(0);
    const [numberGlobal, setNumberGlobal] = useState<number>(0);
    const [numberTotal, setNumberTotal] = useState<number>(0);

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [modalBuyerInfo, setModalBuyerInfo] = useState<boolean>(false);
    const [modalCupom, setModalCupom] = useState<boolean>(false);
    const [modalValidation, setModalValidation] = useState<boolean>(false);
    const [modalBuyerInfoError, setModalBuyerInfoError] = useState<string>('');

    const [isFree, setIsFree] = useState<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isDescount, setIsDescount] = useState<any>(0);

    const [selectedItemToRemove, setSelectedItemToRemove] = useState<any>(null);

    const [modalState, setModalState] = useState<string>('');
    const [modalMessage, setModalMessage] = useState<string>('');

    const [cpfValidation, setCpfValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" do cpf

    const maxNumPeople = 99999999;

    const days = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];

    const month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

    const cartPDV = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)))

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === "CARACOL") {
            let isencaoAndCortesia: any = localStorage.getItem("IsencaoCortesia");
            let type: any = JSON.parse(isencaoAndCortesia);
            let aux = [];

            for (let i = 0; i < cartPDV.dados.length; i++) {
                aux.push(type?.includes(cartPDV.dados[i].productCode));
            }

            setIsFree(aux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartPDV.dados.length])

    const {
        handleSubmit,
        control,
        getValues,
        resetField,
        setValue,
        // formState: { errors },
    } = useForm();

    const today = new Date()
    const date = `${days[today.getDay()]}: ${today.getDate()} de ${month[today.getMonth()]} de ${today.getFullYear()}`;

    const local: any = localStorage.getItem('passengerInfo');
    const passenger: any = JSON.parse(local);

    //dispatch(removeTotalCart(cartPDV));

    const editProductCart = (code: string, index: number) => {
        let aux: any = cartPDV.dados[index];

        aux.adults = numberAdults;
        aux.childs = numberChilds;
        aux.infants = numberInfants;
        aux.globalPeople = aux.sellingType === 1 ? 1 : (numberAdults + numberChilds + numberInfants);
        aux.totalPeople = aux.sellingType === 1 ? numberGlobal : undefined;

        if (aux.productType !== 1) {
            aux.infants = numberInfants;
            aux.elders = numberElders;
            aux.student = numberStudent;
            aux.globalPeople = aux.sellingType === 1 ? 1 : (numberAdults + numberChilds + numberInfants);
            aux.totalPeople = aux.sellingType === 1 ? numberGlobal : undefined;
        }

        //aux.globalPeople = numberTotal;

        const newItem: any = {
            "index": index,
            "item": aux
        }

        dispatch(updateItemCart(newItem))
    }

    const openEdit = (product: any, index: number) => {
        /* setChosenProduct(product);

        setNumberAdults(product.adults);
        setNumberChilds(product.childs);
        setNumberInfants(product.infants);
        setNumberStudent(product.productType === 1 ? 0 : product.student);
        setNumberElders(product.productType === 1 ? 0 : product.elders);
        setNumberGlobal(product.totalPeople)
        setNumberTotal(product.adults + product.childs + (product.infants || 0) + (product.student || 0) + (product.elders || 0) + (product.totalPeolple || 0))

        let cartProductBox: any = document.getElementsByClassName('tour-edit');

        cartProductBox[index].classList.toggle('editable');

        for (let i: number = 0; i < cartProductBox.length; i++) {

            if (i !== index) {
                cartProductBox[i].classList.remove('editable');
            }
        } */

        setModalShow(true);
        setEdit(true);

        setModalInfo(product);
        setEditIndex(index);
    }

    useEffect(() => {
        if (chosenProduct !== null) {
            let edit: any = document.getElementsByClassName('tour-edit');
            let aux: any = false;

            for (let i: number = 0; i < edit.length; i++) {
                if (edit[i].classList.contains('editable')) {
                    aux = true;
                };
            }

            if (aux === true) {
                setIsEditing(true);
            } else {
                setIsEditing(false);
            }
        }
    }, [chosenProduct])

    const handleClickAdults = (controll: string) => {
        let newNumber = numberAdults;
        if (controll === "sub") {
            if (numberAdults === 0) {
                return false;
            } else {
                newNumber = numberAdults - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberAdults + 1;
            } else {
                return false;
            }
        }
        return setNumberAdults(newNumber);
    };

    const handleClickChilds = (controll: string) => {
        let newNumber = numberChilds;
        if (controll === "sub") {
            if (numberChilds === 0) {
                return false;
            } else {
                newNumber = numberChilds - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberChilds + 1;
            } else {
                return false;
            }
        }
        return setNumberChilds(newNumber);
    };

    const handleClickInfants = (controll: string) => {
        let newNumber = numberChilds;
        if (controll === "sub") {
            if (numberInfants === 0) {
                return false;
            } else {
                newNumber = numberInfants - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberInfants + 1;
            } else {
                return false;
            }
        }
        return setNumberInfants(newNumber);
    };

    const handleClickStudent = (controll: string) => {
        let newNumber = numberStudent;
        if (controll === "sub") {
            if (numberStudent === 0) {
                return false;
            } else {
                newNumber = numberStudent - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberStudent + 1;
            } else {
                return false;
            }
        }
        return setNumberStudent(newNumber);
    };

    const handleClickElders = (controll: string) => {
        let newNumber = numberElders;
        if (controll === "sub") {
            if (numberElders === 0) {
                return false;
            } else {
                newNumber = numberElders - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberElders + 1;
            } else {
                return false;
            }
        }
        return setNumberElders(newNumber);
    };

    const handleClickGlobal = (controll: string) => {
        let newNumber = numberGlobal;
        if (controll === "sub") {
            if (numberGlobal === 0) {
                return false;
            } else {
                newNumber = numberGlobal - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberGlobal + 1;
            } else {
                return false;
            }
        }
        return setNumberGlobal(newNumber);
    };

    useEffect(() => {
        setNumberTotal(numberAdults + numberChilds + numberElders + numberInfants + numberStudent)
    }, [numberAdults, numberChilds, numberElders, numberInfants, numberStudent]);

    function CpfValidator(strCPF: any) {
        if (strCPF !== null) {
            strCPF = removerCaracteresEspeciais(strCPF);  //remove ".", "-" e "-" que a máscara coloca
            var Soma;
            var Resto;
            Soma = 0;

            if (strCPF === "00000000000") {
                setCpfValidation(false)
                return false;
            }

            for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto === 10) || (Resto === 11)) Resto = 0;
            if (Resto !== parseInt(strCPF.substring(9, 10))) {
                setCpfValidation(false)
                return false;
            }

            Soma = 0;
            for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto === 10) || (Resto === 11)) Resto = 0;
            if (Resto !== parseInt(strCPF.substring(10, 11))) {
                setCpfValidation(false)
                return false;
            }

            setCpfValidation(true)
            return true;
        } else {
            setCpfValidation(false)
        }
    }

    const onSubmitInfo = () => {
        console.log(isBrazilian, cpfValidation);

        let data: any = getValues();

        let passenger: any = {
            firstName: data?.buyerName,
            email: data?.buyerEmail,
            DocumentNumber: removerCaracteresEspeciais(data?.buyerCPF),
            phone: removerCaracteresEspeciais(data?.buyerPhone),
            isBrazilian: isBrazilian
        }

        if(isBrazilian !== true) {
            setCpfValidation(true);
        }

        if (cpfValidation !== false && (getValues()?.buyerName !== "" && getValues()?.buyerName !== undefined && getValues()?.buyerCPF !== undefined && getValues()?.buyerCPF !== "")) {
            localStorage.setItem('passengerInfo', JSON.stringify(passenger));
            setModalBuyerInfo(false);
            setModalBuyerInfoError('');
        } else {
            setModalBuyerInfo(true);
            if ((getValues()?.buyerName === "" || getValues()?.buyerName === undefined)) {
                setModalBuyerInfoError('Por favor, informe um nome');
            } else {
                if(isBrazilian === true) {
                    setModalBuyerInfoError('CPF Inválido');
                } else {
                    setModalBuyerInfoError('ID Inválido');
                }
            }
        }
    }

    const addPassengers = () => {
        let data: any = getValues();

        let passenger: any = {
            firstName: data?.buyerName,
            email: data?.buyerEmail,
            DocumentNumber: removerCaracteresEspeciais(data?.buyerCPF),
            phone: removerCaracteresEspeciais(data?.buyerPhone)
        }
        dispatch(addPassengersToCart(passenger));
    }

    const resetarCampos = () => {
        resetField('buyerName');
        resetField('buyerEmail');
        resetField('buyerCPF');
        resetField('buyerPhone');
        setCpfValidation(false);
        setModalBuyerInfoError('');
        dispatch(removePassengersToCart());
        localStorage.removeItem('passengerInfo');
    }

    useEffect(() => {
        let data: any = getValues();

        if (cartPDV.dados.length === 0 && (data.buyerName !== undefined || data.buyerEmail !== undefined || data.buyerCPF !== undefined)) {
            resetarCampos();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartPDV])

    const handleAdults = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - (numberChilds + numberStudent + numberInfants + numberElders))) {
            setNumberAdults(Number(e.target.value));
        } else {
            setNumberAdults(maxNumPeople - (numberChilds + numberStudent + numberInfants + numberElders));
        }
    }

    const handleChilds = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - (numberAdults + numberStudent + numberInfants + numberElders))) {
            setNumberChilds(Number(e.target.value));
        } else {
            setNumberChilds(maxNumPeople - (numberAdults + numberStudent + numberInfants + numberElders));
        }
    }

    const handleStudent = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - (numberAdults + numberChilds + numberInfants + numberElders))) {
            setNumberStudent(Number(e.target.value));
        } else {
            setNumberStudent(maxNumPeople - (numberAdults + numberChilds + numberInfants + numberElders));
        }
    }

    const handleElders = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - (numberAdults + numberChilds + numberInfants + numberStudent))) {
            setNumberElders(Number(e.target.value));
        } else {
            setNumberElders(maxNumPeople - (numberAdults + numberChilds + numberInfants + numberStudent));
        }
    }

    const handleInfants = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - (numberAdults + numberChilds + numberElders + numberStudent))) {
            setNumberInfants(Number(e.target.value));
        } else {
            setNumberInfants(maxNumPeople - (numberAdults + numberChilds + numberElders + numberStudent));
        }
    }

    const handleGlobal = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - numberGlobal)) {
            setNumberGlobal(Number(e.target.value));
        } else {
            setNumberGlobal(maxNumPeople - numberGlobal);
        }
    }

    const init_area = (event: any) => {
        event.target.removeAttribute("readonly");
    }

    const validateTourCode = async (code: any) => {
        setModalState('loading');
        setModalValidation(true);

        if (code === '') {
            setModalState('error');
            setModalMessage("Cupom inválido");
        }
        try {
            const { data } = await api.get(`/TourCode/ValidateCoupon/${code}`);
            if (data.status !== 400) {
                if (data.data === null) {
                    setModalState('error');
                    setModalMessage("Cupom inválido");
                } else {
                    if (data.data.typeDiscount !== 3) {
                        setModalCupom(false);
                        setModalState('success');
                        setModalMessage("Cupom adicionado com sucesso");

                        setValue('cupomName', '');
                    }

                    if (data.data.typeDiscount === 2) {
                        cartPDV.cupom = {
                            type: data.data.typeDiscount,
                            code: data.data.code,
                            codeName: data.data.codeName,
                            value: data.data.valueDiscount,
                        };
                        cartPDV.discount = data.data.valueDiscount;
                        cartPDV.tourCode = undefined

                        dispatch(updateCart(cartPDV));

                    }
                    else if (data.data.typeDiscount === 3) {
                        cartPDV.cupom = {
                            type: data.data.typeDiscount,
                            code: data.data.code,
                            codeName: data.data.codeName,
                            value: data.data.valueDiscount,
                        };
                        cartPDV.discount = data.data.valueDiscount;
                        cartPDV.tourCode = undefined;

                        verifyAllCart();
                    } else if (data.data.typeDiscount === 4) {
                        cartPDV.cupom = {
                            type: data.data.typeDiscount,
                            code: data.data.code,
                            codeName: data.data.codeName,
                            value: data.data.valueDiscount,
                        };
                        cartPDV.discount = data.data.valueDiscount;
                        cartPDV.tourCode = undefined;

                        dispatch(updateCart(cartPDV));
                        //dispatch(updateCupomCart(cupom));

                    }
                }
            }

        } catch (error: any) {
            if (error?.response?.status === 400) {
            }
        }
    }

    const verifyAllCart = () => {
        var dados: any = [...cartPDV.dados]
        let atLeastOneValid: boolean = false;
        cartPDV.dados.forEach((cItem: any, index: any) => {
            const validateTourCodeProduct = async () => {
                try {
                    const { data } = await api.get(`/api/TourCode/ValidateTourCodeProduct/${cartPDV.cupom.code}/${cItem.productCode}`);
                    if (data.statusCode === 200) {
                        atLeastOneValid = true;
                        cItem.discountBool = true;
                        cItem.discount = data.data.valueDiscount;
                    } else {
                        cItem.discountBool = false;
                        cItem.discount = 0;
                    }

                    if (index === dados.length - 1) {
                        dispatch(updateCart(cartPDV));

                        if (atLeastOneValid === true) {
                            //setModalState('success');
                            //setModalMessage("Cupom adicionado com sucesso");
                        } else {
                            //setModalState('success');
                            //setModalMessage("Cupom não elegível para essa compra");
                        }
                    }

                } catch (error: any) {
                    console.log(error)
                }
            }

            validateTourCodeProduct();

        });

    }

    function onSubmit(e: any) {
        validateTourCode(e.cupomName);
    }

    function removerCaracteresEspeciais(str: string) {
        // Use uma expressão regular para encontrar todos os caracteres que não são letras ou números
        const regex = /[^a-zA-Z0-9]/g;

        // Use o método split para dividir a string em um array de substrings
        // onde o separador é qualquer caractere que corresponda à expressão regular
        const substrings = str?.split(regex);

        // Use o método join para unir as substrings em uma única string
        const resultado = substrings?.join('');

        return resultado;
    }

    useEffect(() => {
        if (cartPDV.dados.length === 0) {
            localStorage.removeItem('passengerInfo');
        }
    }, [cartPDV.dados]);

    useEffect(() => {
        if(passenger !== undefined && passenger !== null) {
            setValue('buyerName', passenger.firstName);
            setValue('buyerEmail', passenger.email);
            setValue('buyerCPF', passenger.DocumentNumber);
            setValue('buyerPhone', passenger.phone);
            setIsBrazilian(passenger.isBrazilian);

            if (passenger.isBrazilian === true) {
                CpfValidator(passenger.DocumentNumber);
            } else {
                setCpfValidation(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setValue('buyerCPF', "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBrazilian])

    return (
        <>
            <div className="cart">
                <div className="cart-header flex-column align-items-start">
                    <p className="mb-1">Carrinho{cartPDV.dados.length !== 0 ? `, ${cartPDV.dados.length}` : ``} {cartPDV.dados.length === 0 ? `` : cartPDV.dados.length === 1 ? ` item` : ` items`}</p>
                    <small style={{ fontSize: ".75rem" }}>{date}</small>
                </div>
                <div className="cart-body">
                    <div>
                        {
                            cartPDV.dados.length !== 0
                                ?
                                <div className="d-flex justify-content-start buyer-info mt-2">
                                    <p onClick={() => { setModalBuyerInfo(true) }}>Incluir informações do comprador <span>(opcional)</span></p>
                                </div>
                                :
                                ""
                        }
                        <div className="info-buyer">
                            <Row className="mx-0 px-0">
                                {
                                    passenger?.firstName !== undefined
                                        ?
                                        <Col md={6} className="text-start px-0">
                                            <h6>Nome</h6>
                                            <p>{passenger?.firstName}</p>
                                        </Col>
                                        :
                                        ""
                                }
                                {
                                    passenger?.email !== undefined
                                        ?
                                        <Col md={6} className="text-start px-0">
                                            <h6>Email</h6>
                                            <p>{passenger?.email}</p>
                                        </Col>
                                        :
                                        ""
                                }
                                {
                                    passenger?.DocumentNumber !== undefined
                                        ?
                                        <Col md={6} className="text-start px-0">
                                            <h6>{passenger.isBrazilian === true ? "CPF" : "ID"}</h6>
                                            <p>{passenger?.DocumentNumber}</p>
                                        </Col>
                                        :
                                        ""
                                }
                                {
                                    passenger?.phone !== undefined
                                        ?
                                        <Col md={6} className="text-start px-0">
                                            <h6>Telefone</h6>
                                            <p>{passenger?.phone}</p>
                                        </Col>
                                        :
                                        ""
                                }
                            </Row>
                        </div>
                    </div>
                    <div>
                        <ul className="listItens">
                            {cartPDV.dados.length >= 1
                                ? cartPDV.dados.map((elem: any, index: number) => {
                                    return (
                                        <div key={index}>
                                            <li className="lineItemCart border-bottom row pb-3 m-0" key={index}>
                                                <div className="col-md-3 tour-img" style={{ backgroundImage: `url(${elem.imagesBaseUrl === undefined ? `${process.env.REACT_APP_SERVER_URL_IMG}` : elem.imagesBaseUrl}${elem.imgCart})` }}></div>
                                                <div className="col-md-9 tour-info">
                                                    <div className="d-flex justify-content-between">
                                                        <span className='title text-start'>{elem.productName}</span>
                                                        <span className='price'>{(elem.priceAdults * elem.adults + elem.priceChilds * elem.childs + elem.priceElders * elem.elders + elem.priceInfants * elem.infants + elem.priceStudent * elem.student + elem.priceGlobalPeople * elem.globalPeople)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between gap-3 mt-2">
                                                        <div className="d-flex flex-column align-items-start">
                                                            <div className="text-start">
                                                                {
                                                                    elem.productType === 4 && elem?.time !== null
                                                                        ?
                                                                        <span className="info"><FontAwesomeIcon icon={["fal", "calendar-alt"]} className="mr-2" />{`${t("cart.date")} ${elem?.date.split('-').reverse().join('/')} - ${elem?.time}`}</span>
                                                                        :
                                                                        <span className="info"><FontAwesomeIcon icon={["fal", "calendar-alt"]} className="mr-2" />{`${t("cart.date")} ${elem?.date.split('-').reverse().join('/')}`}</span>
                                                                }
                                                            </div>
                                                            <div>
                                                                <span className="info"><FontAwesomeIcon icon={['fal', 'user']} className="mr-2" />{`${t("cart.totalTicket")} ${((elem.adults || 0) + (elem.childs || 0) + (elem.elders || 0) + (elem.infants || 0) + (elem.student || 0)) + (elem.totalPeople || 0)}`}</span>
                                                            </div>
                                                        </div>
                                                        {
                                                            isEditing === false || chosenProduct?.productCode !== elem.productCode
                                                                ?
                                                                <div className="d-flex flex-column justify-content-end gap-2">
                                                                    <Button className="btn-item-cart d-flex align-items-center" onClick={() => setSelectedItemToRemove(elem)}>
                                                                        <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-1" />
                                                                        Remover
                                                                    </Button>
                                                                    {
                                                                        elem.productType !== 2
                                                                        &&
                                                                        <Button className="btn-item-cart" onClick={() => { openEdit(elem, index) }}>
                                                                            <FontAwesomeIcon icon={["fal", "edit"]} className="mr-1" />
                                                                            Editar
                                                                        </Button>
                                                                    }
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    elem.productType === 1
                                                        ?
                                                        <div className="d-flex justify-content-between align-items-center px-0 text-start mt-3">
                                                            <div>
                                                                <p className="mb-1" style={{ fontSize: ".825rem", fontWeight: 600, lineHeight: "1rem" }}>{elem.meetingPoint === "" ? "Local de Embarque" : "Ponto de Encontro"}</p>
                                                                {
                                                                    elem.meetingPoint === ""
                                                                        ?
                                                                        <small style={{ fontSize: ".75rem", display: 'block' }}>{elem?.pickupInfo?.name}</small>
                                                                        :
                                                                        <small style={{ fontSize: ".75rem", display: 'block' }}>{elem?.meetingPoint}</small>
                                                                }
                                                            </div>

                                                            {/* <FontAwesomeIcon icon={["fas", "edit"]} className="edit-pickup" style={{ cursor: "pointer" }}/> */}

                                                            {/* <GetPickups
                                                        propsId={cartPDV.dados[indexCart].pickupListId}
                                                        actionPickup={addPickup}
                                                        alert={alert}
                                                        index={indexCart}
                                                    /> */}
                                                        </div>
                                                        :
                                                        ""
                                                }

                                                <div className={`col-md-12 tour-edit ${elem.productType === 1 ? "tour" : "ticket"}`}>
                                                    <div className="d-flex flex-column mt-5">
                                                        {
                                                            chosenProduct?.productType === 1
                                                                ?
                                                                <>
                                                                    {/* TOUR */}
                                                                    {
                                                                        process.env.REACT_APP_ENVIRONMENT === "CARACOL"
                                                                            ?
                                                                            <div className='step mb-0'>
                                                                                <div className="prices-modal">
                                                                                    {
                                                                                        elem.ranges?.isActiveAdult === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">
                                                                                                    <label>
                                                                                                        {elem.ranges?.labelAdult}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <button className="qtyDec" onClick={() => handleClickAdults("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberAdults}
                                                                                                        onChange={(e: any) => handleAdults(e)}
                                                                                                        id="numero-adulto"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickAdults("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-adulto-sm"
                                                                                                    >
                                                                                                        R$ {(elem?.priceAdults).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveChild === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelChild}
                                                                                                    </label>
                                                                                                    <small id="idade-crianca">
                                                                                                        ({t("products.ticket.years01")})
                                                                                                    </small>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickChilds("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberChilds}
                                                                                                        onChange={(e: any) => handleChilds(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickChilds("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceChilds).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveElders === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelElders}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickElders("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberElders}
                                                                                                        onChange={(e: any) => handleElders(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickElders("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceElders).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveInfant === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelInfant}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickInfants("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberInfants}
                                                                                                        onChange={(e: any) => handleInfants(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickInfants("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceInfants).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveStudent === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelStudent}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickStudent("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberStudent}
                                                                                                        onChange={(e: any) => handleStudent(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickStudent("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceStudent).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                </div>
                                                                                <div className="d-flex justify-content-end gap-2">
                                                                                    <button type="button" className="btn btn-primary text-white" onClick={() => { openEdit(elem, index) }}>Cancelar</button>
                                                                                    <button type="button" className="btn btn-outline-primary text-primary" onClick={() => { openEdit(elem, index); editProductCart(elem.productCode, index) }}>Atualizar</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className='step mb-0'>
                                                                                <div className="prices-modal">
                                                                                    {
                                                                                        elem.ranges?.isActiveAdult === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">
                                                                                                    <label>
                                                                                                        {elem.ranges?.labelAdult}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <button className="qtyDec" onClick={() => handleClickAdults("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberAdults}
                                                                                                        onChange={(e: any) => handleAdults(e)}
                                                                                                        id="numero-adulto"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickAdults("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-adulto-sm"
                                                                                                    >
                                                                                                        R$ {(elem?.priceAdults).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveChild === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelChild}
                                                                                                    </label>
                                                                                                    <small id="idade-crianca">
                                                                                                        ({t("products.ticket.years01")})
                                                                                                    </small>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickChilds("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberChilds}
                                                                                                        onChange={(e: any) => handleChilds(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickChilds("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceChilds).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveElders === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelElders}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickElders("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberElders}
                                                                                                        onChange={(e: any) => handleElders(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickElders("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceElders).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveInfant === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelInfant}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickInfants("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberInfants}
                                                                                                        onChange={(e: any) => handleInfants(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickInfants("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceInfants).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveStudent === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelStudent}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickStudent("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberStudent}
                                                                                                        onChange={(e: any) => handleStudent(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickStudent("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceStudent).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActivePrivate === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelPrivate}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickGlobal("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberGlobal}
                                                                                                        onChange={(e: any) => handleGlobal(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickGlobal("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceGlobalPeople).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                </div>
                                                                                <div className="d-flex justify-content-end gap-2">
                                                                                    <button type="button" className="btn btn-primary text-white" onClick={() => { openEdit(elem, index) }}>Cancelar</button>
                                                                                    <button type="button" className="btn btn-outline-primary text-primary" onClick={() => { openEdit(elem, index); editProductCart(elem.productCode, index) }}>Atualizar</button>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {/* TICKET */}
                                                                    {
                                                                        process.env.REACT_APP_ENVIRONMENT === "CARACOL"
                                                                            ?
                                                                            <div className='step mb-0'>
                                                                                <div className="prices-modal ticket">
                                                                                    {
                                                                                        elem.ranges?.isActiveAdult === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">
                                                                                                    <label>
                                                                                                        {elem.ranges?.labelAdult}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <button className="qtyDec" onClick={() => handleClickAdults("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberAdults}
                                                                                                        onChange={(e: any) => handleAdults(e)}
                                                                                                        id="numero-adulto"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickAdults("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-adulto-sm"
                                                                                                    >
                                                                                                        R$ {(elem?.priceAdults).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveChild === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelChild}
                                                                                                    </label>
                                                                                                    <small id="idade-crianca">
                                                                                                        ({t("products.ticket.years01")})
                                                                                                    </small>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickChilds("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberChilds}
                                                                                                        onChange={(e: any) => handleChilds(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickChilds("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceChilds).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveElders === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelElders}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickElders("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberElders}
                                                                                                        onChange={(e: any) => handleElders(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickElders("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceElders).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveStudent === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelStudent}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickStudent("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberStudent}
                                                                                                        onChange={(e: any) => handleStudent(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickStudent("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceStudent).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }


                                                                                    {
                                                                                        elem.ranges?.isActiveInfant === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelInfant}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickInfants("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberInfants}
                                                                                                        onChange={(e: any) => handleInfants(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickInfants("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceInfants).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }
                                                                                </div>
                                                                                <div className="d-flex justify-content-end gap-2">
                                                                                    <button type="button" className="btn btn-primary text-white" onClick={() => { openEdit(elem, index) }}>Cancelar</button>
                                                                                    <button type="button" className="btn btn-outline-primary text-primary" onClick={() => { openEdit(elem, index); editProductCart(elem.productCode, index) }}>Atualizar</button>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className='step mb-0'>
                                                                                <div className="prices-modal ticket">
                                                                                    {
                                                                                        elem.ranges?.isActiveAdult === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">
                                                                                                    <label>
                                                                                                        {elem.ranges?.labelAdult}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <button className="qtyDec" onClick={() => handleClickAdults("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberAdults}
                                                                                                        onChange={(e: any) => handleAdults(e)}
                                                                                                        id="numero-adulto"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickAdults("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-adulto-sm"
                                                                                                    >
                                                                                                        R$ {(elem?.priceAdults).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveInfant === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelInfant}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickInfants("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberInfants}
                                                                                                        onChange={(e: any) => handleInfants(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickInfants("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceInfants).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveChild === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelChild}
                                                                                                    </label>
                                                                                                    <small id="idade-crianca">
                                                                                                        ({t("products.ticket.years01")})
                                                                                                    </small>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickChilds("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberChilds}
                                                                                                        onChange={(e: any) => handleChilds(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickChilds("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceChilds).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveElders === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelElders}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickElders("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberElders}
                                                                                                        onChange={(e: any) => handleElders(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickElders("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceElders).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }

                                                                                    {
                                                                                        elem.ranges?.isActiveStudent === true
                                                                                            ?
                                                                                            <div className="qtyButtons">
                                                                                                <div className="flex-ajuste">

                                                                                                    <label>
                                                                                                        {elem.ranges?.labelStudent}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <button className="qtyDec" onClick={() => handleClickStudent("sub")}>
                                                                                                        <span>-</span>
                                                                                                    </button>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="qtyInput"
                                                                                                        value={numberStudent}
                                                                                                        onChange={(e: any) => handleStudent(e)}
                                                                                                        id="numero-crianca"
                                                                                                        readOnly onClick={(e: any) => { init_area(e) }}
                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                    />
                                                                                                    <button className="qtyInc" onClick={() => handleClickStudent("plus")}>
                                                                                                        <span>+</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <span
                                                                                                        className="price-dropdown font-weight-bold w-100"
                                                                                                        id="valor-crianca-sm"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        R$ {(elem?.priceStudent).toFixed(2).split(".").join(",")}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            ""
                                                                                    }
                                                                                </div>
                                                                                <div className="d-flex justify-content-end gap-2">
                                                                                    <button type="button" className="btn btn-primary text-white" onClick={() => { openEdit(elem, index) }}>Cancelar</button>
                                                                                    <button type="button" className="btn btn-outline-primary text-primary" onClick={() => { openEdit(elem, index); editProductCart(elem.productCode, index) }}>Atualizar</button>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                })
                                :
                                <></>
                            }
                        </ul>
                    </div>
                </div>
                {
                    cartPDV.dados.length !== 0
                        ?
                        <div className="cart-footer">
                            {/* {
                                process.env.REACT_APP_ENVIRONMENT === "CARACOL" && isFree?.includes(true)
                                    ?
                                    <div className="d-flex justify-content-between mb-2">
                                        <small>Desconto</small>
                                        <small>
                                            {isDescount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </small>
                                    </div>
                                    :
                                    ""
                            } */}
                            {
                                cartPDV?.cupom?.type === 2
                                    ?
                                    <small style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            <span>{cartPDV.cupom.codeName}</span>
                                            <span>R$ {cartPDV?.cupom?.value.toFixed(2).split(".").join(",")}</span>
                                        </div>
                                    </small>
                                    :
                                    ''
                            }

                            {
                                cartPDV?.cupom?.type === 4 ?
                                    <small className="d-flex justify-content-between pr-0 mb-2">
                                        <span >{cartPDV.cupom.codeName}</span>
                                        <span> {cartPDV?.cupom?.value + '%'}{/*  - {(totalCart * (cartPDV?.cupom?.value / 100)).toFixed(2).split(".").join(",")} */}
                                            <button
                                                onClick={() => {
                                                    dispatch(removeCupomCart());
                                                }}
                                                className="p-0 m-0 text-muted btn-exclui-carrinho"
                                                style={{ cursor: "pointer", marginRight: "12px" }}
                                            >
                                                <i className="text-muted btn-exclui text-dark">
                                                    <FontAwesomeIcon style={{ color: "#212529" }} icon={["fal", "times"]} />
                                                </i>
                                            </button>
                                        </span>
                                    </small>
                                    :
                                    ''}
                            <div className="d-flex justify-content-between">
                                <h5 style={{ fontWeight: 600, fontSize: "1.5rem" }}>Total</h5>
                                <h5 style={{ fontWeight: 700, fontSize: "1.5rem" }}>{cartPDV.totalCart?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h5>
                            </div>
                            {
                                cartPDV?.cupom?.codeName === undefined
                                    ?
                                    <div>
                                        <div className="d-flex justify-content-end buyer-info px-0 pt-1 pb-2">
                                            <p onClick={() => { setModalCupom(true) }} className="mb-0">Adicionar Cupom</p>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                            <div className="d-flex justify-content-between gap-3">
                                <button className={isEditing === true ? "btn btn-default btn-outline-cancel mt-3 disabled" : "btn btn-default btn-outline-cancel mt-3"} type='button' onClick={() => { resetarCampos(); setIsEditing(false); dispatch(removeTotalCart(cartPDV.dados)); }}>Limpar Carrinho</button>
                                {
                                    process.env.REACT_APP_ENVIRONMENT === "CARACOL"
                                        ?
                                        isFree?.includes(false)
                                            ?
                                            <button className={isEditing === true ? "btn btn-default btn-success-buy mt-3 disabled" : "btn btn-default btn-success-buy mt-3"} type='button' onClick={() => { addPassengers(); setModalPaymentShow(true) }}>Pagar Agora</button>
                                            :
                                            <button className={isEditing === true ? "btn btn-default btn-success-buy mt-3 disabled" : "btn btn-default btn-success-buy mt-3"} type='button' onClick={() => { addPassengers(); setModalPaymentShow(true) }}>Finalizar</button>
                                        :
                                        <button className={isEditing === true ? "btn btn-default btn-success-buy mt-3 disabled" : "btn btn-default btn-success-buy mt-3"} type='button' onClick={() => { addPassengers(); setModalPaymentShow(true) }}>Pagar Agora</button>
                                }
                            </div>
                        </div>
                        :
                        <></>
                }

                <ModalNewLayout show={selectedItemToRemove} setShow={setSelectedItemToRemove} />

                {
                    cartPDV?.dados.length !== 0
                        ?
                        <Modal
                            className="modalInfoBuyer"
                            show={modalBuyerInfo}
                            /* onHide={() => { setModalBuyerInfo(false) }} */
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <>
                                {/* <Modal.Header style={{ border: 'none' }}>

                                </Modal.Header> */}
                                <Modal.Body
                                    className="modal-body text-center sucess-pos d-flex justify-content-evenly "
                                /* style={{ height: "370px" }} */
                                >
                                    <div className="modal-container w-100 pt-0 buyer-info">
                                        <h3 className='modal-title mb-3'>Informações do comprador</h3>

                                        <Form
                                            noValidate
                                            validated={false}
                                            onSubmit={handleSubmit(onSubmitInfo)}
                                            autoComplete="false"
                                        >
                                            <div className="d-flex flex-column justify-content-between">

                                                <div className='mt-4'>
                                                    {/* <Form.Label>
                                                        Tipo de Pessoa
                                                    </Form.Label> */}
                                                    <Row className="mb-4">
                                                        <Form.Group as={Col} md="3" controlId="">
                                                            <div className="d-flex">
                                                                <Controller
                                                                    control={control}
                                                                    name="compType"
                                                                    render={({ field }: any) => (
                                                                        <Form.Check
                                                                            {...field}
                                                                            onClick={() => { setIsBrazilian(true) }}
                                                                            checked={isBrazilian === true}
                                                                            type="radio"
                                                                            variant="standard"
                                                                            margin="normal"
                                                                            value="1"
                                                                            required
                                                                            className="mr-2"
                                                                        />
                                                                    )}
                                                                />
                                                                <Form.Label className="mb-0">
                                                                    Brasileiro
                                                                </Form.Label>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="3" controlId="">
                                                            <div className="d-flex">
                                                                <Controller
                                                                    control={control}
                                                                    name="compType"
                                                                    render={({ field }: any) => (
                                                                        <Form.Check
                                                                            {...field}
                                                                            onClick={() => { setIsBrazilian(false) }}
                                                                            checked={isBrazilian === false}
                                                                            type="radio"
                                                                            variant="standard"
                                                                            margin="normal"
                                                                            value="2"
                                                                            required
                                                                            className="mx-2"
                                                                        />
                                                                    )}
                                                                />
                                                                <Form.Label className="mb-0">
                                                                    Estrangeiro
                                                                </Form.Label>
                                                            </div>
                                                        </Form.Group>
                                                    </Row>

                                                    <Form.Group as={Col} md={12} className='mb-3'>
                                                        <Form.Label className="">
                                                            Nome
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="buyerName"
                                                            render={({ field }: any) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="text"
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    autoComplete="off"
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={12} className='mb-3'>
                                                        <Form.Label className="">
                                                            Email
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="buyerEmail"
                                                            render={({ field }: any) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="email"
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    autoComplete="off"
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={12} className='mb-3'>
                                                        <Form.Label className="">
                                                            {isBrazilian === true ? "CPF" : "ID"}
                                                        </Form.Label>
                                                        {
                                                            isBrazilian === true
                                                                ?
                                                                <Controller
                                                                    control={control}
                                                                    name="buyerCPF"
                                                                    render={({ field }: any) => (
                                                                        <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange} onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()} onInput={(e: any) => { CpfValidator(e.target.value) }}>
                                                                            {(inputProps: any) => (
                                                                                <Form.Control
                                                                                    {...inputProps}
                                                                                    type="text"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    autoComplete="off"
                                                                                //onInput={(e: any) => { CpfValidator(e.target.value) }}
                                                                                />
                                                                            )}
                                                                        </InputMask>
                                                                    )}
                                                                />
                                                                :
                                                                <Controller
                                                                    control={control}
                                                                    name="buyerCPF"
                                                                    render={({ field }: any) => (
                                                                        <Form.Control
                                                                            {...field}
                                                                            type="text"
                                                                            variant="standard"
                                                                            margin="normal"
                                                                            autoComplete="off"
                                                                        />
                                                                    )}
                                                                />
                                                        }
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={12} className='mb-3'>
                                                        <Form.Label className="">
                                                            Telefone
                                                        </Form.Label>
                                                        {
                                                            isBrazilian === true
                                                                ?
                                                                <Controller
                                                                    control={control}
                                                                    name="buyerPhone"
                                                                    render={({ field }: any) => (
                                                                        <InputMask mask="(99) 99999-9999" value={field?.value} onChange={field?.onChange} onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}>
                                                                            {(inputProps: any) => (
                                                                                <Form.Control
                                                                                    {...inputProps}
                                                                                    type="text"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                    autoComplete="off"
                                                                                />
                                                                            )}
                                                                        </InputMask>
                                                                    )}
                                                                />
                                                                :
                                                                <Controller
                                                                    control={control}
                                                                    name="buyerPhone"
                                                                    render={({ field }: any) => (
                                                                        <Form.Control
                                                                            {...field}
                                                                            type="text"
                                                                            variant="standard"
                                                                            margin="normal"
                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                            autoComplete="off"
                                                                        />
                                                                    )}
                                                                />
                                                        }
                                                    </Form.Group>
                                                    <span style={{ color: 'red' }} className='mb-0'>{modalBuyerInfoError}</span>
                                                </div>
                                                <div className="d-flex justify-content-end mt-4">
                                                    <button type='button' className="btn btn-outline-primary mr-2" onClick={() => { resetarCampos(); setModalBuyerInfo(false) }}>Cancelar</button>
                                                    <Button type='submit' className="btn btn-primary text-white">Confirmar</Button>
                                                </div>
                                            </div>
                                        </Form>

                                    </div>
                                </Modal.Body>
                            </>
                        </Modal>
                        :
                        <></>
                }

                {
                    cartPDV?.dados.length !== 0
                        ?
                        <Modal
                            className="modalInfoBuyer"
                            show={modalCupom}
                            /* onHide={() => { setModalBuyerInfo(false) }} */
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <>
                                {/* <Modal.Header style={{ border: 'none' }}>

                                </Modal.Header> */}
                                <Modal.Body
                                    className="modal-body text-center sucess-pos d-flex justify-content-evenly "
                                    style={{ height: '100%' }}
                                >
                                    <div className="modal-container w-100 pt-0 buyer-info">
                                        <h3 className='modal-title mb-3'>Adicionar Cupom</h3>

                                        <Form
                                            noValidate
                                            validated={false}
                                            onSubmit={handleSubmit(onSubmit)}
                                            autoComplete="false"
                                        >
                                            <div className="d-flex flex-column justify-content-between">

                                                <div className='mt-2'>
                                                    <Form.Group as={Col} md={12} className='mb-3'>
                                                        <Form.Label className="">
                                                            Cupom
                                                        </Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="cupomName"
                                                            render={({ field }: any) => (
                                                                <Form.Control
                                                                    {...field}
                                                                    type="text"
                                                                    variant="standard"
                                                                    margin="normal"
                                                                    autoComplete="off"
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>

                                                    <span style={{ color: 'red' }} className='mb-0'>{modalBuyerInfoError}</span>
                                                </div>
                                                <div className="d-flex justify-content-end mt-4">
                                                    <button type='button' className="btn btn-outline-primary mr-2" onClick={() => { resetarCampos(); setModalCupom(false) }}>Cancelar</button>
                                                    <Button type='submit' className="btn btn-primary text-white">Confirmar</Button>
                                                </div>
                                            </div>
                                        </Form>

                                    </div>
                                </Modal.Body>
                            </>
                        </Modal>
                        :
                        <></>
                }

                <Modal
                    className="modalAuth modal-validation"
                    show={modalValidation}
                    //onHide={() => { setModalValidation(null) }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
                        >
                            <div>
                                {
                                    modalState !== 'loading'
                                        ?
                                        <FontAwesomeIcon
                                            icon={["fal", modalState === 'success' ? "check-circle" : modalState === 'error' ? "times-circle" : "question-circle"]}
                                            size="5x"
                                            className='text-primary'
                                        />
                                        :
                                        <div className='load'></div>
                                }
                            </div>

                            <small>{modalMessage}</small>
                            {
                                modalState !== 'loading'
                                    ?
                                    <div className="d-flex justify-content-center pt-3">
                                        <button
                                            onClick={() => {
                                                setModalValidation(false);
                                            }}
                                            className="btn btn-primary mx-2 w-25"
                                            style={{ color: '#FFF' }}
                                        >
                                            Sair
                                        </button>
                                    </div>
                                    :
                                    ""
                            }
                        </Modal.Body>
                    </>
                </Modal>

            </div>

        </>
    )

}

export default CartPDV;