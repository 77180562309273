import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { removeTotalCart } from "../../../../store/cart";

import './InstructionsPrint.scss';

//COMPONENTS

//ICONS
import iconPrintingTickets from '../../../../assets/icons/printing-tickets.svg';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../../services/api";

declare global {
  interface Window {
    BrowserPrint?: any;
  }
}

export interface printing {
  printer?: any;
  setModalShow?: any;
  checkoutError?: any;
  printerSecondWay?: any;
  bookLocFinal?: any;
  tokenCode?: any;
};

const InstructionsPrint: React.FC<printing> = React.memo(({ printer, setModalShow, checkoutError, printerSecondWay, bookLocFinal, tokenCode
}: printing) => {

  const dispatch = useAppDispatch();
  const cartPDV: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const [chooseDevice, setChooseDevice] = useState<any>('');
  const [available, setAvailabe] = useState<number>(0);
  const [management, setManagement] = useState('imprimindo');

  const [paper, setPaper] = useState<any>('');
  const [stop, setStop] = useState<any>('');

  const [messagePrint, setMessagePrint] = useState<any>('');

  const [errorCode, setErrorCode] = useState<string>("");

  const [isPrint, setIsPrint] = useState<boolean>(false);
  //const [reprintTickets, setReprintTickets] = useState<any>(false);

  //const [reprintAll, setReprintAll] = useState<any>(true);

  const dateChanged: any = new Date().getTime();

  /*   function Reset() {
      if (printer === undefined) {
        localStorage.removeItem("printZpl");
        window.location.href = "/resgatar-compra";
      } else {
        window.location.href = "/";
      }
    } */

  function Success() {
    if (printer === undefined) {
      window.location.href = "/buscar-ingressos";
    } else {
      window.location.href = "/tickets";
      dispatch(removeTotalCart(cartPDV.dados));
    }
  }

  useEffect(() => {
    if (printer !== undefined) {
      coupomTicket(printer?.ticket); // Chamada API do Caracol --- Compra
    } else {
      coupomTicket(printerSecondWay?.destinowVoucher); // Chamada API do Caracol --- Reimpressão
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrint])

  const coupomTicket = useCallback(async (printer) => { // Impressora Caracol

    try {
      const { data } = await api.post(`http://localhost:5000/api/impressao/totem/mp4200`, printer);
      if (data?.statusCode !== 400) {
        setTimeout(() => {
          setManagement('success');
        }, 5000);
      } else {
        setManagement("error");
        //setReprintTickets(true);
      }
    } catch (e) {
      setManagement("error");
      //setReprintTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  var readCallback = function (readData: any) {
    if (readData === undefined || readData === null || readData === "") {
      /* setPaper('0');
      setStop('0'); */
      setAvailabe(2);
      //console.log("Oiiiii!");
      setManagement("error");
      checkoutError('-992', 'Impressora');
      setErrorCode(`FFI00${dateChanged}-992`); // 92 - Erro na leitura da impressora
    }
    else {
      setPaper(readData.split(",")[1]);
      setStop(readData.split(",")[2]);
      setAvailabe(2);
    }

  }

  var errorCallback = function (errorMessage: any) {
    setManagement("error");
    checkoutError('-993', 'Impressora');
    setErrorCode(`FFI00${dateChanged}-993`); // 93 - Erro na escrita para impressora (Não houve pulseira o suficiente para imprimir)
  }

  function writeToSelectedPrinter(dataToWrite: any) {
    if (dataToWrite === "~hs") {
      chooseDevice.send(dataToWrite, undefined, errorCallback);
    } else {
      chooseDevice.send(dataToWrite, undefined, errorCallback);
    }
  }

  function approved() {

    if (printer === undefined) {
      setTimeout(() => {
        setManagement("success");
      }, 10000);
    } else {

      setTimeout(() => {
        setManagement("success");
      }, 10000);
    }
  }

  function readFromSelectedPrinter() {
    chooseDevice.read(readCallback, errorCallback);
  }

  useEffect(() => {
    /* console.log(available) */
    if (available === 1 && chooseDevice !== undefined) {
      readFromSelectedPrinter();
      writeToSelectedPrinter("~hs");
    } else if (available === 1 && chooseDevice === undefined) {
      setErrorCode(`FFI00${dateChanged}-995`);
      setManagement("error");
      checkoutError('-995', 'Impressora');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [available, chooseDevice])

  useEffect(() => {
    if (available === 2) {
      console.log(stop, paper)
      if (stop === '0' && paper === '0') {
        if (printer === undefined) {
          console.log(printerSecondWay);
          for (let i: any = 0; i < printerSecondWay.length; i++) {
            setTimeout(() => {
              writeToSelectedPrinter(printerSecondWay[i]);
            }, 1000);
          }
        } else {
          let printerInfo: any = printer.info;
          let printerZpl: any = printer.codeZpl;
          for (let i: any = 0; i < printerInfo.length; i++) {
            setTimeout(() => {
              writeToSelectedPrinter(printerZpl.replaceAll("{ACCESS_CODE}", `${printerInfo[i].accessCode}`).replaceAll("{PRODUCT_NAME}", `${printerInfo[i].productName}`).replaceAll("{PRODUCT_RANGE}", `${printerInfo[i].productRange}`));
            }, 1000);
          }
        }
      } else {
        setManagement("error");
        checkoutError('-994', 'Impressora');
        setErrorCode(`FFI00${dateChanged}-994`); // 94 - Impressora sem papel ou pausada
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [available])

  /* Mensagem do quantitativo de impressão */
  useEffect(() => {
    if (available === 2) {
      const wait = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

      const printTime = async () => {
        //var aux: any = "imprimindo 1 de 4";

        if (printer === undefined) {
          for (var i: any = 0; i < printerSecondWay.length; i++) {
            setMessagePrint("Imprimindo " + (i + 1) + " de " + printerSecondWay.length);
            await wait(1500);

            if ((i + 1) === printerSecondWay.length) {
              approved();
            }
          }
        } else {
          for (var j: any = 0; j < printer.info.length; j++) {
            setMessagePrint("Imprimindo " + (j + 1) + " de " + printer.info.length);
            await wait(1500);

            if ((j + 1) === printer.info.length) {
              approved();
            }
          }
        }
      };

      printTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [available])
  /* END - Mensagem do quantitativo de impressão */

  const coupom = useCallback(async (receive, print, printEstab) => {
    //setReprintTickets(false);

    try {
      const { data } = await api.post(`http://localhost:5000/api/impressao/bilheteria/recibo`, {
        "ConteudoRecibo": receive,
        "ConteudoReciboSITEFCli": print,
        "ConteudoReciboSITEFEst": printEstab
      });
      if (data?.statusCode !== 400) {
        console.log(data)
      } else {
        setManagement("error");
        //setReprintTickets(true);
      }
    } catch (e) {
      setManagement("error");
      //setReprintTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (printer?.comprovanteBilheteria !== undefined) {
      coupom(printer?.comprovanteBilheteria, printer?.comprovanteSitef, printer?.comprovanteEstabelecimento);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrint])

  return (
    <>
      {
        management === "imprimindo"
          ?
          <div className="d-flex flex-column align-items-center justify-content-center modal-checkout">
            <div style={{ overflow: "hidden", height: "155px" }}>
              <img className="printer-icon" src={iconPrintingTickets} alt="icon not approved" style={{ width: "5.5rem" }} />
            </div>
            <h4 className="my-4 text-primary">Imprimindo ingressos!</h4>
            <p style={{ fontWeight: 600, width: "80%", textAlign: "center" }}>{messagePrint}</p>
          </div>
          :
          management === "error"
            ?
            <div className="d-flex justify-content-evenly flex-column text-center modal-checkout">
              <div>
                <FontAwesomeIcon
                  icon={["fal", "times-circle"]}
                  size="1x"
                  style={{ fontSize: "6rem" }}
                  className="color-error"
                />
              </div>
              <div>
                <h4 className="text-error">Desculpe algo deu errado!</h4>
                <p className="mb-2">Por favor, entre em contato com nossa equipe de suporte.</p>
                <p className="mb-0">{errorCode}</p>

                <div className="d-flex justify-content-center">
                  {/*                   <Button className="btn btn-error-outline-primary"
                    onClick={() => { setIsPrint(!isPrint); setManagement('imprimindo')}}
                  >
                    Reimprimir Pulseira
                  </Button>
                  {
                    reprintTickets === true
                      ?
                      <Button className="btn btn-error-outline-primary"
                        onClick={() => {
                          coupom(printer?.comprovanteBilheteria, printer?.comprovanteSitef, printer?.comprovanteEstabelecimento);
                          setManagement('receive');
                        }}
                      >
                        Reimprimir Recibo
                      </Button>
                      :
                      <></>
                  } */}
                  <Button className="btn btn-error-outline-primary mt-3"
                    onClick={() => { setIsPrint(!isPrint); setManagement('imprimindo') }}
                  >
                    Imprimir Novamente
                  </Button>
                </div>

              </div>
              <div className="d-flex justify-content-center">
                <Button className="btn-default text-white" onClick={() => setModalShow(false)}>
                  Ok
                </Button>
              </div>
            </div>
            :
            management === "success"
              ?
              <div className="d-flex justify-content-evenly flex-column text-center modal-checkout">
                <div>
                  <FontAwesomeIcon
                    icon={["fal", "check-circle"]}
                    size="1x"
                    style={{ fontSize: "6rem" }}
                    className="color-success"
                  />
                </div>
                <div>
                  {
                    printerSecondWay !== undefined
                      ?
                      <h4 className="text-success">Reimpressão realizada com sucesso!</h4>
                      :
                      <>
                        <h4 className="text-success">Compra realizada com sucesso!</h4>
                        {
                          bookLocFinal !== undefined && bookLocFinal !== null && bookLocFinal !== ""
                            ?
                            <div className="py-3">
                              <p className="mb-0"><b>Número do Pedido:</b> {bookLocFinal}</p>
                              {/* <p className="mb-0"><b>Código para Reimpressão:</b> {tokenCode}</p> */}
                            </div>
                            :
                            ""
                        }
                      </>
                  }

                  {/* <h6>Por favor, entre em contato com nossa equipe de suporte.</h6> */}
                </div>
                <div className="d-flex justify-content-center">
                  <Button className="btn-default" onClick={Success}>
                    Ok
                  </Button>
                </div>
              </div>
              :
              management === "receive"
                ?
                <div className="d-flex flex-column align-items-center modal-checkout">
                  <div style={{ overflow: "hidden", height: "240px" }}>
                    <img className="printer-icon" src={iconPrintingTickets} alt="icon not approved" style={{ width: "8rem" }} />
                  </div>
                  <h4 className="my-4 text-primary">Imprimindo recibos!</h4>
                  <p style={{ fontWeight: 600, width: "80%", textAlign: "center" }}>Imprimindo 1 de 1</p>
                </div>
                :
                <></>
      }
    </>
  )
});

export default InstructionsPrint;
