/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import { Key, useEffect, useState } from "react";
import api from "../../../services/api";
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addItemCart, removeItemCart, updateDadosCart, updateItemCart } from "../../../store/cart";

import { useTranslation } from "react-i18next";

import './ProductTour.scss';
//import ModalAlert from "../../../../components/Modal/ModalAlert/ModalAlert";
//import ModalAlertCarioca from "../../../../components/Modal/ModalAlertCarioca/ModalAlertCarioca";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
//import SimpleAlert from "../../../../components/Alert/SimpleAlert/SimpleAlert";
//import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import DoubleCalendar from "../../Calendar/DoubleCalendar";
import ModalDefault from "../../Modal/ModalDefault/ModalDefault";
import SimpleAlert from "../../Alert/SimpleAlert/SimpleAlert";
import { parseCookies } from "nookies";
import DropdownHours from "../../DropdownHours/DropdownHours";
import GetPickups from "../../C2Points/GetPickups";

let error = true;

export interface props {
    tourResponse: any;
    lng: any;
    setModalShow: any;
    edit: any;
    editInfo: any;
    editIndex: any;
}

const ProductTour: React.FC<props> = ({ tourResponse, lng, setModalShow, edit, editInfo, editIndex }: props) => {
    const { t } = useTranslation();
    let idChannel: number = 1;

    const cartPDV = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    const [allowCalendar, setAllowCalendar] = useState<any>(false);
    const [dateTour, setDateTour] = useState<any>([]);
    const [chosenDate, setChosenDate] = useState<any>();
    const [stayOnPage, setStayOnPage] = useState<any>(false);

    const [showCalendar, setShowCalendar] = useState<any>(false);
    const [showDropdown, setShowDropdown] = useState<any>(false);
    const [showPickup, setShowPickup] = useState<any>(false);
    const [showDropdownHours, setShowDropdownHours] = useState<any>(false);
    const [peopleDisponible, setPeopleDisponible] = useState<boolean>(false);
    const [showDisponible, setShowDisponible] = useState<any>(false);

    const [modalShowCarioca, setModalShowCarioca] = useState<any>(false);
    const [modalShowDefault, setModalShowDefault] = useState<any>(false);

    const [messageValidation, setMessageValidation] = useState<any>(null);

    const [chooseIndex, setChooseIndex] = useState<any>(null);

    const [numberAdults, setNumberAdults] = useState<number>(0);
    const [numberChilds, setNumberChilds] = useState<number>(0);
    const [numberInfants, setNumberInfants] = useState<number>(0);
    const [numberStudent, setNumberStudent] = useState<number>(0);
    const [numberElders, setNumberElders] = useState<number>(0);
    const [numberGlobal, setNumberGlobal] = useState<number>(0);

    const [numberTotal, setNumberTotal] = useState<number>(0);
    const [numberStockTotal, setNumberStockTotal] = useState<number>(1);

    const [modalityNumber, setModalityNumber] = useState<any>(tourResponse.modalities.length === 1 ? 0 : null);
    const [price, setPrice] = useState<any>({
        idSellingType: null,
        adult: 0,
        child: 0,
        infant: 0,
        student: 0,
        elders: 0,
        global: 0,
        idTarif: null
    });

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [chooseModality, setChooseModality] = useState<string>('');

    const [repeat, setRepeat] = useState<boolean>(false);

    const [ranges, setRanges] = useState<any>(null);
    const [newGetDate, setNewGetDate] = useState<any>(null);


    const [pickupSelect, setPickupSelect] = useState<any>(null);

    const maxNumPeople = 99999999;

    const dispatch = useAppDispatch();

    useEffect(() => {
        var aux: any = [];
        for (var i = 0; i < tourResponse.modalities.length; i++) {
            aux.push({
                adult: 0,
                child: 0,
                infant: 0,
            })
        }
        setDateTour(aux);
    }, [tourResponse.modalities])

    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    useEffect(() => {
        if (tourResponse.productCode === "PQF0619") {
            setMessageValidation(`${t("slowBuy.messageVal01")}`);
            setModalShowCarioca(true);
        } else {
            setModalShowCarioca(false);
        }
    }, [])

    useEffect(() => {
        if (showDisponible === false) {
            setStayOnPage(false);
        }
    }, [showDisponible])

    const dateToday = day + '/' + month + '/' + year;

    const dateNow: any = new Date().toLocaleString().split(" ")[1].split(":").join("");

    const getValueOfDay = async (indexMod: any, first: any) => {
        var today: any;
        var todayDay: any;
        var todayMonth: any;
        var todayYear: any;
        var endMonth: any;
        var endYear: any;

        if (newGetDate === null) {
            today = new Date();
            todayDay = today.getDate();
            todayMonth = (today.getMonth() + 1);
            todayYear = today.getFullYear();
            endMonth = (today.getMonth() + 3);
            endYear = today.getFullYear();

            if (endMonth > 11) {
                endMonth = endMonth - 11;
                endYear = parseInt(endYear) + 1;
            }
        } else {
            today = new Date();
            todayDay = today.getDate();
            todayMonth = newGetDate - 1;
            todayYear = today.getFullYear();
            endMonth = newGetDate + 3;
            endYear = today.getFullYear();

            if (todayMonth > 12) {
                todayMonth = todayMonth - 12;
                todayYear = parseInt(todayYear) + 1;
            }
            if (endMonth > 12) {
                endMonth = endMonth - 12;
                endYear = parseInt(endYear) + 1;
            }
        }

        todayDay = todayDay >= 28 ? 28 : todayDay;

        const cookies = parseCookies();
        const token = cookies.authSupervisor;
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const { data } = await api.post(
                `${process.env.REACT_APP_SERVER_URL_API}/Products/GetCalendarTourAsync`,
                {
                    dateStart: todayYear + '-' + todayMonth + '-' + todayDay,
                    dateEnd: endYear + '-' + endMonth + '-' + todayDay,
                    productCode: tourResponse.productCode,
                    tarCode: tourResponse.modalities[indexMod].tarif.tarCode,
                    ProdModCode: tourResponse.modalities[indexMod].prodModCode,
                    lang: "BR",
                    idCanal: idChannel
                }
            );

            if (data.status !== 400) {
                setRanges(data.data.ranges);

                if (data.data.dates.length === 0 || data.statusCode === 204) { //Poduto indisponível para hoje
                    if (first !== 1) {
                        setStayOnPage(true);
                        setShowDisponible(true);
                        setMessageValidation(`${t("products.messageVal02")}`);
                    }
                    //setAllowCalendar(true);
                } else if (data.data.dates.length !== 0 && Number((data.data.dates[0].limitToBuy).split("T")[1].split(":").join("")) <= Number(dateNow)) {
                    if (first !== 1) {
                        setStayOnPage(true);
                        setShowDisponible(true);
                        setMessageValidation(`${t("products.messageVal02")}`);
                    }
                    //setAllowCalendar(true);
                } else {
                    error = false;
                }
            }
        } catch (error: any) {

        } finally {
            setTimeout(() => {
                if (error === true) {
                    setStayOnPage(true);
                    setShowDisponible(true);
                    setMessageValidation(`${t("products.messageVal02")}`);
                }
            }, 500);
        }
    }

    const config = {
        headers: { "ngrok-skip-browser-warning": "69420" },
    };

    async function getIdChannel(indexMod: any, first: any) {
        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/bilheteria`, config);
            if (data.statusCode === 200) {
                idChannel = data.data.data;
                getValueOfDay(indexMod, first);
            }
        } catch (error) { }
    }

    const handleChangeModalities = (value: any, first: any) => {
        setShowCalendar(false);
        setShowPickup(false);
        setShowDropdown(false);
        setShowDropdownHours(false);

        if (first === 0) {
            setModalityNumber(value);
            getIdChannel(value, first);

        } else {
            getIdChannel(value, first);
        }

        let timer1 = setTimeout(() => setShowCalendar(true), 100);

        return () => {
            clearTimeout(timer1);
        };
    }

    /* useEffect(() => {
        //console.log(tourResponse.modalities)
        if (modalityNumber === null) {
            handleChangeModalities(0, 1);
            handleChangeModalities(1, 1);
        }
    }, []) */

    useEffect(() => {
        if (tourResponse.modalities.length === 1) {
            getIdChannel(0, '');
        }
    }, [])

    const handleClickAdults = (controll: string) => {
        let newNumber = numberAdults;
        if (controll === "sub") {
            if (numberAdults === 0) {
                return false;
            } else {
                newNumber = numberAdults - ranges?.incrementAdult;
                if (ranges.flagStockAdult === 1) {
                    setNumberStockTotal(numberStockTotal - ranges?.incrementAdult);
                }
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockAdult === 1) {
                if (numberStockTotal < dateTour[modalityNumber].maxStockNumber) {
                    newNumber = numberAdults + ranges?.incrementAdult;
                    setNumberStockTotal(numberStockTotal + ranges?.incrementAdult);
                } else {
                    return false;
                }
            } else {
                newNumber = numberAdults + ranges?.incrementAdult;
            }
        }
        return setNumberAdults(newNumber);
    };

    const handleClickChilds = (controll: string) => {
        let newNumber = numberChilds;
        if (controll === "sub") {
            if (numberChilds === 0) {
                return false;
            } else {
                newNumber = numberChilds - ranges?.incrementChild;
                if (ranges.flagStockChild === 1) {
                    setNumberStockTotal(numberStockTotal - ranges?.incrementChild);
                }
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockAdult === 1) {
                if (numberStockTotal < dateTour[modalityNumber].maxStockNumber) {
                    newNumber = numberChilds + ranges?.incrementChild;
                    setNumberStockTotal(numberStockTotal + ranges?.incrementChild);
                } else {
                    return false;
                }
            } else {
                newNumber = numberChilds + ranges?.incrementChild;
            }
        }
        return setNumberChilds(newNumber);
    };

    const handleClickInfants = (controll: string) => {
        let newNumber = numberChilds;
        if (controll === "sub") {
            if (numberInfants === 0) {
                return false;
            } else {
                newNumber = numberInfants - ranges?.incrementInfant;
                if (ranges.flagStockInfant === 1) {
                    setNumberStockTotal(numberStockTotal - ranges?.incrementInfant);
                }
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockInfant === 1) {
                if (numberStockTotal < dateTour[modalityNumber].maxStockNumber) {
                    newNumber = numberInfants + ranges?.incrementInfant;
                    setNumberStockTotal(numberStockTotal + ranges?.incrementInfant);
                } else {
                    return false;
                }
            } else {
                newNumber = numberInfants + ranges?.incrementInfant;
            }
        }
        return setNumberInfants(newNumber);
    };

    const handleClickStudent = (controll: string) => {
        let newNumber = numberStudent;
        if (controll === "sub") {
            if (numberStudent === 0) {
                return false;
            } else {
                newNumber = numberStudent - ranges?.incrementStudent;
                if (ranges.flagStockStudent === 1) {
                    setNumberStockTotal(numberStockTotal - ranges?.incrementStudent);
                }
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockStudent === 1) {
                if (numberStockTotal < dateTour[modalityNumber].maxStockNumber) {
                    newNumber = numberStudent + ranges?.incrementStudent;
                    setNumberStockTotal(numberStockTotal + ranges?.incrementStudent);
                } else {
                    return false;
                }
            } else {
                newNumber = numberStudent + ranges?.incrementStudent;
            }
        }
        return setNumberStudent(newNumber);
    };

    const handleClickElders = (controll: string) => {
        let newNumber = numberElders;
        if (controll === "sub") {
            if (numberElders === 0) {
                return false;
            } else {
                newNumber = numberElders - ranges?.incrementElders;
                if (ranges.flagStockStudent === 1) {
                    setNumberStockTotal(numberStockTotal - ranges?.incrementElders);
                }
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockElders === 1) {
                if (numberStockTotal < dateTour[modalityNumber].maxStockNumber) {
                    newNumber = numberElders + ranges?.incrementElders;
                    setNumberStockTotal(numberStockTotal + ranges?.incrementElders);
                } else {
                    return false;
                }
            } else {
                newNumber = numberElders + ranges?.incrementElders;
            }
        }
        return setNumberElders(newNumber);
    };

    const handleClickGlobal = (controll: string) => {
        let newNumber = numberGlobal;
        if (controll === "sub") {
            if (numberGlobal === 0) {
                return false;
            } else {
                newNumber = numberGlobal - ranges?.incrementPrivate;
                if (ranges.flagStockPrivate === 1) {
                    setNumberStockTotal(numberStockTotal - ranges?.incrementPrivate);
                }
            }
        }
        if (controll === "plus") {
            if (ranges.flagStockPrivate === 1) {
                if (numberStockTotal < dateTour[modalityNumber].maxStockNumber) {
                    newNumber = numberGlobal + ranges?.incrementPrivate;
                    setNumberStockTotal(numberStockTotal + ranges?.incrementPrivate);
                } else {
                    return false;
                }
            } else {
                newNumber = numberGlobal + ranges?.incrementPrivate;
            }
        }
        return setNumberGlobal(newNumber);
    };

    useEffect(() => {
        if (tourResponse.type === "PRIVATIVO") {
            setNumberTotal(numberGlobal);
        } else {
            setNumberTotal(numberAdults + numberChilds + numberInfants);
        }
    }, [numberAdults, numberChilds, numberInfants, numberGlobal]);

    const addToCart = (itemOption: any) => {
        var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

        cartPDV.dados.forEach((elem: any) => {
            if (elem.productCode === tourResponse.productCode && elem.allowEdit === false) {
                repeatedItem = true
            } else if (elem.productCode === tourResponse.productCode && elem.allowEdit === true) {
                repeatedItem = false;
                remove();
            }
        });

        console.log(repeatedItem)
        if (repeatedItem === true && edit === false) {
            setModalShowDefault(true);
            setMessageValidation(`${t("slowBuy.messageVal03")}`);
        } else {
            if (itemOption.childs !== 0) {
                setMessageValidation(`${t("slowBuy.messageVal04")}`)
                setModalShowDefault(true);
            } else {
                /* if (tourResponse.productCode !== 'PQF0619') {
                    window.location.href = "/carrinho";
                } else {
                    setMessageValidation("Necessária a apresentação de comprovante de residência e documento com foto para moradores e nascidos do Rio e Grande Rio. Mantenha a documentação em mãos.");
                    setModalShow(true);
                    window.location.href = "/carrinho";
                } */
            }

            const timestamp = new Date().getTime();

            const item = {
                controlCart: timestamp,
                productName: itemOption.productName,
                modalityName: itemOption.modalityName,
                modalityIndex: itemOption.modalityIndex,
                imgCart: tourResponse.images[0],
                imagesBaseUrl: tourResponse.imagesBaseUrl,
                typeProduct: tourResponse.typeProduct,
                price: itemOption?.tarif?.price,

                idTarif: itemOption.idTarif,
                idPickup: itemOption.idPickup,

                priceAdults: itemOption.priceAdults,
                priceChilds: itemOption.priceChilds,
                priceInfants: itemOption.priceInfants,
                priceElders: 0,
                priceStudent: 0,
                priceGlobalPeople: itemOption.priceGlobalPeople,

                ranges: ranges,

                slug: window.location.href.split('compra-rapida/')[1],
                sellingType: itemOption.idSellingType,
                adults: itemOption.adults,
                childs: itemOption.childs,
                infants: itemOption.infants,
                elders: itemOption.elders,
                student: itemOption.student,
                globalPeople: itemOption.globalPeople,
                totalPeople: itemOption.totalPeople,
                productType: 1,
                productCode: tourResponse.productCode,
                time: itemOption.time,
                date: itemOption.date,
                supplier: tourResponse.supplier,
                supplierFantasyName: tourResponse.supplierFantasyName,
                typePickup: itemOption.embarkingType,
                meetingPoint: itemOption.embarkingType === "0" ? itemOption.meetingPoint : '',
                pickupListId: itemOption.embarkingType === "0" ? 0 : itemOption.pickupListId,
                pickup: pickupSelect !== null ? pickupSelect?.id === undefined ? editInfo.pickupInfo.id : pickupSelect?.id : 0,
                pickupInfo: pickupSelect === null ? editInfo.pickupInfo : pickupSelect,
                productSlug: tourResponse.slugBR,

                discount: 0,
                customValueNet: 0,
                customValueSell: 0,
                goingSource: "null",
                commingSource: "null",
                latOrigem: "null",
                lngOrigem: "null",
                latDestino: "null",
                lngDestino: "null",
                cia: "null",
                voo: "null",
                smallSuitcase: 0,
                bigSuitcase: 0,
                internalNotes: " ",
                idVeiculo: 0,
                passengers: [],

                allowEdit: false,

                maxStockNumber: dateTour[modalityNumber].maxStockNumber,
                dateTour: dateTour[modalityNumber]
            }

            if (edit !== true) {
                dispatch(addItemCart(item));
                setModalShow(false);
            } else {
                dispatch(updateItemCart({ item: item, index: editIndex }));
                setModalShow(false);
            }
        }
    }

    const auth = (option: any) => {
        if (numberTotal === 0 || price.idTarif === null || (chosenDate === undefined && allowCalendar === true)) {
            setModalShowDefault(true);
            setMessageValidation(`${t("slowBuy.messageVal05")}`);
        } else {
            option.productName = tourResponse.productName;
            option.modalityName = tourResponse.modalities[modalityNumber]?.modalityName;
            option.modalityIndex = modalityNumber;
            option.imagesBaseUrl = tourResponse.imagesBaseUrl;

            option.adults = numberAdults;
            option.childs = numberChilds;
            option.infants = numberInfants;
            option.elders = numberElders;
            option.student = numberStudent;
            option.globalPeople = price.idSellingType === 1 ? 1 : numberTotal;
            option.totalPeople = price.idSellingType === 1 ? numberTotal : undefined;

            option.date = dateTour[Number(modalityNumber)]?.data === undefined ? editInfo.dateTour.data : dateTour[Number(modalityNumber)]?.data?.split('/').reverse().join('-');
            option.time = dateTour[modalityNumber]?.time;

            option.idTarif = price.idTarif;

            option.priceAdults = price.adult;
            option.priceChilds = price.child;
            option.priceInfants = price.infant;
            option.priceElders = price.elders;
            option.priceStudent = price.student;
            option.priceGlobalPeople = price.global;
            option.idSellingType = price.idSellingType === undefined ? editInfo.dateTour.idSellingType : price.idSellingType;

            option.pickupListId = tourResponse.modalities[modalityNumber].pickupListId;
            option.embarkingType = tourResponse.modalities[modalityNumber].embarkingType;
            option.meetingPoint = tourResponse.modalities[modalityNumber].meetingPoint;

            if (option.date !== "") {
                addToCart(option);
            } else {
                setModalShowDefault(true);
                setMessageValidation(`${t("slowBuy.messageVal05")}`);
            }
        }
    }

    const remove = () => {
        dispatch(removeItemCart(tourResponse));
    }

    function changePriceDateTour(obj: any) {
        setChosenDate(obj[0].data)
        setDateTour(obj);
        setShowDropdown(true);

        setPrice(obj[modalityNumber]);

        if (edit !== true) {
            setNumberAdults(0);
            setNumberChilds(0);
            setNumberInfants(0);
            setNumberStudent(0);
            setNumberElders(0);
            setNumberGlobal(0);
            setNumberStockTotal(0)
            setNumberTotal(0);
            //setNumberTotalPeople(0);
        }

        let timer1 = setTimeout(() => setShowDisponible(true), 100);

        return () => {
            clearTimeout(timer1);
        };
    }

    /* Trocar Label para Adicionado */
    useEffect(() => {
        setRepeat(false);

        cartPDV.dados.forEach((elem: any) => {

            if (elem.productCode === tourResponse.productCode) {
                setRepeat(true);

                if (elem.sellingType === 2) {
                    setNumberAdults(elem.adults);
                    setNumberChilds(elem.childs);
                    setNumberInfants(elem.infants);
                    setNumberElders(elem.elders);
                    setNumberStudent(elem.student);
                    setNumberTotal(elem.globalPeople);
                } else {
                    setNumberGlobal(elem.totalPeople);
                    setNumberTotal(elem.totalPeople);
                }
            }
        });
    }, [edit]);
    /* END - Trocar Label para Adicionado */

    const handleAdults = (e: any) => {
        console.log(Number(e.target.value));
        if (Number(e.target.value) <= (maxNumPeople - (numberChilds + numberStudent + numberInfants + numberElders))) {
            setNumberAdults(Number(e.target.value));
        } else {
            setNumberAdults(maxNumPeople - (numberChilds + numberStudent + numberInfants + numberElders));
        }
    }

    const handleChilds = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - (numberAdults + numberStudent + numberInfants + numberElders))) {
            setNumberChilds(Number(e.target.value));
        } else {
            setNumberChilds(maxNumPeople - (numberAdults + numberStudent + numberInfants + numberElders));
        }
    }

    const handleStudent = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - (numberAdults + numberChilds + numberInfants + numberElders))) {
            setNumberStudent(Number(e.target.value));
        } else {
            setNumberStudent(maxNumPeople - (numberAdults + numberChilds + numberInfants + numberElders));
        }
    }

    const handleElders = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - (numberAdults + numberChilds + numberInfants + numberStudent))) {
            setNumberElders(Number(e.target.value));
        } else {
            setNumberElders(maxNumPeople - (numberAdults + numberChilds + numberInfants + numberStudent));
        }
    }

    const handleInfants = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - (numberAdults + numberChilds + numberElders + numberStudent))) {
            setNumberInfants(Number(e.target.value));
        } else {
            setNumberInfants(maxNumPeople - (numberAdults + numberChilds + numberElders + numberStudent));
        }
    }

    const handleGlobal = (e: any) => {
        if (Number(e.target.value) <= (maxNumPeople - numberGlobal)) {
            setNumberGlobal(Number(e.target.value));
        } else {
            setNumberGlobal(maxNumPeople - numberGlobal);
        }
    }

    const init_area = (event: any) => {
        event.target.removeAttribute("readonly");
    }

    const addPickup = (objPickup: any) => {
        setPickupSelect(objPickup);


        /* cartPDV.dados[modalityNumber].pickup = objPickup.id;
        cartPDV.dados[modalityNumber].pickupInfo = objPickup;
        cartPDV.dados[modalityNumber].meetingPoint = `${objPickup.name} - ${objPickup.address}`; */

        //dispatch(updateDadosCart(cartPDV.dados));
        setShowDropdownHours(true);
    };

    useEffect(() => {
        if (edit !== true) {
            setNumberAdults(0);
            setNumberChilds(0);
            setNumberInfants(0);
            setNumberStudent(0);
            setNumberElders(0);
            setNumberGlobal(0);
            setNumberStockTotal(0)
            setNumberTotal(0);
            //setNumberTotalPeople(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTour[modalityNumber]?.data])

    useEffect(() => {
        if (edit === true) {
            if(tourResponse.modalities.length > 1) {
                handleChangeModalities(Number(editInfo.modalityIndex), 0);
            }

            setShowCalendar(true);
            setShowPickup(true);
            setShowDropdown(true);
            setShowDropdownHours(true);
            setPeopleDisponible(true);

            setNumberTotal(editInfo.adults + editInfo.childs + (editInfo.infants || 0) + (editInfo.student || 0) + (editInfo.elders || 0) + (editInfo.totalPeople || 0))

            setNumberStockTotal((editInfo.ranges.flagStockAdult === 1 ? editInfo.adults : 0) + (editInfo.ranges.flagStockChild === 1 ? editInfo.childs : 0) + (editInfo.ranges.flagStockInfant === 1 ? editInfo.infants : 0) + (editInfo.ranges.flagStockStudent === 1 ? editInfo.student : 0) + (editInfo.ranges.flagStockElders === 1 ? editInfo.elders : 0) + (editInfo.ranges.flagStockPrivate === 1 ? editInfo.sellingType === 1 ? editInfo.totalPeople : 0 : 0))
        }
    }, [edit])

    return (
        <>
            <div>
                <div className='content single-product'>
                    {/* {
                            showDisponible === false
                                ? */}
                    <div className="d-flex flex-column">
                        <div className='' style={{ minHeight: "630px" }}>
                            {tourResponse.modalities.length > 1 ?
                                <>
                                    <div className='step'>
                                        <h3 className="text-primary step-choose">
                                            {t("products.choose")}
                                        </h3>
                                        <div className="line-step-choose">
                                            <select
                                                onChange={(e: any) => { handleChangeModalities(e.target.value, 0) }}
                                                className="form-control rounded-0 bg-white py-2 rounded modality-input"
                                                name="modalities"
                                                id="modalities"
                                                defaultValue={editInfo.modalityIndex || ''}
                                            >
                                                <option value='' disabled selected>{t("products.select")}</option>
                                                {tourResponse.modalities.map((item: any, index: Key) => {
                                                    return (
                                                        <option key={index} value={index}>{item.modalityName}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                </>
                            }

                            {
                                showCalendar === true || tourResponse.modalities.length <= 1
                                    ?
                                    <div className='step'>
                                        <h3 className="text-primary step-choose">
                                            {t("products.date")}
                                        </h3>
                                        <div className="line-step-choose">
                                            {/* {
                                                    allowCalendar === true
                                                        ?
                                                        <> */}
                                            <DoubleCalendar
                                                modalityID={modalityNumber}
                                                product={tourResponse.modalities[modalityNumber]}
                                                productCode={tourResponse.productCode}
                                                index={modalityNumber}
                                                dateTour={dateTour}
                                                changePriceDateTour={changePriceDateTour}
                                                setPrice={setPrice}
                                                isTour={true}
                                                setShowPickup={setShowPickup}
                                                setShowDropdownHours={setShowDropdownHours}
                                                edit={edit}
                                                editInfo={editInfo}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }

                            {
                                showPickup === true
                                    ?
                                    <div className='step'>
                                        <h3 className="text-primary step-choose">
                                            Ponto de Encontro
                                        </h3>
                                        <div className="line-step-choose" style={{ display: "block" }}>
                                            {
                                                tourResponse.modalities[modalityNumber].embarkingType === "0"
                                                    ?
                                                    <div style={{ position: "relative" }}>
                                                        <FontAwesomeIcon
                                                            icon={["fal", "map-marker-alt"]}
                                                            size="1x"
                                                            style={{ color: "#000", position: "absolute", transform: "translate(4px, 2px)", fontSize: "22px" }}
                                                        />
                                                        <p className="meeting-point-text mb-2">
                                                            {tourResponse.modalities[modalityNumber].meetingPoint}
                                                        </p>
                                                    </div>
                                                    :
                                                    <div style={{ position: "relative" }}>
                                                        <GetPickups
                                                            propsId={tourResponse.modalities[modalityNumber].pickupListId}
                                                            actionPickup={addPickup}
                                                            alert={alert}
                                                            edit={edit}
                                                            editInfo={editInfo}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }

                            {
                                showDropdownHours === true
                                    ?
                                    <div className='step' style={{ position: "relative" }}>
                                        <h3 className="text-primary step-choose">
                                            Escolha o horário
                                        </h3>
                                        <div className="panel-dropdown bg-white line-step-choose" style={{ height: "48px" }}>
                                            <DropdownHours
                                                changePriceDateTour={changePriceDateTour}
                                                dateTour={dateTour}
                                                index={modalityNumber}
                                                setPeopleDisponible={setPeopleDisponible}
                                                edit={edit}
                                                editInfo={editInfo}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            {/* // STEP 1 */}

                            {/* // STEP 2 */}

                            {peopleDisponible === true
                                ?
                                <>
                                    {
                                        repeat === false || edit === true
                                            ?
                                            ranges !== null && showDropdown === true
                                                ?
                                                <div className='step'>
                                                    <h3 className="text-primary step-choose">
                                                        <strong>{tourResponse.modalities.length === 1 ? 'Passo 01' : 'Passo 02'}:</strong> {t("products.selectTickets")}
                                                    </h3>
                                                    <div className="prices-modal">
                                                        {
                                                            ranges?.isActiveAdult === true
                                                                ?
                                                                <div className="qtyButtons">
                                                                    <div className="flex-ajuste">
                                                                        <label>
                                                                            {ranges?.labelAdult}
                                                                        </label>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <button className="qtyDec" onClick={() => handleClickAdults("sub")}>
                                                                            <span>-</span>
                                                                        </button>
                                                                        <input
                                                                            type="text"
                                                                            name="qtyInput"
                                                                            value={numberAdults}
                                                                            //onChange={(e: any) => handleAdults(e)}
                                                                            id="numero-adulto"
                                                                            readOnly onClick={(e: any) => { init_area(e) }}
                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        />
                                                                        {/* <span
                                                                                        id="numero-adulto"
                                                                                    >{numberAdults}
                                                                                    </span> */}
                                                                        <button className="qtyInc" onClick={() => handleClickAdults("plus")}>
                                                                            <span>+</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="price">
                                                                        <span
                                                                            className="price-dropdown font-weight-bold w-100"
                                                                            id="valor-adulto-sm"
                                                                        >
                                                                            R$ {(price?.adult).toFixed(2).split(".").join(",")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            ranges?.isActiveChild === true
                                                                ?
                                                                <div className="qtyButtons">
                                                                    <div className="flex-ajuste">

                                                                        <label>
                                                                            {ranges?.labelChild}
                                                                        </label>
                                                                        <small id="idade-crianca">
                                                                            ({t("products.ticket.years01")})
                                                                        </small>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <button className="qtyDec" onClick={() => handleClickChilds("sub")}>
                                                                            <span>-</span>
                                                                        </button>
                                                                        <input
                                                                            type="text"
                                                                            name="qtyInput"
                                                                            value={numberChilds}
                                                                            //onChange={(e: any) => handleChilds(e)}
                                                                            id="numero-crianca"
                                                                            readOnly onClick={(e: any) => { init_area(e) }}
                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        />
                                                                        <button className="qtyInc" onClick={() => handleClickChilds("plus")}>
                                                                            <span>+</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="price">
                                                                        <span
                                                                            className="price-dropdown font-weight-bold w-100"
                                                                            id="valor-crianca-sm"
                                                                        >
                                                                            {" "}
                                                                            R$ {(price?.child).toFixed(2).split(".").join(",")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            ranges?.isActiveElders === true
                                                                ?
                                                                <div className="qtyButtons">
                                                                    <div className="flex-ajuste">

                                                                        <label>
                                                                            {ranges?.labelElders}
                                                                        </label>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <button className="qtyDec" onClick={() => handleClickElders("sub")}>
                                                                            <span>-</span>
                                                                        </button>
                                                                        <input
                                                                            type="text"
                                                                            name="qtyInput"
                                                                            value={numberElders}
                                                                            //onChange={(e: any) => handleElders(e)}
                                                                            id="numero-crianca"
                                                                            readOnly onClick={(e: any) => { init_area(e) }}
                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        />
                                                                        {/* <span
                                                                                                id="numero-crianca"
                                                                                            >{numberChilds}
                                                                                            </span> */}
                                                                        <button className="qtyInc" onClick={() => handleClickElders("plus")}>
                                                                            <span>+</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="price">
                                                                        <span
                                                                            className="price-dropdown font-weight-bold w-100"
                                                                            id="valor-crianca-sm"
                                                                        >
                                                                            {" "}
                                                                            R$ {(price?.elders).toFixed(2).split(".").join(",")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            ranges?.isActiveInfant === true
                                                                ?
                                                                <div className="qtyButtons">
                                                                    <div className="flex-ajuste">

                                                                        <label>
                                                                            {ranges?.labelInfant}
                                                                        </label>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <button className="qtyDec" onClick={() => handleClickInfants("sub")}>
                                                                            <span>-</span>
                                                                        </button>
                                                                        <input
                                                                            type="text"
                                                                            name="qtyInput"
                                                                            value={numberInfants}
                                                                            //onChange={(e: any) => handleInfants(e)}
                                                                            id="numero-crianca"
                                                                            readOnly onClick={(e: any) => { init_area(e) }}
                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        />
                                                                        {/* <span
                                                                                                id="numero-crianca"
                                                                                            >{numberChilds}
                                                                                            </span> */}
                                                                        <button className="qtyInc" onClick={() => handleClickInfants("plus")}>
                                                                            <span>+</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="price">
                                                                        <span
                                                                            className="price-dropdown font-weight-bold w-100"
                                                                            id="valor-crianca-sm"
                                                                        >
                                                                            {" "}
                                                                            R$ {(price?.infant).toFixed(2).split(".").join(",")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            ranges?.isActiveStudent === true
                                                                ?
                                                                <div className="qtyButtons">
                                                                    <div className="flex-ajuste">

                                                                        <label>
                                                                            {ranges?.labelStudent}
                                                                        </label>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <button className="qtyDec" onClick={() => handleClickStudent("sub")}>
                                                                            <span>-</span>
                                                                        </button>
                                                                        <input
                                                                            type="text"
                                                                            name="qtyInput"
                                                                            value={numberStudent}
                                                                            //onChange={(e: any) => handleStudent(e)}
                                                                            id="numero-crianca"
                                                                            readOnly onClick={(e: any) => { init_area(e) }}
                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        />
                                                                        {/* <span
                                                                                                id="numero-crianca"
                                                                                            >{numberChilds}
                                                                                            </span> */}
                                                                        <button className="qtyInc" onClick={() => handleClickStudent("plus")}>
                                                                            <span>+</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="price">
                                                                        <span
                                                                            className="price-dropdown font-weight-bold w-100"
                                                                            id="valor-crianca-sm"
                                                                        >
                                                                            {" "}
                                                                            R$ {(price?.student).toFixed(2).split(".").join(",")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        {
                                                            ranges?.isActivePrivate === true
                                                                ?
                                                                <div className="qtyButtons">
                                                                    <div className="flex-ajuste">

                                                                        <label>
                                                                            {ranges?.labelPrivate}
                                                                        </label>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <button className="qtyDec" onClick={() => handleClickGlobal("sub")}>
                                                                            <span>-</span>
                                                                        </button>
                                                                        <input
                                                                            type="text"
                                                                            name="qtyInput"
                                                                            value={numberGlobal}
                                                                            onChange={(e: any) => handleGlobal(e)}
                                                                            id="numero-crianca"
                                                                            readOnly onClick={(e: any) => { init_area(e) }}
                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        />
                                                                        {/* <span
                                                                                    id="numero-crianca"
                                                                                >{numberChilds}
                                                                                </span> */}
                                                                        <button className="qtyInc" onClick={() => handleClickGlobal("plus")}>
                                                                            <span>+</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="price">
                                                                        <span
                                                                            className="price-dropdown font-weight-bold w-100"
                                                                            id="valor-crianca-sm"
                                                                        >
                                                                            {" "}
                                                                            R$ {(price?.global).toFixed(2).split(".").join(",")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        {numberStockTotal >= dateTour[modalityNumber].maxStockNumber
                                                            ?
                                                            <SimpleAlert
                                                                alertType="danger"
                                                            >
                                                                Limite de estoque atingido
                                                            </SimpleAlert>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                    <hr />
                                                    <div>
                                                        <div className="qtyButtons">
                                                            <div className="flex-ajuste">
                                                                <label>
                                                                    SubTotal
                                                                </label>
                                                                {/* {tourResponse.productCode === 'PQF0619' ? <small id="idade-crianca">(6 a 12 anos)</small> : <></>} */}
                                                            </div>

                                                            <div className="price">
                                                                <span
                                                                    className="price-dropdown font-weight-bold w-100"
                                                                    id="valor-crianca-sm"
                                                                >
                                                                    {" "}
                                                                    R$ {(price?.adult * numberAdults + price?.child * numberChilds + price?.infant * numberInfants + price?.global * numberGlobal).toFixed(2).split(".").join(",")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            :
                                            <div className="d-flex flex-column align-items-center justify-content-around" style={{ height: "380px" }}>
                                                <div className="alert-message text-center">
                                                    <p>Produto já adicionado ao carrinho</p>
                                                </div>
                                            </div>
                                    }
                                </>
                                :
                                <>
                                </>
                            }

                            {/* // STEP 2 */}
                        </div>

                        {
                            repeat === false || edit === true
                                ?
                                <div className="d-flex justify-content-end align-items-end mb-3 px-0">
                                    <Button
                                        variant="outline-primary"
                                        className="btn-default mr-2"
                                        type="button"
                                        onClick={() => setModalShow(false)}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button className="btn-default text-light" type="button" onClick={auth}>
                                        {edit === true ? "Editar" : "Adicionar ao Carrinho"}
                                    </Button>
                                </div>
                                :
                                <div className="d-flex justify-content-end align-items-end mb-3 px-0">
                                    <Button
                                        variant="outline-primary"
                                        className="btn-default mr-2"
                                        type="button"
                                        onClick={() => setModalShow(false)}
                                    >
                                        Cancelar
                                    </Button>
                                </div>
                        }
                    </div>
                    {/* :
                                <div className="not-product">
                                    <div>
                                        <FontAwesomeIcon
                                            icon={["fal", "exclamation-circle"]}
                                            size="5x"
                                        />
                                    </div>
                                    <div className="alert-message text-center" dangerouslySetInnerHTML={{ __html: t("products.messageVal02") }}></div>
                                    <div>
                                        <button
                                            className="btn btn-default btn-primary text-light"
                                            onClick={() => setModalShow(false)}>
                                            Ok
                                        </button>
                                    </div>
                                </div>
                        } */}
                </div>

                {/* Modal Sucesso */}
                <div>
                    {/* <ModalAlert
                            class="modal-default"
                            setModalShow={setModalShow}
                            modalShow={modalShow}
                            text={messageValidation}
                            stayOnPage={stayOnPage}
                        /> */}
                </div>
                {/* END - Modal Sucesso */}

                {/* Modal Carioca */}
                <div>
                    {/* <ModalAlertCarioca
                            class="modal-default"
                            setModalShow={setModalShowCarioca}
                            modalShow={modalShowCarioca}
                            text={messageValidation}
                        /> */}
                </div>
                {/* END - Modal Carioca */}
            </div>
        </>
    );
}

export default ProductTour;