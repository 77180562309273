/* eslint-disable array-callback-return */
import { createSlice, configureStore } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "counter",
  initialState: {
    cart:
      localStorage.getItem("cartPDV") === null
        ? {
            Country: "BR",
            agent: "CPA3120" /* "CLI-XZ18690" */,
            discount: "0",
            foreign: 2,
            language: 1,
            resellerReference: "",
            source: "bilheteria",
            totalCart: 0,
            dados: [],
            payments: [],
            cupom: {
              code: "",
              codeName: "",
              type: 0,
            },
          }
        : JSON.parse(localStorage.getItem("cartPDV") || "{}"),
  },
  reducers: {
    addItemCart: (state: any, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.cart.dados = [...state.cart.dados, action.payload];
      if (state.cart.cupom?.type === 3) {
        state.cart.cupom = {
          type: 0,
        };
      }
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateDadosCart: (state: any, action) => {
      state.cart.dados = action.payload;
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateCart: (state: any, action) => {
      state.cart = { ...action.payload };
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateItemCart: (state: any, action) => {
      state.cart.dados[action.payload.index] = action.payload.item;
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    removeItemCart: (state: any, action) => {
      state.cart.dados = state.cart.dados.filter(
        (item: any) => item.controlCart !== action.payload.controlCart
      );
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    removeTotalCart: (state: any, action) => {
      state.cart.dados = [];
      state.cart.cupom = {
        type: 0,
      };
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    addPassengersToCart: (state: any, action) => {
      for (let i = 0; i < state.cart.dados.length; i++) {
        state.cart.dados[i].passengers[0] = {
          firstName: action.payload.firstName,
          email: action.payload.email,
          DocumentNumber: action.payload.DocumentNumber,
          phone: action.payload.phone
        };
      }
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    removePassengersToCart: (state: any) => {
      for (let i = 0; i < state.cart.dados.length; i++) {
        state.cart.dados[i].passengers = [];
      }
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateCupomCart: (state: any, action) => {
      state.cart.cupom = action.payload;

      state.cart.TourCode = {
        code: action.payload.codeName,
      };

      if (action.payload.type !== 3) {
        state.cart.dados.forEach((element: any) => {
          element.discount = 0;
          element.discountBool = false;
        });
      }

      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    removeCupomCart: (state: any) => {
      // state.cart-= 1
      state.cart.cupom = {
        type: 0,
      };
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateMenu: (state: any, action) => {
      localStorage.setItem("menuState", JSON.stringify(action.payload));
    },
  },
});

export const {
  addItemCart,
  updateDadosCart,
  updateCart,
  updateItemCart,
  removeItemCart,
  removeTotalCart,
  addPassengersToCart,
  removePassengersToCart,
  updateCupomCart,
  removeCupomCart,
  updateMenu,
} = counterSlice.actions;

const store = configureStore({
  reducer: counterSlice.reducer,
});

// convert object to string and store in localStorage
function saveToLocalStorage(state: any) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("cartPDV", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

function updateTotalCart(state: any) {
  let totalCart = 0;

  try {
    let cartTemp = JSON.stringify(state);
    let cartObj = JSON.parse(cartTemp);
    let priceProduct = 0;

    cartObj.dados.map((cItem: any, index: any) => {
      if (process.env.REACT_APP_ENVIRONMENT !== "CARACOL") {
        priceProduct =
          (Number(cItem.adults) * Number(cItem.priceAdults) || 0) +
          (Number(cItem.childs) * Number(cItem.priceChilds) || 0) +
          (Number(cItem.infants) * Number(cItem.priceInfants) || 0) +
          (Number(cItem.elders) * Number(cItem.priceElders) || 0) +
          (Number(cItem.student) * Number(cItem.priceStudent) || 0) +
          (Number(cItem.globalPeople) * Number(cItem.priceGlobalPeople) || 0);
      } else {
        let isencaoAndCortesia: any = localStorage.getItem("IsencaoCortesia");
        let type: any = JSON.parse(isencaoAndCortesia);

        if (type?.includes(cItem.productCode) === false) {
          priceProduct =
            (Number(cItem.adults) * Number(cItem.priceAdults) || 0) +
            (Number(cItem.childs) * Number(cItem.priceChilds) || 0) +
            (Number(cItem.infants) * Number(cItem.priceInfants) || 0) +
            (Number(cItem.elders) * Number(cItem.priceElders) || 0) +
            (Number(cItem.student) * Number(cItem.priceStudent) || 0) +
            (Number(cItem.globalPeople) * Number(cItem.priceGlobalPeople) || 0);
        } else {
          priceProduct = 0;
        }
      }

      if (cItem.discountBool === true && cartObj.cupom.type === 3) {
        priceProduct = priceProduct - priceProduct * (cItem.discount / 100);
      }

      totalCart = Number(totalCart) + priceProduct;
    });

    if (cartObj.cupom.type === 2) {
      totalCart = Number(totalCart) - cartObj.cupom.value;
    } else if (cartObj.cupom.type === 4) {
      totalCart = Number(totalCart) - totalCart * (cartObj.cupom.value / 100);
    } 

    return totalCart;
  } catch (e) {
    console.warn(e);
  }
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
