import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { setCookie, parseCookies } from 'nookies'
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from '../../services/api';
//import DesktopDefault from '../../templates/DesktopDefault';

export interface propItems {
    isAuthenticated: any;
    setAuthState: any;
    verifySession?: any;
}

const LoginSupervisor: React.FC<propItems> = ({
    isAuthenticated, setAuthState, verifySession
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [showPass, setShowPass] = useState<any>(false);
    
    const cookies = parseCookies();

    let local: any = localStorage.getItem("params");
    let storage: any = JSON.parse(local);
    
    const onSubmit = (e: any) => {
        e.preventDefault();
        const getAuthenticate = async () => {
            try {
                const { data } = await api.post('/LoginBilheteria/AuthenticateLider', {
                    "email": e.target.email.value,
                    "password": e.target.cust_pass.value,
                    "id": cookies.authCookie,
                    "idCaixa": (storage.caixa === null || storage.caixa === undefined) ? "caixa999" : `caixa${storage.caixa}` // ID CAIXA GERADO PELO FRONT
                });
                if (data.statusCode === 200) { 
                    setTimeout(() => {
                        verifySession('');  
                    }, 1000);
                    // console.log(data.data.user.obrigaFechamento);                 
                    if (data.data.user.perfils.filter((elem: any) => elem.nivel === 27).length > 0) { //procura o perfil de login com nível 27
                        setCookie(null, 'authSupervisor', data.data.user.perfils.filter((elem: any) => elem.nivel === 27)[0].token.token, {
                            maxAge: (60 * 60 * 8), //expira em 8 horas (60 * 60 * 8)
                            path: '/',
                        });
                        setCookie(null, 'Operator', `${data.data.user.nome} ${data.data.user.lastName}`, { //guardar nome e sobrenome do operador
                            maxAge: (60 * 60 * 8), //expira em 8 horas (60 * 60 * 8)
                            path: '/',
                        });                        
                        setAuthState(true);
                        if(data.data.user.obrigaFechamento === true && cookies.isCashierOpened === undefined){                            
                            setCookie(null, 'authFechamento', data.data.user.perfils.filter((elem: any) => elem.nivel === 27)[0].token.token, {
                                maxAge: (60 * 60 * 8), //expira em 8 horas (60 * 60 * 8)
                                path: '/',
                            });
                            window.location.href = window.location.origin + '/fechamento';
                        }
                    } else { //caso não ache perfil de login com nível 27
                        setLoading(false);
                        setErrorMessage('Código de operador de caixa não encontrado');
                    }
                    //setModalShow(false);
                    //window.location.href = window.location.origin + '/dashboard';
                } else {
                    setLoading(false)
                    setErrorMessage(data.errorMessage)
                }
            } catch (error: any) {
                setLoading(false);
                //setErrorMessage(error.response.data.errorMessage)
                if (error?.response?.status === 400) {
                    /* setLoading(false) */
                    setErrorMessage(error.response.data.errorMessage)
                }
            }
        }

        getAuthenticate();

        //setModalShow(false);
        //setLoading(false);
    }

    const init_area = (event: any) => {
        event.target.removeAttribute("readonly");
    }

    const GetError = () => {
        /* console.log(errorMessage === null); */
        if (errorMessage === null) {
            return (
                <div className="form-group"></div>
            );
        } else {
            return (
                <div className="invalid form-group">
                    <div className="invalid-feedback d-flex justify-content-center">{errorMessage}</div>
                </div>
            );
        }
    }

    /* [CONTROL] Controla caso a pessoa tente navegar sem o login do supervisor autenticado */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [verifyFraud, setVerifyFraud] = useState<any>(false);
    
    useEffect(() => {
        setVerifyFraud(true);
    }, [])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let aux: any = document.querySelector('.modalAuth.login-supervisor')

    // if (!isAuthenticated && aux === null && verifyFraud === true) {
    //     window.location.reload();
    // }
    /* [/CONTROL] */

    return (
        <>
            <Modal
                className="modalAuth login-supervisor"
                show={!isAuthenticated}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <Modal.Body
                        className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                        style={{ height: "600px" }}
                    >
                        <div className="modal-container w-100">
                            <h2 className='modal-title'>Login do Supervisor</h2>

                            <form className="" name="login_form" onSubmit={(e) => onSubmit(e)} autoComplete='off'>
                                <div className="form-group">
                                    <label className='d-flex justify-content-start' htmlFor="exampleInputEmail">{t("login.email")}</label>
                                    <input type="text" name="email" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp" autoComplete='off' readOnly onClick={(e: any) => { init_area(e) }} onPaste={() => { return false }} />
                                </div>
                                <div className="form-group">
                                    <label className='d-flex justify-content-start' htmlFor="exampleInputPassword">{t("login.password")}</label>
                                    <input type={showPass === true ? "text" : "password"} name="cust_pass" className="form-control" id="exampleInputPassword" autoComplete='off' readOnly onClick={(e: any) => { init_area(e) }} onPaste={() => { return false }} />
                                    <div className="iconsPassword">
                                        {showPass === false ?
                                            <FontAwesomeIcon
                                                icon={["fal", "eye-slash"]}
                                                size="1x"
                                                onClick={() => { setShowPass(!showPass) }}
                                            />
                                            :
                                            <FontAwesomeIcon
                                                icon={["fal", "eye"]}
                                                size="1x"
                                                onClick={() => { setShowPass(!showPass) }}
                                            />
                                        }
                                    </div>
                                </div>
                                <GetError />

                                <Row>
                                    <Col md={6}>

                                        <button type="button" className="form-button btn btn-outline-primary btn-block" onClick={() => { window.location.href = '/' }}>Cancelar</button>
                                    </Col>
                                    <Col md={6}>

                                        <button type="submit" className="form-button btn btn-primary btn-block btn-login text-white" onClick={() => { setLoading(true) }}>{loading === true ? <div className="load"></div> : "Autorizar abertura"}</button>
                                    </Col>
                                </Row>
                            </form>
                            <div></div>
                        </div>
                    </Modal.Body>
                </>
            </Modal>
        </>
    );
}

export default LoginSupervisor;