import React, { useState } from "react";

import "./Tickets.scss";

//COMPONENTS
import { Col, Row } from "react-bootstrap";
import CartPDV from "./components/Cart/CartPDV";
import ModalBuy from "../../components/Modal/ModalBuy/ModalBuy";
import ModalPayment from "./components/ModalPayment/ModalPayment";
import DesktopDefault from "../../templates/DesktopDefault";
import TabSearchs from "../../components/TabSearchs/TabSearchs";
import Transfers from "./components/Transfers/Transfers";
import Pagination from "../../components/Pagination/Pagination";

//ICONS
/* import iconGlobe from '../../assets/icons/fa-globe-americas.svg'; */

const Tickets = () => {
  const [resProduct, setResProduct] = useState<any>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<any>();
  const [modalPaymentShow, setModalPaymentShow] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(0);

  const [chooseTab, setChooseTab] = useState<number>(1);
  const [search, setSearch] = useState<any>(null);

  const [pagination, setPagination] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(6);
  const [totalRows, setTotalRows] = useState<any>(0);

  return (
    <>
      <DesktopDefault pageTitle={'Bilhetes'}>
        <div className="main">
          <Row>
            <p className="route text-capitalize">Bilhetes &gt; Bilhetes {process.env.REACT_APP_ENVIRONMENT}</p>
          </Row>
          <Row>
            <Col md={8} className="content-product">
              <Row>
              <TabSearchs setResProduct={setResProduct} setChooseTab={setChooseTab} setSearch={setSearch} setTotalRows={setTotalRows} />
              </Row>
              <Row className="mx-0 bg-content-pdv px-0 mt-2">
                {
                  chooseTab === 1
                    ?
                    resProduct !== undefined
                      ?
                      <Row>
                        {resProduct.slice(((pagination - 1) * rowsPerPage), ((pagination) * rowsPerPage)).map((item: any, index: any) => {
                          return (
                            <Col className="p-3" md={4} key={index} onClick={() => setModalInfo(item)}>
                              <div
                                onClick={() => { setModalShow(true); setEdit(false) }}
                                className="ticket-image"
                                style={{
                                  backgroundImage: `url(${item.imagesBaseUrl}${item.productImg})`,
                                }}
                              >
                                <div className="product-name">
                                  {item.productName.split()}
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                        <Pagination
                          totalRows={totalRows}
                          pageCount={pagination}
                          setPageCount={setPagination}
                          rowsPerPage={rowsPerPage}
                          setRowsPerPage={setRowsPerPage}
                          selectNumberRows="no"
                        />
                      </Row>
                      :
                      <></>
                    :
                    <Row className="bg-content-pdv mx-0 px-0">
                      <Transfers search={search} setSearch={setSearch} />
                    </Row>
                }
              </Row>
            </Col>
            <Col md={4}>
              <CartPDV setModalPaymentShow={setModalPaymentShow} setModalShow={setModalShow} setEdit={setEdit} setModalInfo={setModalInfo} setEditIndex={setEditIndex} />
            </Col>
          </Row>

          <ModalBuy
            setModalShow={setModalShow}
            modalShow={modalShow}
            modalInfo={modalInfo}
            edit={edit}
            editIndex={editIndex}
          />

          <ModalPayment
            setModalShow={setModalPaymentShow}
            modalShow={modalPaymentShow}
          />

        </div>

      </DesktopDefault>
    </>
  );
}

export default Tickets;
