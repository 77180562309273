import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export interface propPlus {
  propsId: number;
  actionPickup: any;
  alert: any;
  edit?: any;
  editInfo?: any;
}

const GetPickups: React.FC<propPlus> = ({
  propsId, actionPickup, alert, edit, editInfo
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [check, setCheck] = useState<any>(true);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("c2tourAuth") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token).token}` },
    };
    async function listPickups() {
      try {
        const { data } = await api.get(`/Pickup/GetListByIdStrangerToCheckOutAsync/${propsId}`, config);
        if (data.status !== 400) {
          setInfo(data.data);
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + "/";
        }
      }
    }
    listPickups();
  }, [propsId /*  */]);

  const handleChange = (options: any) => {
    actionPickup(options);
    setCheck(false)
  };

  if (info !== null) {
    return (
      <>
        <FontAwesomeIcon
          icon={["fal", "map-marker-alt"]}
          size="1x"
          style={{ color: "#000", position: "absolute", transform: "translate(4px, 8px)", fontSize: "22px", zIndex: 9 }}
        />
        <div style={{ position: "relative" }}>
          {
            edit !== true
              ?
              <>
                <Select
                  aria-labelledby="aria-label"
                  inputId="aria-example-input"
                  className="endpoint"
                  name="aria-live-color"
                  placeholder="Selecione"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuOpen}
                  onChange={handleChange}
                  options={Array.from(info)}
                  getOptionLabel={(option: any) => `${option.name}: ${option.address}`}
                />
                {
                  check === true
                    ?
                    alert
                    :
                    ""
                }
              </>
              :
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                className="endpoint"
                name="aria-live-color"
                placeholder="Selecione"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuOpen}
                onChange={handleChange}
                options={Array.from(info)}
                getOptionLabel={(option: any) => `${option.name}: ${option.address}`}
                defaultValue={{
                  "id": `${editInfo?.pickupInfo?.id}`,
                  "name": `${editInfo?.pickupInfo?.name}: ${editInfo?.pickupInfo?.address}`
                }}
              />
          }
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer", color: "#666", position: "relative", height: "40px" }}>
          <div className="load" style={{ position: "absolute" }}></div>
        </div>
        {/* <Form.Select>
          <option value='' disabled></option>
        </Form.Select> */}
      </>
    )

  }
};

export default GetPickups;
