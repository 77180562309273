import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseCookies, destroyCookie } from 'nookies'

import Header from "../components/Header/Header";
import Menu from "../components/Menu/Menu";

function DesktopDefault(props: any) {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalForceOpenCashier, setModalForceOpenCashier] = useState<boolean>(false);
    const cookies = parseCookies();

    useEffect(() => {
        if ((!['/cash-register','/fechamento'].includes(window.location.pathname) && cookies.isCashierOpened === undefined)) {
            setModalForceOpenCashier(true);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* Ociosidade da tela */

    var tempoDeEspera = parseInt(`${process.env.REACT_APP_INACTIVE_TIME}`) * 60 * 1000; //intervalo de 5 min (5 * 60 * 1000)
    var timeout = setTimeout(ociosidade, tempoDeEspera);

    function someAction(e: any) { //reinicia o contador caso alguma atividade (mouse ou teclado) ocorra!
        clearInterval(timeout);
        timeout = setTimeout(ociosidade, tempoDeEspera);
    }

    function ociosidade() { //função que roda caso a tela estaja ociosa
        destroyCookie(null, 'authSupervisor');
        setModalShow(true);
    }

    ['keyup', 'touchmove' in window ? 'touchmove' : 'mousemove', "onwheel" in document.createElement("div") ? "wheel" : document.onwheel !== undefined ? "mousewheel" : "DOMMouseScroll"].forEach(function (ev) {
        window.addEventListener(ev, someAction);
    });

    /* [END] Ociosidade da tela */

    return (
        <>
            <div className="d-flex">
                <Menu />

                <div style={{
                    width: "100%"
                }}>
                    <div className='contentPDV'>
                        <Header pageTitle={props.pageTitle} setInfoTotal={props.setInfoTotal} />
                        <div >
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="modalAuth modal-validation"
                show={modalForceOpenCashier}
                onHide={() => { window.location.href = '/cash-register' }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <>
                    <Modal.Body
                        className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
                    >
                        <div>

                            <FontAwesomeIcon
                                icon={["fal", "times-circle"]}
                                size="5x"
                                className='text-primary'
                            />
                        </div>

                        <small>{`Favor, abrir seu caixa para acessar essa página!`}</small>
                        <div className="d-flex justify-content-center pt-3">
                            <button
                                onClick={() => {
                                    window.location.href = '/cash-register'
                                }}
                                className="btn btn-primary mx-2 w-25"
                                style={{ color: '#FFF' }}
                            >
                                Confirmar
                            </button>
                        </div>
                    </Modal.Body>
                </>
            </Modal>

            <Modal
                className="modalAuth modal-validation"
                show={modalShow}
                onHide={() => { destroyCookie(null, 'authSupervisor'); window.location.href = '/'; }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <>
                    <Modal.Body
                        className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
                    >
                        <div>

                            <FontAwesomeIcon
                                icon={["fal", "times-circle"]}
                                size="5x"
                                //style={{ fontSize: "7.5em" }}
                                className='text-primary'
                            />
                        </div>

                        <small>{`Inativo por ${process.env.REACT_APP_INACTIVE_TIME} minutos, Favor logar novamente`}</small>
                        <div className="d-flex justify-content-center pt-3">
                            <button
                                onClick={() => {
                                    destroyCookie(null, 'authSupervisor');
                                    window.location.href = '/';
                                    /* setModalShow(false);
                                    setAuthState(false); */
                                }}
                                className="btn btn-primary mx-2 w-25"
                                style={{ color: '#FFF' }}
                            >
                                Confirmar
                            </button>
                        </div>
                    </Modal.Body>
                </>
            </Modal>
        </>
    )
}

export default DesktopDefault;