import React, { useState } from 'react';
import './CashRegister.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseCookies } from 'nookies';
import { Col, Modal } from 'react-bootstrap';

//COMPONENTS
import CashierOpening from '../../components/CashierOpening/CashierOpening';
import CashRegisterCards from './components/CashRegisterCards/CashRegisterCards';
import CashRegisterTable from './components/CashRegisterTable/CashRegisterTable';
import DesktopDefault from '../../templates/DesktopDefault';


//ICONS
/* import iconGlobe from '../../assets/icons/fa-globe-americas.svg'; */

function CashRegister() {
  const [loading,] = useState<any>(false);
  const [modalOpenCashierShow, setModalOpenCashierShow] = useState<boolean>(false);
  const [modalCloseCashierShow, setModalCloseCashierShow] = useState<boolean>(false);
  const [openingSuccess, setOpeningSuccess] = useState<boolean>(false);
  const [openingSuccessMessage, setOpeningSuccessMessage] = useState<string>('');
  const [salesTotal, setSalesTotal] = useState<any>([]);
  const [allowLogOut, setAllowLogOut] = useState<boolean>(false);

  const cookies = parseCookies();

  let data = [];

  if(process.env.REACT_APP_ENVIRONMENT === "CARACOL") {
    data = [
      {
        tipodemovimento: "Total de Vendas",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'TOTAL')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Dinheiro",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'CASH')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Cartão de Crédito",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'CREDITCARD')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Cartão de Débito",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'DEBITCARD')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Devolução",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'REFUND')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Isenção",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'ISENCAO')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Cortesia",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'CORTESIA')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
    ];
  } else {
    data = [
      {
        tipodemovimento: "Total de Vendas",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'TOTAL')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Dinheiro",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'CASH')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Cartão de Crédito",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'CREDITCARD')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Cartão de Débito",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'DEBITCARD')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Devolução",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'REFUND')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      }/* ,
      {
        tipodemovimento: "Isenção",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'ISENCAO')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      },
      {
        tipodemovimento: "Cortesia",
        registradonopdv: salesTotal?.filter((elem: any) => elem.type === 'CORTESIA')[0]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0,00',
      }, */
    ];
  }

  const handleOpenCashier = () => {
    /* const cookies = parseCookies();

    if(cookies.isCashierOpened === 'true'){
      alert('caixa ja está aberto');
    }else{
      //abrir caixa
    } */
    setModalOpenCashierShow(true);
  }

  const handleCloseCashier = () => {
    setModalCloseCashierShow(true);
    /* const cookies = parseCookies();

    if(cookies.isCashierOpened === 'true'){
      alert('caixa ja está aberto');
    }else{
      setModalOpenCashierShow(true);
      //abrir caixa
    } */
  }



  return (
    <>
      <DesktopDefault pageTitle={'Caixa'} setInfoTotal={setSalesTotal}>
        <main>
          <div className="row d-flex justify-content-end">
            <Col md={12}>

              <CashRegisterCards
                cardsInfo={salesTotal}
              />
            </Col>
            <Col md={2} className='d-flex justify-content-end align-items-center'>
              {cookies.isCashierOpened === undefined && <button type='button' className='btn btn-primary text-white' onClick={handleOpenCashier}>Abrir caixa</button>}
            </Col>
          </div>

          <CashRegisterTable
            loading={loading}
            cashTable={data}
            handleCloseCashier={handleCloseCashier}
          />

          <CashierOpening modalShow={modalOpenCashierShow} setModalShow={setModalOpenCashierShow} setOpeningSuccess={setOpeningSuccess} setModalMessage={setOpeningSuccessMessage} />
          <CashierOpening modalShow={modalCloseCashierShow} setModalShow={setModalCloseCashierShow} setOpeningSuccess={setOpeningSuccess} setModalMessage={setOpeningSuccessMessage} isFechamento={true} setAllowLogOut={setAllowLogOut} />

          <Modal
            className="modalAuth modal-validation"
            show={openingSuccess}
            onHide={() => {
              setOpeningSuccess(false);
              if (allowLogOut === true) window.location.href = '/';
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <>
              <Modal.Body
                className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
              >
                <div>

                  <FontAwesomeIcon
                    icon={["fal", "check-circle"]}
                    size="5x"
                    //style={{ fontSize: "7.5em" }}
                    className='text-primary'
                  />
                </div>

                <div>{openingSuccessMessage}</div>
                <div className="d-flex justify-content-center pt-3">
                  <button
                    onClick={() => {
                      if (allowLogOut === true) window.location.href = '/';
                      setOpeningSuccess(false);
                    }}
                    className="btn btn-primary mx-2 w-25"
                    style={{ color: '#FFF' }}
                  >
                    Confirmar
                  </button>
                </div>
              </Modal.Body>
            </>
          </Modal>
        </main>
      </DesktopDefault>
    </>

  );
}

export default CashRegister;