import React from "react";
import { Button } from "react-bootstrap";

//COMPONENTS

//ICONS
import iconMachine from "../../../../assets/icons/credit_card_machine_dataphone.svg";

export interface checkout {
  infos: any,
  continueReady: any,
  changeContinua: any
};

const InstructionsSitef: React.FC<checkout> = React.memo(({ infos, continueReady, changeContinua
}: checkout) => {

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <img className="mb-4" src={iconMachine} alt="icon machine" style={{ width: "6rem" }} />
        <h4 className="mb-4 text-primary">Siga as instruções da maquininha</h4>
        <p style={{ fontWeight: 600 }}>{infos}</p>
        <div className="printing-buttons" style={{ marginTop: "1rem" }}>
          {
            continueReady === true/*  && pending !== false */
              ?
              <Button className="btn btn-default text-white" onClick={() => changeContinua(-1)}>
                Cancelar
              </Button>
              :
              <></>
          }
        </div>
      </div>
    </>
  )
});

export default InstructionsSitef;
