import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import '../../App.css';
import api from '../../services/api';

import LoadScript from "../../infra/LoadScript";

//STYLE
import './Header.scss'
import { parseCookies } from 'nookies';

export interface propHeader {
    pageTitle: string; setInfoTotal?: any, allowStatus?: boolean
};

let continua = 0;

let cupomFiscal = '';
//let numberID = '';
let dataFiscal = '';
let horaFiscal = '';
let pendente = '';
let sessionId = '';

let pendLoc = '';
let pendDate = '';
let pendTime = '';
let pendStatus = '';

declare global {
    interface Window {
        BrowserPrint?: any;
    }
}

const Header: React.FC<propHeader> = ({
    pageTitle, setInfoTotal, allowStatus = true
}: propHeader) => {
    LoadScript('/static/js/BrowserPrint-3.0.216.min.js');

    const [readySITEF, setReadySITEF] = useState<any>('loading');
    const [readyPrinter, setReadyPrinter] = useState<any>(process.env.REACT_APP_ENVIRONMENT !== "CARACOL" ? 'loading' : 'success');
    const [readyCoupon, setReadyCoupon] = useState<any>('loading');

    const [chooseDevice, setChooseDevice] = useState<any>('');
    const [available, setAvailabe] = useState<number>(0);
    const [paper, setPaper] = useState<any>('');
    const [stop, setStop] = useState<any>('');

    const [isPrint, setIsPrint] = useState<boolean>(false);

    const [salesTotal, setSalesTotal] = useState<any>(null);

    let local: any = localStorage.getItem("params");
    let storage: any = JSON.parse(local);

    const sitefIpNew = storage !== null && storage?.sitefIp !== null ? storage?.sitefIp : "127.0.0.1";
    const empresaNew = storage !== null && storage?.empresa !== null ? storage?.empresa : "00000000"; // 00004673
    const terminalNew = storage !== null && storage?.terminal !== null ? storage?.terminal : "REST0001"; // MU000901
    const cnpjEstabelecimentoNew = storage !== null && storage?.cnpjEstabelecimento !== null ? storage?.cnpjEstabelecimento : "31406434895111";
    const cnpjLojaNew = storage !== null && storage?.cnpjLoja !== null ? storage?.cnpjLoja : "12523654185985";
    //const caixa = storage !== null && storage?.caixa !== null ? storage?.caixa : "009";

    const date: any = (new Date().toLocaleString()).split(" ")[0].split("/").reverse().join("");
    const time: any = (new Date().toLocaleString()).split(" ")[1].split(":").join("");

    // Verificação do SITEF
    const verifySession = useCallback(async (order: any) => {

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/session`, `sitefIp=${sitefIpNew}&storeId=${empresaNew}&terminalId=${terminalNew}&sessionParameters=[ParmsClient=1=${cnpjEstabelecimentoNew};2=${cnpjLojaNew}]`);
            if (data?.statusCode !== 400) {

                if (data.sessionId !== undefined) {
                    order = 0;
                }

                verifyPinpad(data.sessionId, order);
                sessionId = data.sessionId;
            }

        } catch (e) {
            setReadySITEF('error'); // Máquininha com problema.
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const verifyPinpad = useCallback(async (sessionId: string, order: any) => {

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/pinpad/isPresent`, `sessionId=${sessionId}`);
            if (data?.statusCode !== 400) {
                /* startTransaction(1, 130, order) */
                setReadySITEF('success');
            }
        } catch (e) {
            setReadySITEF('error'); // Máquininha com problema.
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const startTransaction = useCallback(async (tipo, funcao, order) => {
        let param = '';

        if (tipo === 1) {
            param = `sessionId=${sessionId}&functionId=${funcao}&trnAmount=&taxInvoiceNumber=&taxInvoiceDate=${date}&taxInvoiceTime=${time}&cashierOperator=CAIXA&trnAdditionalParameters=&trnInitParameters=[ParmsClient=1=${cnpjEstabelecimentoNew};2=${cnpjLojaNew}]`;
        }

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/startTransaction`, param);

            if (data?.statusCode !== 400) {
                if (data.serviceStatus !== 0) {
                    /*                     setReadySITEF('pendente');
                                        setTextError('Impressora não disponível'); */
                    setTimeout(() => {
                        verifySession(1);
                    }, 3000);

                } else {
                    continueTransaction('', '', order);
                }

            } else {
                setReadySITEF('error');
            }
        } catch (e) {
            setReadySITEF('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const continueTransaction = useCallback(async (dados, installMent, order) => {

        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/continueTransaction`, `sessionId=${sessionId}&data=${dados}&continue=${continua}`);

            if (data?.statusCode !== 400) {

                if (data.clisitefStatus !== 10000) {

                    if (data.clisitefStatus === 0) {

                        if (Number(pendente) >= 1) {
                            verifyPendente(cupomFiscal, order);
                            //Printer();
                        }
                    } else {
                        setReadySITEF('error');
                    }

                    return;
                }

                switch (data.commandId) {
                    case 0:

                        switch (data.fieldId) {
                            case 160:
                                cupomFiscal = data.data;
                                break;
                            case 161:
                                break;
                            case 163:
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                dataFiscal = data.data;
                                break;
                            case 164:
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                horaFiscal = data.data;
                                break;
                            case 210:

                                if (order === 0) {
                                    setTimeout(() => {
                                        setReadySITEF('success');
                                    }, 500);
                                }

                                if (Number(data.data) > 0) {
                                    pendente = data.data
                                } else {
                                    pendente = data.data
                                    //Printer();
                                }

                                break;
                            case 1319:
                                break;

                        }

                        continueTransaction('', installMent, order);
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 15:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 16:
                    case 22:
                        continueTransaction('', installMent, order);
                        break;
                    case 23:
                    case 21:
                    case 20:
                    case 30:
                    case 31:
                    case 32:
                    case 33:
                    case 34:
                    case 35:
                    case 38:
                        break;

                    default:
                        continueTransaction('', installMent, order);
                }
            }
        } catch (e) {
            setReadySITEF('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const finishTransaction = useCallback(async (order) => {
        try {
            const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API_SITEF}/agente/clisitef/finishTransaction`, `sessionId=${sessionId}&taxInvoiceNumber=${pendLoc}&taxInvoiceDate=${pendDate}&taxInvoiceTime=${pendTime}&confirm=${pendStatus}`);
            if (data?.statusCode !== 400) {

                if (order === 1) {
                    setTimeout(() => {
                        setReadySITEF('success');
                    }, 1500);
                }
            }
        } catch (e) {
            setReadySITEF('error');
        }
    }, []);

    const verifyPendente = useCallback(async (cupomFiscal, order) => {
        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/bookings/getstatussitef/${cupomFiscal}`);

            if (data?.statusCode !== 400) {
                //console.log(data.data)
                pendLoc = data.data.bookingloc;
                pendDate = data.data.date;
                pendTime = data.data.time;
                pendStatus = data.data.status;

                setTimeout(() => {
                    finishTransaction(order);
                }, 100);
            }
        } catch (e) {
            setReadySITEF('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readyCoupom = useCallback(async () => {
        try {
            const { data } = await api.get(`http://localhost:5000/api/impressao/bilheteria/status`);
            if (data?.statusCode !== 400) {
                setReadyCoupon('success');
            } else {
                setReadyCoupon('error');
            }
        } catch (e) {
            setReadyCoupon('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        verifySession('');
    }, [verifySession]);
    // END - Verificação do SITEF

    useEffect(() => {
        readyCoupom();
    }, [readyCoupom])

    // Verificação Impressora

    var readCallback = function (readData: any) {
        if (readData === undefined || readData === null || readData === "") {
            /* setPaper('0');
            setStop('0'); */
            setAvailabe(2);
            /* console.log("Oiiiii!"); */
            setReadyPrinter('error');
        }
        else {
            setPaper(readData.split(",")[1]);
            setStop(readData.split(",")[2]);
            setAvailabe(2);
        }
    }

    var errorCallback = function (errorMessage: any) {
        if (readyPrinter !== 'error') {
            setReadyPrinter("error"); // Impressora não disponível
        }
    }

    function writeToSelectedPrinter(dataToWrite: any) {
        if (dataToWrite === "~hs") {
            chooseDevice.send(dataToWrite, undefined, errorCallback);
        }
    }

    function readFromSelectedPrinter() {
        chooseDevice.read(readCallback, errorCallback);
    }

    useEffect(() => {
        if (available === 1 && chooseDevice !== undefined) {
            readFromSelectedPrinter();
            writeToSelectedPrinter("~hs");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [available, chooseDevice])

    useEffect(() => {
        if (available === 2) {
            //console.log(stop, paper)
            if (readyPrinter !== "error") {
                if (stop === '1') {
                    setReadyPrinter('error'); // Impressora pausada.

                } else if (paper === '1') {
                    setReadyPrinter('error'); // Impressora sem papel.
                } else {
                    setReadyPrinter('success');
                    //readyCoupom();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [available])
    // END - Verificação Impressora

    const cookies = parseCookies();
    const token = cookies.authSupervisor;
    const operator = cookies.Operator;

    useEffect(() => {
        const date: any = new Date();
        const today: any = date.toISOString();

        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        async function getItems() {
            try {
                const { data } = await api.post('/Bilheteria/GetTotalSoldPeriodByFilterAsync', {
                    "dateStartFind": today,
                    "dateEndFind": today,
                    "caixaPessoa": 1,
                    "sessionOrDay": 1
                }, config);
                if (data.statusCode !== 400) {
                    setSalesTotal(data.data.data);
                    setInfoTotal(data.data.data);
                } else {

                }

            } catch (error: any) {
                if (error?.response?.status === 400) {

                }
            }
        }
        if (cookies.authSupervisor !== undefined) {
            getItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies.isCashierOpened, cookies.authSupervisor, token]);

    return (
        <div className="header">
            <div className='header-title'>
                <h1>{pageTitle}</h1>
                <small>Escolha os bilhetes do {process.env.REACT_APP_ENVIRONMENT}</small>
            </div>

            <div className='d-flex align-items-center gap-5'>
                <div className='d-flex align-items-center'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <div className='d-flex align-items-center'>
                                <FontAwesomeIcon icon={['fal', 'donate']} style={{ fontSize: "1.15rem", marginRight: ".4rem" }} />
                                <small style={{ color: "#707070", fontWeight: 600 }}>Total de Vendas</small>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='sale-total'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <small style={{ color: "#707070", fontWeight: 600, fontSize: ".875rem" }}>Dinheiro</small>
                                {
                                    salesTotal !== null
                                        ?
                                        <small style={{ color: "#707070", fontWeight: 600, fontSize: ".875rem" }}>{salesTotal[0].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                                        :
                                        <></>
                                }
                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <small>Cartão de Crédito</small>
                                {
                                    salesTotal !== null
                                        ?
                                        <small>{salesTotal[1].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                                        :
                                        <></>
                                }
                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <small>Cartão de Débito</small>
                                {
                                    salesTotal !== null
                                        ?
                                        <small>{salesTotal[2].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                                        :
                                        <></>
                                }
                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-2' style={{ opacity: ".5" }}>
                                <small>PIX</small>
                                {
                                    salesTotal !== null
                                        ?
                                        <small>{salesTotal[3].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                                        :
                                        <></>
                                }
                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <small>Devolução</small>
                                {
                                    salesTotal !== null
                                        ?
                                        <small>{salesTotal[4].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                                        :
                                        <></>
                                }
                            </div>
                            {
                                process.env.REACT_APP_ENVIRONMENT === "CARACOL"
                                    ?
                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                        <small>Isenção</small>
                                        {
                                            salesTotal !== null
                                                ?
                                                <small>{salesTotal[7]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                                                :
                                                <></>
                                        }
                                    </div>
                                    :
                                    ""
                            }
                            {
                                process.env.REACT_APP_ENVIRONMENT === "CARACOL"
                                    ?
                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                        <small>Cortesia</small>
                                        {
                                            salesTotal !== null
                                                ?
                                                <small>{salesTotal[8]?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                                                :
                                                <></>
                                        }
                                    </div>
                                    :
                                    ""
                            }
                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                <small>Total</small>
                                {
                                    salesTotal !== null
                                        ?
                                        <small>{salesTotal[5].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                                        :
                                        <></>
                                }
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <small>Valor em Caixa</small>
                                {
                                    salesTotal !== null
                                        ?
                                        <small>{salesTotal[6].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                                        :
                                        <></>
                                }
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>


                </div>
                <div className='d-flex align-items-center'>
                    {allowStatus &&
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <div className='d-flex align-items-center'>
                                    {
                                        readySITEF === 'success' && readyCoupon === 'success'
                                            ?
                                            <FontAwesomeIcon icon={['fas', 'check-circle']} className="color-success" style={{ fontSize: "1.15rem", marginRight: ".4rem" }} />
                                            :
                                            readySITEF === 'error' && readyCoupon === "error"
                                                ?
                                                <FontAwesomeIcon icon={['fas', 'times-circle']} className="color-error" style={{ fontSize: "1.15rem", marginRight: ".4rem" }} />
                                                :
                                                readySITEF !== 'loading' && readyCoupon !== 'loading'
                                                    ?
                                                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="color-alert" style={{ fontSize: "1.15rem", marginRight: ".4rem" }} />
                                                    :
                                                    <div className='load'></div>
                                    }

                                    <small style={{ color: "#707070", fontWeight: 600 }}>Status do Sistema</small>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <div className='d-flex align-items-center mb-2'>
                                    {
                                        readySITEF === 'error'
                                            ?
                                            <FontAwesomeIcon icon={['fas', 'times-circle']} className="color-error" style={{ fontSize: "1.15rem", marginRight: ".4rem" }} />
                                            :
                                            readySITEF === 'success'
                                                ?
                                                <FontAwesomeIcon icon={['fas', 'check-circle']} className="color-success" style={{ fontSize: "1.15rem", marginRight: ".4rem" }} />
                                                :
                                                <div className='load'></div>
                                    }
                                    <small style={{ color: "#707070", fontWeight: 600 }}>SITEF</small>
                                </div>
                                <div className='d-flex align-items-center'>
                                    {
                                        readyCoupon === 'error'
                                            ?
                                            <FontAwesomeIcon icon={['fas', 'times-circle']} className="color-error" style={{ fontSize: "1.15rem", marginRight: ".4rem" }} />
                                            :
                                            readyCoupon === 'success'
                                                ?
                                                <FontAwesomeIcon icon={['fas', 'check-circle']} className="color-success" style={{ fontSize: "1.15rem", marginRight: ".4rem" }} />
                                                :
                                                <div className='load'></div>
                                    }
                                    <small style={{ color: "#707070", fontWeight: 600 }}>Impressora Cupom</small>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
                <div className='d-flex align-items-center gap-3' style={{ color: "#707070", fontWeight: 700, fontSize: "1.25rem" }}>
                    {operator}

                    <FontAwesomeIcon icon={['fad', 'user-circle']} className="operator-name" />
                </div>
            </div>
        </div>
    );
}

export default Header;