import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
/* import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
import api from '../../services/api';
import { setCookie, parseCookies, destroyCookie } from 'nookies'

export interface propItems {
    setModalShow: any;
    modalShow: boolean;
    isFechamento?: boolean;
    setOpeningSuccess?: any;
    setModalMessage?: any;
    setAllowLogOut?: any;
}

const CashierOpening: React.FC<propItems> = ({
    setModalShow, modalShow, isFechamento = false, setOpeningSuccess, setModalMessage, setAllowLogOut = () => { }
}) => {
    //const { t } = useTranslation();

    const [maxOpening, setMaxOpening] = useState<any>("");

    const [loading, setLoading] = useState<boolean>(false);
    //const [showPass, setShowPass] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [typeCashier, setTypeCashier] = useState<any>([]);

    function handleMaxOpening(e: any) {
        if (e.target.value < Number(`${process.env.REACT_APP_MAX_OPENING_CASHIER}`)) {
            setMaxOpening(e.target.value);
        } else {
            setMaxOpening(process.env.REACT_APP_MAX_OPENING_CASHIER);
        }
    }

    useEffect(() => {
        setMaxOpening("");
    }, [modalShow])

    useEffect(() => {
        

        const getStatusCaixaDescription = async () => {
            const cookies = parseCookies();
            const token = cookies.authSupervisor;
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, "ngrok-skip-browser-warning": "69420" },
            };
            try {
                const { data } = await api.get(`/DropDown/GetStatusCaixaDescription/${isFechamento === true ? 2 : 1}`, config);
                if (data.statusCode !== 400) {
                    setTypeCashier(data.data);
                }
            } catch (error: any) { }
        }
        if (modalShow === true) {
            setErrorMessage('');
            getStatusCaixaDescription();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalShow]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        setErrorMessage('');
        setLoading(true);

        const openCashier = async () => {
            const cookies = parseCookies();
            const token = cookies.authSupervisor;
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };
            try {
                const { data } = await api.post(`/BilheteriaCaixa/AberturaFechamentoCaixa`, {
                    "valor": isFechamento === true ? e.target.valor.value : maxOpening,
                    /* "emailLider": e.target.email.value,
                    "password": e.target.cust_pass.value, */
                    "tipoOperacao": isFechamento === true ? 2 : 1, // 1 = abertura | 2 = fechamento
                    "justificativa": e.target.observations.value,
                    "tipoFuncao": parseInt(e.target.tipoFuncao.value) // id do dropdown de tipo de abertura e fechamento
                }, config);
                if (data.statusCode !== 400) {
                    if (data.data.log !== 0) {
                        setErrorMessage(data.data.texto);
                    }

                    setLoading(false);

                    if (isFechamento === true) {
                        if (data.data.log === 0) {
                            destroyCookie(null, 'isCashierOpened');
                            setModalMessage(data.data.texto);
                            setOpeningSuccess(true);

                            if (window.location.pathname !== '/fechamento') {
                                setAllowLogOut(true);
                                setModalShow(false);
                            }
                        }
                    } else {
                        if (data.data.log === 0) {
                            setCookie(null, 'isCashierOpened', 'true', {
                                maxAge: (60 * 60 * 8), //expira em 8 horas (60 * 60 * 8)
                                path: '/',
                            });

                            setModalMessage(data.data.texto);
                            setOpeningSuccess(true);
                            setModalShow(false);
                        }
                    }

                    /* if (window.location.pathname === '/fechamento' && data.data.log === 0) {
                        window.location.href = '/';
                    } */


                } else {
                    setLoading(false)
                }

            } catch (error: any) {
                setLoading(false)
                setErrorMessage(error?.response?.statusText || "Erro ao processar operação");
                if (error?.response?.status === 400) {
                    //setModalMessage(error.response.data.errorMessage)
                }
            }
        }

        openCashier();
    }

    /* const init_area = (event: any) => {
        event.target.removeAttribute("readonly");
    } */

    const GetError = () => {
        if (errorMessage === '') {
            return (
                <div className="form-group"></div>
            );
        } else {
            return (
                <div className="invalid form-group">
                    <div className="invalid-feedback d-flex justify-content-center">{errorMessage}</div>
                </div>
            );
        }
    }

    function handleCanceled() {
        if(window.location.pathname !== "/fechamento") {
            setModalShow(false);
        } else {
            setModalShow(false);
            window.location.href = "/tickets";
        }
    }

    return (
        <Modal
            className="modalAuth abertura-de-caixa"
            show={modalShow}
            onHide={() => { }}
            aria-labelledby="contained-modal-title-vcenter"
        >
            <>
                <Modal.Body
                    className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                /* style={{ height: "600px" }} */
                >
                    <div className="modal-container w-100">
                        <h2 className='modal-title mb-3'>{isFechamento === true ? "Fechamento de caixa" : "Abertura do caixa"}</h2>

                        <form className="" name="login_form" onSubmit={(e) => onSubmit(e)}>
                            <div className="form-group">
                                <label className='d-flex justify-content-start' htmlFor="inputTipoFuncao">{isFechamento === true ? "Tipo de fechamento" : "Tipo de abertura"}</label>
                                <select required className="type-cashier form-control rounded-0 bg-white py-2 rounded" name="tipoFuncao" id="inputTipoFuncao">
                                    <option value='' disabled selected>Selecione uma opção</option>
                                    {typeCashier.map((elem: any, index: number) => {
                                        return (
                                            <option key={index} value={elem.id}>
                                                {elem.description}
                                            </option>
                                        );

                                    })}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className='d-flex justify-content-start' htmlFor="inputValor">{isFechamento === true ? "Valor de fechamento" : "Valor de abertura"}</label>
                                {
                                    isFechamento === true
                                        ?
                                        <input
                                            required
                                            type="text"
                                            name="valor"
                                            className="form-control"
                                            id="inputValor"
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        />
                                        :
                                        <input
                                            required
                                            type="text"
                                            name="valor"
                                            className="form-control"
                                            id="inputValor"
                                            value={maxOpening}
                                            onChange={(e: any) => handleMaxOpening(e)}
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        />
                                }
                            </div>
                            <div className="form-group">
                                <label className='d-flex justify-content-start' htmlFor="inputObservations">Justificativa</label>
                                <textarea name="observations" className="form-control" id="inputObservations" />
                            </div>

                            {/* <hr /> */}

{/*                             <div className="form-group">
                                <label className='d-flex justify-content-start' htmlFor="exampleInputEmail">Login do supervisor</label>
                                <input required type="text" name="email" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp" autoComplete='off' readOnly onClick={(e: any) => { init_area(e) }} onPaste={() => { return false }} />
                            </div>
                            <div className="form-group">
                                <label className='d-flex justify-content-start' htmlFor="exampleInputPassword">{t("login.password")}</label>
                                <input required type={showPass === true ? "text" : "password"} name="cust_pass" className="form-control" id="exampleInputPassword" autoComplete='off' readOnly onClick={(e: any) => { init_area(e) }} onPaste={() => { return false }} />
                                <div className="iconsPassword">
                                    {showPass === false ?
                                        <FontAwesomeIcon
                                            icon={["fal", "eye-slash"]}
                                            size="1x"
                                            onClick={() => { setShowPass(!showPass) }}
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={["fal", "eye"]}
                                            size="1x"
                                            onClick={() => { setShowPass(!showPass) }}
                                        />
                                    }
                                </div>
                            </div> */}
                            <GetError />

                            <Row>
                                <Col md={6}>
                                    <button type="button" className="form-button btn btn-outline-primary btn-block" onClick={() => { handleCanceled() }}>Cancelar</button>
                                </Col>
                                <Col md={6}>
                                    <button type="submit" className="form-button btn btn-primary btn-block btn-login text-white">{loading === true ? <div className="load"></div> : "Confirmar"}</button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Modal.Body>
            </>
        </Modal>
    );
}

export default CashierOpening;