import pt from './default.json';
import en from './default.en.json';
import es from './default.es.json';

//Pages
// import homePt from '../../pages/Home/locales/default.json';
// import homeEn from '../../pages/Home/locales/default.en.json';
// import homeEs from '../../pages/Home/locales/default.es.json';

import loginPt from '../../pages/Login/locales/default.json';
import loginEn from '../../pages/Login/locales/default.en.json';
import loginEs from '../../pages/Login/locales/default.es.json';

import cartPt from '../../pages/Tickets/components/Cart/locales/default.json';
import cartEn from '../../pages/Tickets/components/Cart/locales/default.en.json';
import cartEs from '../../pages/Tickets/components/Cart/locales/default.es.json';

import productsPt from '../../components/Products/locales/default.json';
import productsEn from '../../components/Products/locales/default.en.json';
import productsEs from '../../components/Products/locales/default.es.json';


//Components

// pt['pt-BR'].home = homePt['pt-BR'];
// en['en'].home = homeEn['en'];
// es['es'].home = homeEs['es'];

// pt['pt-BR'].tour = tourPt['pt-BR'];
// en['en'].tour = tourEn['en'];
// es['es'].tour = tourEs['es'];

pt['pt-BR'].cart = cartPt['pt-BR'];
en['en'].cart = cartEn['en'];
es['es'].cart = cartEs['es'];

pt['pt-BR'].login = loginPt['pt-BR'];
en['en'].login = loginEn['en'];
es['es'].login = loginEs['es'];

pt['pt-BR'].products = productsPt['pt-BR'];
en['en'].products = productsEn['en'];
es['es'].products = productsEs['es'];

export {
  pt,
  en,
  es,
};