import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch } from "../../../store/hooks";
import { removeItemCart } from "../../../store/cart";

import './ModalNewLayout.scss';

export interface propModal {
    show: any;
    setShow: any;
};

const ModalNewLayout: React.FC<propModal> = ({
    show, setShow,
}: propModal) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <Modal ///Confirmação para remover item do carrinho
                className="modalAuth modal-validation"
                show={show !== null}
                onHide={() => { setShow(null) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <>
                    <Modal.Body
                        className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
                    >
                        <div>

                            <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className='text-primary'
                            />
                        </div>

                        <small>{`Tem certeza que deseja remover "${show?.productName}" do carrinho?`}</small>
                        <div className="d-flex justify-content-center pt-3">
                            <button
                                onClick={() => {
                                    setShow(null);
                                }}
                                className="btn btn-outline-primary mx-2 w-25"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={() => {
                                    dispatch(removeItemCart(show));
                                    setShow(null);
                                }}
                                className="btn btn-primary mx-2 w-25"
                                style={{ color: '#FFF' }}
                            >
                                Confirmar
                            </button>
                        </div>
                    </Modal.Body>
                </>
            </Modal>
        </>
    )
}

export default ModalNewLayout;