import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CashierOpening from '../../components/CashierOpening/CashierOpening';

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';

function CloseCashier() {
    const [modalCloseCashierShow, setModalCloseCashierShow] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    const handleCancel = (cancel: boolean) => {
        //setModalCloseCashierShow(true);
        //window.location.href = '/';
    }

    return (
        <>
            <DesktopDefault pageTitle={'Fechamento de caixa'}>
                <CashierOpening modalShow={true} setModalShow={handleCancel} isFechamento={true} setOpeningSuccess={setModalCloseCashierShow} setModalMessage={setModalMessage} />

                <Modal
                    className="modalAuth modal-validation"
                    show={modalCloseCashierShow}
                    onHide={() => { window.location.href = '/' }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos modal-default d-flex justify-content-evenly flex-column"
                        >
                            <div>

                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    //style={{ fontSize: "7.5em" }}
                                    className='text-primary'
                                />
                            </div>

                            <div>{modalMessage}</div>
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => {
                                        window.location.href = '/';
                                    }}
                                    className="btn btn-primary mx-2 w-25"
                                    style={{ color: '#FFF' }}
                                >
                                    Confirmar
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                </Modal>
            </DesktopDefault>
        </>
    );
}

export default CloseCashier;